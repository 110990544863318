import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './i18n';
import thunk from 'redux-thunk';
import {Provider} from "react-redux";
import {applyMiddleware, combineReducers, compose, createStore} from "redux";
import auth from "./store/reducer/auth";
import data from "./store/reducer/data";
import {SnackbarProvider} from "notistack";
import {createMuiTheme} from '@material-ui/core/styles';
import CssBaseline from "@material-ui/core/CssBaseline";
import {MuiThemeProvider} from "@material-ui/core";
import notification from "./store/reducer/notification";
import Notifier from "./Notifier/notifier";
import meta from "./store/reducer/meta";
import moment from "moment";
import {MediaQueryProvider} from "react-media-query-hoc";
import 'moment/min/locales.min'
import {QueryClient, QueryClientProvider,} from 'react-query'

const rootReducer = combineReducers({
    auth: auth,
    data: data,
    meta: meta,
    notification: notification,
});

const logger = store => {
    return next => {
        return action => {
            // console.log('[Middleware] Dispatching', action);
            const result = next(action);
            // console.log('[Middleware] next state', store.getState());
            return result;
        }
    }
};

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(logger, thunk)));


const DarkTheme = createMuiTheme({
    overrides: {
        MuiStepIcon: {
            root: {
                '&$completed': {
                    color: '#4caf50',
                },
                '&$active': {
                    color: '#ffeb3b',
                },
            },
            active: {},
            completed: {},
        },
    },
    palette: {
        type: 'dark',
        primary: {
            main: '#ffffff',
        },
        secondary: {
            main: '#953632',
        },
    },
    props: {
        MuiAppBar: {
            color: 'transparent',
        },
        MuiList: {
            dense: true,
        },
        MuiMenuItem: {
            dense: true,
        },
        MuiTable: {
            size: 'small',
        },
        MuiButton: {
            size: 'small',
        },
        MuiButtonGroup: {
            size: 'small',
        },
        MuiCheckbox: {
            size: 'small',
        },
        MuiFab: {
            size: 'small',
        },
        MuiFormControl: {
            margin: 'dense',
            size: 'small',
        },
        MuiFormHelperText: {
            margin: 'dense',
        },
        MuiIconButton: {
            size: 'small',
        },
        MuiInputBase: {
            margin: 'dense',
        },
        MuiInputLabel: {
            margin: 'dense',
        },
        MuiRadio: {
            size: 'small',
        },
        MuiSwitch: {
            size: 'small',
        },
        MuiTextField: {
            margin: 'dense',
            size: 'small',
        },
    },
    shape: {
        borderRadius: 4,
    },
});

let root = document.documentElement;
root.style.setProperty('--primary_color', DarkTheme.palette.primary.main);

const queryClient = new QueryClient()

// Recupero e impostazione della formattazione della data
if (!localStorage.getItem('lang')) {
    const locale = window.navigator.userLanguage || window.navigator.language;
    localStorage.setItem('lang', locale.substring(0, 2))
}
moment.locale(localStorage.getItem('lang'));

const customQueries = {
    littleScreen: 'screen and (max-width: 600px)',
    mobile: 'screen and (min-width: 601px) and (max-width: 960px)',
    tablet: 'screen and (min-width: 961px) and (max-width: 1550px)',
    desktop: 'screen and (min-width: 1551px) and (max-width: 1800px)',
    largeDesktop: 'screen and (min-width: 1801px)',
    show:'screen and (min-width: 2100px) and (min-height: 3000px)',
    genPhone: 'screen and (max-width: 960px)',
    genPc: 'screen and (min-width: 961px)',
};

ReactDOM.render(
    <React.StrictMode>
        <MuiThemeProvider theme={DarkTheme}>
            <CssBaseline/>
            <QueryClientProvider client={queryClient}>
            <MediaQueryProvider queries={customQueries}>
                <Provider store={store}>
                    <SnackbarProvider maxSnack={4} anchorOrigin={{vertical: 'bottom', horizontal: 'right',}}>
                        {/*<Suspense fallback={<Loading/>}>*/}
                        <Notifier/>
                        <App/>
                        {/*</Suspense>*/}
                    </SnackbarProvider>
                </Provider>
            </MediaQueryProvider>
     </QueryClientProvider>
        </MuiThemeProvider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
