import * as actionType from './action'
import axiosInst from "../../axios_inst";
import axios_inst from "../../axios_inst";
import {enqueueSnackbar} from "./notification";
import {fromJS} from "immutable";
import {errorNotification} from "../utility";
import {UpdateUser} from "./auth";


const _updateMachine = (data) => {
    return {
        type: actionType.UPDATE_MACHINES,
        data
    }
};

export const getMachines = () => {
    return dispatch => {
        return new Promise((resolve, reject) => {
            axiosInst.get(`/machines`)
                .then(res => {
                    const machines = fromJS(res.data.data)
                    dispatch({
                        type: actionType.UPDATE_MACHINES,
                        data: machines
                    });
                    resolve()
                })
                .catch((err) => {
                    console.error(`/machines`, err)
                    let message = 'Errore interno,\nRiprovare!'
                    if (err.response) {
                        if (err.response.status === 500) message = '3011 - Errore interno'
                    } else {
                        message = err.message
                    }
                    dispatch(enqueueSnackbar({
                        message: message,
                        options: {
                            key: new Date().getTime() + Math.random(),
                            variant: 'error',
                            autoHideDuration: 2000,
                        },
                    }))
                    reject(err)
                })
        })
    }
};

export const getPlants = () => {
    return dispatch => {
        return new Promise((resolve, reject) => {
            axiosInst.get(`/plants`)
                .then(res => {
                    const plants = fromJS(res.data.data)
                    dispatch({
                        type: actionType.UPDATE_PLANTS,
                        data: plants
                    });
                    resolve()
                })
                .catch((err) => {
                    console.error(`/plants`, err)
                    let message = 'Errore interno,\nRiprovare!'
                    if (err.response) {
                        if (err.response.status === 500) message = '3011 - Errore interno'
                    } else {
                        message = err.message
                    }
                    dispatch(enqueueSnackbar({
                        message: message,
                        options: {
                            key: new Date().getTime() + Math.random(),
                            variant: 'error',
                            autoHideDuration: 2000,
                        },
                    }))
                    reject(err)
                })
        })
    }
};

export const getPlantsAndMachines = () => {
    return dispatch => {
        return new Promise((resolve, reject) => {
            axiosInst.get(`/plantsMachines`)
                .then(res => {
                    const machines = fromJS(res.data.data.machines)
                    dispatch(_updateMachine(machines));
                    const plants = fromJS(res.data.data.plants)
                    dispatch({
                        type: actionType.UPDATE_PLANTS,
                        data: plants
                    });
                    resolve()
                })
                .catch((err) => {
                    console.error(`/plants`, err)
                    let message = 'Errore interno,\nRiprovare!'
                    if (err.response) {
                        if (err.response.status === 500) message = '3011 - Errore interno'
                    } else {
                        message = err.message
                    }
                    dispatch(enqueueSnackbar({
                        message: message,
                        options: {
                            key: new Date().getTime() + Math.random(),
                            variant: 'error',
                            autoHideDuration: 2000,
                        },
                    }))
                    reject(err)
                })
        })
    }
};

export const updateMachine = (id, machine) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            axiosInst.put(`/machine/${id}`, machine)
                .then((res) => {
                    let machines = getState().meta.machines
                    let idx = machines.findIndex(m => m.get('id') === id)
                    const oldMachineShares = machines.getIn([idx, 'sharedTo'])
                    let promises = []
                    machine.sharedTo.forEach(s => {
                        const t = oldMachineShares.find(os => {
                            return os.get('userEmail') === s.userEmail && os.get('machineId') === s.machineId
                        })
                        if (!t) {
                            promises.push(axiosInst.post(`/shares`, s))
                        } else if (!t.equals(fromJS(s))) {
                            promises.push(axiosInst.put(`/shares`, s))
                        }
                    })
                    oldMachineShares.forEach(os => {
                         const t = machine.sharedTo.find(s => {
                            return os.get('userEmail') === s.userEmail && os.get('machineId') === s.machineId
                        })
                        if (!t) {
                            promises.push(axiosInst.post(`/shares/delete`, {machineId: os.get('machineId'), userEmail: os.get('userEmail')}))
                        }
                    })
                    Promise.all(promises).then(()=>{
                        dispatch(getMachines()).then(()=>{
                            resolve()
                        })
                    })
                    // const data = res.data.data
                    // machines = machines.update(idx, d => d.merge(fromJS(machine)))
                    // dispatch(_updateMachine(machines));

                })
        })
    }
}

export const shareMachine = (machineId, email) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            axios_inst.post(`/machines/${machineId}/share `, [{
                userEmail: email,
                emailAlert: true,
                machineId: machineId
            }])
                .then((res) => {
                    const new_machine = fromJS(res.data.data)
                    let user = getState().auth.user
                    user = user.updateIn(['user', 'sharedMachines', machineId], m => m.push(new_machine))
                    dispatch(UpdateUser(user))
                    resolve()
                })
                .catch((res) => reject())
        })
    }
}

export const unsubscribeMachine = (machineId) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            axios_inst.post(`/machines/${machineId}/unsubscribe `)
                .then((res) => {
                    let machines = getState().meta.machines
                    const machineIdx = machines.findIndex(sm => sm.get('id') === machineId)
                    machines = machines.delete(machineIdx)
                    dispatch(_updateMachine(machines))
                    resolve()
                })
                .catch((res) => {
                    reject()
                })
        })
    }
}

export const getDevices = () => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            const machineId = getState().data.machineId
            axiosInst.get(`machines/${machineId}/devices`)
                .then(res => {
                    const data = fromJS(res.data.data)
                    dispatch({
                        type: actionType.UPDATE_DEVICES,
                        data: data
                    });
                    resolve(res)
                })
                .catch((err) => {
                    console.error(`/devices`, err)
                    dispatch(errorNotification(err, {500: "3013 - Errore interno"}))
                    reject(err)
                })
        })
    }
};

export const getEventClass = () => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            axiosInst.get(`/eventClass`)
                .then(res => {
                    const data = fromJS(res.data.data)
                    dispatch({
                        type: actionType.UPDATE_EVENT_CLASS,
                        data: data
                    });
                    resolve(res)
                })
                .catch((err) => {
                    console.error(`/eventClass`, err)
                    dispatch(errorNotification(err, {500: "3014 - Errore interno"}))
                    reject(err)
                })
        })
    }
};

export const getEventCode = () => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            const machineId = getState().data.machineId
            axiosInst.get(`/machines/${machineId}/eventCodes`)
                .then(res => {
                    const data = fromJS(res.data.data)
                    dispatch({
                        type: actionType.UPDATE_EVENT_CODE,
                        data: data
                    });
                    resolve(res)
                })
                .catch((err) => {
                    console.error(`/eventCode`, err)
                    dispatch(errorNotification(err, {500: "3015 - Errore interno"}))
                    reject(err)
                })
        })
    }
};

export const getType = () => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            axiosInst.get(`/type`)
                .then(res => {
                    const data = fromJS(res.data.data)
                    dispatch({
                        type: actionType.UPDATE_TYPE,
                        data: data
                    });
                    resolve(res)
                })
                .catch((err) => {
                    console.error(`/type`, err)
                    dispatch(errorNotification(err, {500: "3016 - Errore interno"}))
                    reject(err)
                })
        })
    }
};

export const addFavoriteFilter = (filterName) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            const state = getState().data
            let filterState = state.filters
            filterState = filterState.set('filterType', state.filterType)
            filterState = filterState.set('showTable', state.showTable)
            filterState = filterState.set('showGraph', state.showGraph)
            filterState = filterState.delete('startTime')
            filterState = filterState.delete('endTime')
            const filter = {
                filterName: filterName,
                filter: filterState.toJS(),
                machineId: state.machineId,
            }
            axios_inst.post(`/favoriteFilter`, filter)
                .then((res) => {
                    resolve(res)
                })
                .catch((err) => {
                    console.error(`/favoriteFilter`, err)
                    dispatch(errorNotification(err, {500: "Errore inserimento"}))
                    reject(err)
                })
        })
    }
}

export const getFavoriteFilters = () => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            const machineId = getState().data.machineId
            axiosInst.get(`/favoriteFilter/${machineId}`)
                .then((res) => {
                    const data = fromJS(res.data.data)
                    dispatch({
                        type: actionType.UPDATE_FAVORITE_FILTERS,
                        data: data
                    });
                    resolve()
                })
                .catch((err) => {
                    console.error(`/favoriteFilter`, err)
                    dispatch(errorNotification(err, {500: "Errore"}))
                    reject(err)
                })
        })
    }
}

export const deleteFavoriteFilters = (filterId) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            axiosInst.delete(`/favoriteFilter/${filterId}`)
                .then((res) => {
                    let filters = getState().meta.favoriteFilters
                    filters = filters.delete(filters.findIndex(f => f.get('id') === filterId))
                    dispatch({
                        type: actionType.UPDATE_FAVORITE_FILTERS,
                        data: filters
                    });
                })
                .catch((err) => {
                    console.error(`/favoriteFilter`, err)
                    dispatch(errorNotification(err, {500: "Errore"}))
                    reject(err)
                })
        })
    }
}

export const getUsers = () => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            axiosInst.get(`/users`)
                .then((res) => {
                    dispatch({
                        type: actionType.UPDATE_USER_LIST,
                        data: fromJS(res.data.data)
                    });
                })
                .catch((err) => {
                    console.error(`/users`, err)
                    dispatch(errorNotification(err, {500: "Errore"}))
                    reject(err)
                })
        })
    }
}

export const deleteUser = (user_id) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            axiosInst.delete(`/user/${user_id}`)
                .then((res) => {
                    let oldUserList = getState().meta.userList
                    oldUserList = oldUserList.deleteIn([oldUserList.findIndex(u => u.get('id') === user_id)])
                    dispatch({
                        type: actionType.UPDATE_USER_LIST,
                        data: oldUserList
                    });
                    resolve()
                })
                .catch((err) => {
                    console.error(`/users`, err)
                    dispatch(errorNotification(err, {500: "Errore"}))
                    reject(err)
                })
        })
    }
}

export const addUser = (name, surname, email, password, role) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            axios_inst.post(`/user`, {email, password, name, surname, role})
                .then((res) => {
                    let oldUserList = getState().meta.userList
                    oldUserList = oldUserList.push(fromJS(res.data.data))
                    dispatch({
                        type: actionType.UPDATE_USER_LIST,
                        data: oldUserList
                    });
                    resolve()
                })
                .catch((res) => reject())
        })
    }
}

export const getRoles = () => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            axiosInst.get(`/roles`)
                .then((res) => {
                    dispatch({
                        type: actionType.UPDATE_ROLES,
                        data: fromJS(res.data.data)
                    });
                })
                .catch((err) => {
                    console.error(`/roles`, err)
                    dispatch(errorNotification(err, {500: "Errore"}))
                    reject(err)
                })
        })
    }
}

export const getGroups = () => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            axiosInst.get(`/groups`)
                .then((res) => {
                    dispatch({
                        type: actionType.UPDATE_GROUPS,
                        data: fromJS(res.data.data)
                    });
                })
                .catch((err) => {
                    console.error(`/groups`, err)
                    dispatch(errorNotification(err, {500: "Errore"}))
                    reject(err)
                })
        })
    }
}


export const getTickets = () => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            axios_inst.get(`/tickets`)
                .then((res) => {
                    const data = fromJS(res.data.data)
                    dispatch({
                        type: actionType.UPDATE_TICKETS,
                        data
                    })
                    resolve()
                })
                .catch((res) => {
                    reject()
                })
        })
    }
}

export const updateTicket = (ticketId, ticket) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            const machineId = getState().meta.tickets.find(t => t.get('id') === ticketId).get('machineId')
            axiosInst.put(`/machines/${machineId}/tickets/${ticketId}`, ticket)
                .then((res) => {
                    const data = res.data.data
                    let tickets = getState().meta.tickets
                    let idx = tickets.findIndex(t => t.get('id') === ticketId)
                    tickets = tickets.update(idx, d => d.merge(fromJS(ticket)))
                    dispatch({
                        type: actionType.UPDATE_TICKETS,
                        data: tickets
                    })
                    resolve()
                })
        })
    }
}
