import * as actionType from './action'
import axios_inst from "../../axios_inst";
import {enqueueSnackbar} from "./notification";
import {fromJS, List, Map} from "immutable";
import {errorNotification} from "../utility";
import {UpdateUser} from "./auth";
import axiosInst from "../../axios_inst";

export const getBatchReports = (machineId) => {
    return dispatch => {
        return new Promise((resolve, reject) => {
            axios_inst.get(`/machines/${machineId}/reports`)
                .then(res => {
                    if (res.data.data && Object.keys(res.data.data).length === 0 && res.data.data.constructor === Object) {
                        resolve(false)
                        return
                    }
                    const data = fromJS(res.data.data)
                    dispatch({
                        type: actionType.UPDATE_REPORTS,
                        batchReports: data.getIn(['batch', 'data'], Map({})),
                        cipReports: data.getIn(['cip', 'data'], Map({}))
                    });
                    resolve(res)
                })
                .catch((err) => {
                    console.error(`/batchReport`, err)
                    let message = 'Errore interno,\nRiprovare!'
                    if (err.response) {
                        if (err.response.status === 500) message = '3010 -> Errore interno'
                    } else {
                        message = err.message
                    }
                    dispatch(enqueueSnackbar({
                        message: message,
                        options: {
                            key: new Date().getTime() + Math.random(),
                            variant: 'error',
                            autoHideDuration: 2000,
                        },
                    }))
                    reject(err)
                })
        })
    }
};

export const onSelectedPlantChange = (plantId) => {
    return dispatch => {
        return new Promise(((resolve) => {
            dispatch({
                type: actionType.UPDATE_SELECTED_PLANT,
                data: plantId
            })
            resolve()
        }))
    }
}

export const onSelectedMachineChange = (machineId) => {
    return dispatch => {
        return new Promise(((resolve) => {
            dispatch({
                type: actionType.UPDATE_SELECTED_MACHINE,
                data: machineId
            })
            resolve()
        }))
    }
}

export const getFilteredData = (filters, showTable, showGraph, filterType) => {
    return (dispatch, getState) => {
        return new Promise(((resolve, reject) => {
            let machineId = getState().data.machineId
            let newFilters = filters
            newFilters = newFilters.update('startTime', v => v?.valueOf())
            newFilters = newFilters.update('endTime', v => v?.valueOf())
            newFilters = newFilters.update('machine', v => machineId)
            dispatch({
                type: actionType.UPDATE_FILTERS,
                filters,
                showTable,
                showGraph,
                filterType
            })
            axios_inst.post('/logEvents', newFilters.toJS())
                .then((res) => {
                    const data = fromJS(res.data.data)
                    let series = List([])
                    let chartData = data
                        .groupBy(d => d.getIn(['eventCode'])?.toString() + d.getIn(['eventType'])?.toString())
                        // .sortBy(d => d.getIn([0, 'unit']))
                        .map(d => {
                            const description = d.getIn([0, 'description', localStorage.getItem('lang')], d.getIn([0, 'description']).first(''))
                            let existingSeries = series.findIndex(s => s.getIn(['name']) === d.getIn([0, 'unit']))
                            if (existingSeries === -1) {
                                series = series.push(Map({
                                    position: 'left',
                                    offset: series.size * 50,
                                    type: 'value',
                                    name: d.getIn([0, 'unit']),
                                    boundaryGap: ['15%', '10%'],
                                }))
                            }
                            existingSeries = series.findIndex(s => s.getIn(['name']) === d.getIn([0, 'unit']))
                            const line = {
                                yAxisIndex: parseInt(existingSeries),
                                name: description,
                                type: 'line',
                                data: d.sortBy(d => d.get('timestampStart')).reduce((acc, curr) => {
                                    if (curr.get('eventType') === 2) {
                                        return acc.concat([
                                            [
                                                curr.get('timestampStart'),
                                                1,
                                            ], [
                                                curr.get('timestampEnd'),
                                                0,
                                            ],
                                        ])
                                    } else {
                                        return acc.concat([
                                            [
                                                curr.get('timestampStart'),
                                                curr.get('value'),
                                            ]
                                        ])
                                    }
                                }, List([])),
                            }
                            if (d.getIn([0, 'unit']) === 'bool') {
                                line['step'] = 'end'
                            }
                            return line
                        })
                        .toList()

                    dispatch({
                        type: actionType.UPDATE_DATA,
                        data: data,
                        graphData: chartData,
                        graphSeries: series,
                    })


                    resolve(data)
                })
                .catch((err) => {
                    console.error(`/devices`, err)
                    dispatch(errorNotification(err, {500: "3017 - Errore POST filtri data\n"}))
                    reject(err)
                })
        }))
    }
}

export const getDocumentsList = () => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            const machineId = getState().data.machineId
            axios_inst.get(`/machines/${machineId}/documents`)
                .then((res) => {
                    const data = fromJS(res.data.data)
                    dispatch({
                        type: actionType.UPDATE_DOCUMENTS,
                        data
                    })
                    resolve()
                })
                .catch((res) => {
                    reject()
                })
        })
    }
}

export const getDocument = (documentName) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            const machineId = getState().data.machineId
            axios_inst.post(`/machines/${machineId}/documents`, {fileName: documentName})
                .then((res) => {
                    const data = res.data.data.url
                    resolve(data)
                })
                .catch((res) => {
                    reject()
                })
        })
    }
}

export const deleteDocument = (documentName) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            const machineId = getState().data.machineId
            axios_inst.delete(`/machines/${machineId}/documents?fileName=${documentName}`)
                .then(() => {
                    const data = getState().data.documents
                    const newData = data.delete(data.findIndex(d => d === documentName))
                    dispatch({
                        type: actionType.UPDATE_DOCUMENTS,
                        data: newData
                    })
                    resolve(documentName)
                })
                .catch(() => {
                    reject(documentName)
                })
        })
    }
}

export const generateUploadLink = (file) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            const machineId = getState().data.machineId
            axios_inst.put(`/machines/${machineId}/documents`, {fileName: file.name})
                .then((res) => {
                    resolve(res.data.data)
                })
                .catch((res) => {
                    reject(res)
                })
        })
    }
}

export const uploadDocument = (url, file, filename) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            fetch(url, {
                method: 'PUT',
                headers: {'Content-Type': file.type},
                body: file
            }).then((res) => {
                    const data = getState().data.documents
                    const newData = data.push(filename)
                    dispatch({
                        type: actionType.UPDATE_DOCUMENTS,
                        data: newData
                    })
                    resolve(file.name)
                })
                .catch((res) => {
                    reject(res)
                })
        })
    }
}

export const getMachineTickets = () => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            const machineId = getState().data.machineId
            axios_inst.get(`/machines/${machineId}/tickets`)
                .then((res) => {
                    const data = fromJS(res.data.data)
                    dispatch({
                        type: actionType.UPDATE_MACHINE_TICKETS,
                        data
                    })
                    resolve()
                })
                .catch((res) => {
                    reject()
                })
        })
    }
}

export const updateMachineTicket = (ticketId, ticket) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            const machineId = getState().data.machineId
            axiosInst.put(`/machines/${machineId}/tickets/${ticketId}`, ticket)
                .then((res) => {
                    const data = res.data.data
                    let tickets = getState().data.machine_tickets
                    let idx = tickets.findIndex(t => t.get('id') === ticketId)
                    tickets = tickets.update(idx, d => d.merge(fromJS(ticket)))
                    dispatch({
                        type: actionType.UPDATE_MACHINE_TICKETS,
                        data: tickets
                    })
                    resolve()
                })
        })
    }
}


export const newMachineTicket = (ticket) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            const machineId = getState().data.machineId
            axios_inst.post(`/machines/${machineId}/tickets`, ticket)
                .then((res) => {
                    const data = fromJS(res.data.data)
                    let tickets = getState().data.machine_tickets
                    const newTickets = tickets.push(data)
                    dispatch({
                        type: actionType.UPDATE_MACHINE_TICKETS,
                        data: newTickets
                    })
                    resolve()
                })
                .catch(() => {
                    reject()
                })
        })
    }
}
