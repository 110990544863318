import React, {Fragment, useState} from "react";
import './EventTypeFilterPage.scss'
import Autocomplete from "@material-ui/lab/Autocomplete";
import {Button, TextField} from "@material-ui/core";
import {fromJS, List} from "immutable";
import {KeyboardDateTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import moment from "moment";
import MomentUtils from "@date-io/moment";
import {ToggleButton, ToggleButtonGroup} from "@material-ui/lab";
import {withTranslation} from "react-i18next";
import VisualizationType from "../VisualizationType/VisualizationType";
import ConfirmPopUp from "../ConfirmPopUp/ConfirmPopUp";


const EventTypeFilterPage = (props) => {
    const {t} = props
    const eventTypeMask = List([t('OneShot'), t('OnOff'), t('Campionamento')])
    const [showTable, setShowTable] = useState(props.showTable)
    const [showGraph, setShowGraph] = useState(props.showGraph)
    const [resetFilter, setResetFilter] = useState(false)

    const [errors, setErrors] = useState({})

    const handleValidation = () => {
        let new_errors = {}
        if (props.filters.getIn(['eventType', 0]) === null || isNaN(props.filters.getIn(['eventType', 0]))) new_errors = {
            ...new_errors,
            eventType: t('Tipologia evento non selezionata')
        }
        if ((props.filters.get('startTime') instanceof moment && !props.filters.get('startTime')._isValid) || props.filters.get('startTime') === null) new_errors = {
            ...new_errors,
            startTime: t('Data di inizio non valida')
        }
        if (props.filters.get('endTime') instanceof moment && !props.filters.get('endTime')._isValid) new_errors = {
            ...new_errors,
            endTime: t('Data di fine non valida')
        }
        if (props.filters.getIn(['eventType', 0], 0) === 3 && (!props.filters.getIn(['eventCode']) || props.filters.getIn(['eventCode']) === undefined || props.filters.getIn(['eventCode']).size <= 0)) new_errors = {
            ...new_errors,
            eventCode: t('Nessun evento selezionato')
        }
        if (props.filters.getIn(['eventType', 0], 0) === 3 && !showTable && !showGraph) new_errors = {
            ...new_errors,
            visualization: t('Nessuna modalità di visualizzazione selezionata')
        }
        setErrors(old => ({
            ...old,
            ...new_errors
        }))
        const _showTable = props.filters.getIn(['eventType', 0], 0) === 3 ? showTable : true
        const _showGraph = props.filters.getIn(['eventType', 0], 0) === 3 ? showGraph : false
        if (Object.keys(new_errors)?.length === 0) props.sendFilters(_showTable, _showGraph)
    }

    /* Funzione di reset allarmi e invio modifiche */
    const handleChange = (key, value) => {
        let temp = {...errors}
        delete temp[key]
        setErrors(temp)
        props.onChangeFilter(key, value)
    }


    return (
        <div className={'eventTypeFilterPage'}>
            <Autocomplete
                className={'textField'}
                // multiple
                size="medium"
                value={props.filters.getIn(['eventType', 0])}
                onChange={(e, v, r) => handleChange('eventType', List([parseInt(v)]))}
                options={['1', '2', '3']}
                getOptionLabel={(option) => eventTypeMask.get(option - 1)}
                noOptionsText={t('Nessuna scelta disponibile')}
                renderInput={(params) => <TextField {...params}
                                                    error={errors['eventType']}
                                                    helperText={errors['eventType']}
                                                    variant="outlined"
                                                    label={"Tipologia di evento"}
                                                    size={"medium"}/>}
            />

            {props.filters.getIn(['eventType', 0], 0) === 3 &&
            <div>
                <Autocomplete
                    multiple
                    size="medium"
                    limitTags={5}
                    disableCloseOnSelect
                    value={props.filters.getIn(['eventCode'])?.toJS()}
                    onChange={(e, v, r) => handleChange('eventCode', fromJS(v))}
                    options={props.eventCode?.filter(ec => ec.get('eventType') === props.filters.getIn(['eventType', 0]))?.map(ec => ec.get('pkId')?.toString()).toJS()}
                    getOptionLabel={(option) => {
                        const description = props.eventCode.find(ec => ec.get('pkId') == option)?.getIn(['description'])
                        return description?.get(localStorage.getItem('lang'), description.first())
                        // props.eventCode.find(ec => ec.get('pkId') == option).getIn(['description', localStorage.getItem('lang')], '')
                    }}
                    noOptionsText={t('Nessuna scelta disponibile')}
                    className={'textFieldMultiple'}
                    renderInput={(params) => (
                        <TextField {...params}
                                   error={errors['eventCode']}
                                   helperText={errors['eventCode']}
                                   variant="outlined"
                                   label={t("Codice Evento")}
                                   size={"medium"}
                        />
                    )}
                />
            </div>
            }

            {props.filters.getIn(['eventType', 0], 0) === 2 &&
            <div>
                <Autocomplete
                    multiple
                    size="medium"
                    limitTags={5}
                    disableCloseOnSelect
                    value={props.filters.getIn(['eventClass'])?.toJS()}
                    onChange={(e, v, r) => handleChange('eventClass', fromJS(v))}
                    options={props.eventClass?.map(ec => ec.get('id')?.toString()).toJS()}
                    getOptionLabel={(option) => props.eventClass.find(ec => ec.get('id') == option)?.getIn(['description'], '')}
                    // renderOption={}
                    noOptionsText={t('Nessuna scelta disponibile')}
                    className={'textFieldMultiple'}
                    renderInput={(params) => (
                        <TextField {...params}
                                   variant="outlined"
                                   label={t("Livello allarmi")}
                                   size={"medium"}
                        />
                    )}
                />
            </div>
            }

            {props.filters.getIn(['eventType', 0], 0) === 2 &&
            <div>
                <ToggleButtonGroup value={[props.filters.getIn(['activeAlarm']) ? 0 : null]}
                                   aria-label="text formatting"
                                   className={'textFieldMultiple'}
                                   onChange={(e, v) => handleChange('activeAlarm', v.length > 0)}
                >
                    <ToggleButton value={0} aria-label="bold">
                        Allarmi Attivi
                    </ToggleButton>
                </ToggleButtonGroup>
            </div>
            }

            {!isNaN(props.filters.getIn(['eventType', 0], NaN)) && !props.filters.getIn(['activeAlarm']) &&
            <Fragment>
                <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={moment.locale()}>
                    <KeyboardDateTimePicker
                        variant="inline"
                        inputVariant="outlined"
                        className={'textField'}
                        ampm={false}
                        label={t('Da')}
                        value={props.filters.getIn(['startTime'])}
                        onChange={(date) => handleChange('startTime', date)}
                        onError={console.log}
                        // disablePast
                        format="DD/MM/yyyy HH:mm"
                        error={errors['startTime']}
                        helperText={errors['startTime']}
                    />
                    <KeyboardDateTimePicker
                        variant="inline"
                        inputVariant="outlined"
                        className={'textField'}
                        ampm={false}
                        label={t('A')}
                        value={props.filters.getIn(['endTime'])}
                        onChange={(date) => handleChange('endTime', date)}
                        onError={console.log}
                        // disablePast
                        disableFuture
                        format="DD/MM/yyyy HH:mm"
                        error={errors['endTime']}
                        // helperText={props.filters.getIn(['endTime']) - props.filters.getIn(['startTime']) > 86400000? 'tanto':'poco'}
                        helperText={errors['endTime']}
                    />
                </MuiPickersUtilsProvider>
            </Fragment>
            }

            {props.filters.getIn(['eventType', 0], 0) === 3 &&
            <VisualizationType
                showGraph={showGraph}
                showTable={showTable}
                setShowTable={setShowTable}
                setShowGraph={setShowGraph}
            />
            }

            <div>
                <Button
                    variant={"outlined"}
                    className={'button'}
                    color={'primary'}
                    onClick={() => setResetFilter(true)}
                >{t('Reset Filtri')}</Button>
                <Button
                    variant={"contained"}
                    color={'primary'}
                    className={'button'}
                    onClick={handleValidation}
                >{t('Invia filtri')}</Button>
            </div>

            <ConfirmPopUp
                open={resetFilter}
                handleAccept={() => {
                    setResetFilter(false)
                    props.resetFilter()
                }}
                handleDecline={() => setResetFilter(false)}
                title={t('Reset Filtro')}
                description={t('Sei sicuro di voler cancellare i filtri?')}
                ko={t('Annulla')}
                ok={t('Conferma')}
            />

        </div>
    )
}

export default withTranslation()(EventTypeFilterPage);