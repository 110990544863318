import React, {PureComponent} from "react";
import './App.scss';
import {BrowserRouter, Route} from "react-router-dom"
import axios_inst from "./axios_inst";

import Layout from "./Layout/Layout";
import AxiosManager from "./hoc/AxiosManager/AxiosManager";
import {connect} from "react-redux";
import {loginWithToken} from "./store/actions/auth";
import {withTranslation} from "react-i18next";
import Login from "./components/Login/Login";
import SignUp from "./components/SignUp/SignUp";
import {Redirect} from "react-router";
import Settings from "./components/Settings/Settings";
import BatchView from "./components/BatchView/BatchView";
import DataView from "./components/DataView/DataView";
import Dashboard from "./components/Dashboard/Dashboard";
import EditDashboard from "./components/library/icons/EditDashboard";
import TicketsManagement from "./components/TicketsManagement/TicketsManagement";


class App extends PureComponent {

    componentDidMount() {
        this.props.loginWithToken()
        this.props.i18n.changeLanguage(localStorage.getItem('lang'))
    }

    render() {

        if (this.props.autoLogin !== 0) {

            if (!this.props.userLogged && !(window.location.pathname === '/SignUp' || window.location.pathname === '/SignIn')) {
                window.location.pathname = '/SignIn'
            }
            return (
                <BrowserRouter basename="/">
                    <EditDashboard style={{visibility: 'hidden', position: 'absolute'}}>Only for preload</EditDashboard>
                    <div className="app">
                        {this.props.userLogged &&
                            <Layout>
                                <Route path="/" exact>
                                    {!this.props.userLogged ? <Redirect to="/SignIn"/> : <Dashboard/>}
                                </Route>
                                <Route path="/batch" exact>
                                    {!this.props.userLogged ? <Redirect to="/SignIn"/> : <BatchView/>}
                                </Route>
                                <Route path="/DataView" exact>
                                    {!this.props.userLogged ? <Redirect to="/SignIn"/> : <DataView/>}
                                </Route>
                                <Route path="/settings">
                                    {!this.props.userLogged ? <Redirect to="/SignIn"/> : <Settings/>}
                                </Route>
                                <Route path="/tickets">
                                    {!this.props.userLogged ? <Redirect to="/SignIn"/> : <TicketsManagement/>}
                                </Route>
                            </Layout>
                        }

                        <Route path="/SignUp" exact>
                            {this.props.userLogged ? <Redirect to="/"/> : <SignUp/>}
                        </Route>
                        <Route path="/SignIn" exact>
                            {this.props.userLogged ? <Redirect to="/"/> : <Login/>}
                        </Route>
                    </div>
                </BrowserRouter>
            );
        } else {
            return (
                <div className={'boot'}>
                    <img src={'logo_TMCI.png'} alt=""/>
                </div>
            )
        }
    }
}

const mapStateToProps = state => {
    return {
        userLogged: state.auth.userLogged,
        autoLogin: state.auth.autoLogin,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        loginWithToken: (...args) => dispatch(loginWithToken(...args)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(AxiosManager(withTranslation()(App), axios_inst));
