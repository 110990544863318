import * as actionType from './action'
import axios_inst from "../../axios_inst";
import {enqueueSnackbar} from "./notification";
import {fromJS} from "immutable";

const authStart = (defaultLogin) => {
    return {
        type: actionType.AUTH_START,
        defaultLogin: defaultLogin
    }
};

const authSuccess = (data) => {
    return {
        type: actionType.AUTH_SUCCESS,
        data
    }
};

export const UpdateUser = (user) => {
    return {
        type: actionType.UPDATE_USER,
        data: user
    }
};

const authFail = (authError) => {
    return {
        type: actionType.AUTH_FAIL,
        data: authError
    }
};


export const login = (email, password) => {
    return dispatch => {
        dispatch(authStart());
        return new Promise((resolve, reject) => {
            axios_inst.post('/login', {email, password})
                .then(res => {
                    const data = fromJS(res.data.data)
                    localStorage.setItem('token', data.get('token'));
                    localStorage.setItem('refresh_token', data.get('refreshToken'));
                    dispatch(authSuccess(data));
                    resolve(res)
                })
                .catch((err) => {
                    console.error(`/login`, err)
                    let message = 'Errore interno,\nRiprovare!'
                    if (err.response) {
                        if (err.response.status === 400) message = 'Campi mancanti'
                        if (err.response.status === 403) message = 'Email o password non validi'
                        if (err.response.status === 500) message = '3001 - Errore interno'
                    } else {
                        message = err.message
                    }
                    dispatch(enqueueSnackbar({
                        message: message,
                        options: {
                            key: new Date().getTime() + Math.random(),
                            variant: 'error',
                            autoHideDuration: 2000,
                        },
                    }))
                    reject(err)
                })
        })
    }
};


export const loginWithToken = (token) => {
    return (dispatch, getState) => {
        if (!localStorage.getItem('token')) return dispatch({type: actionType.AUTO_LOGIN_FAIL})
        axios_inst.post('loginWithToken')
            .then(response => {
                let data = fromJS(response.data.data)
                localStorage.setItem('token', data.get('token'));
                localStorage.setItem('refresh_token', data.get('refreshToken'));
                // data = data.merge(
                //     Map(
                //         {
                //             'token': localStorage.getItem('token'),
                //             'refresh_token': localStorage.getItem('refresh_token')
                //         }
                //     )
                // )
                // dispatch(authSuccess(data));
                dispatch({type: actionType.AUTO_LOGIN_SUCCESS, data});
                // checkAuthTImeout(10);
            })
            .catch(error => {
                // console.log(error)
                // dispatch(authFail(error))
                dispatch({type: actionType.AUTO_LOGIN_FAIL});

            })
    }
};


export const updateUser = (userData) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            let updatedUser = userData
            updatedUser = updatedUser.delete('sharedMachines')
            // updatedUser = updatedUser.delete('id')
            updatedUser = updatedUser.delete('enable')
            updatedUser = updatedUser.delete('role')
            updatedUser = updatedUser.delete('group')
            updatedUser = updatedUser.delete('machineAlerts')
            updatedUser = updatedUser.delete('machines')
            updatedUser = updatedUser.toJS()

            axios_inst.put(`/user/${userData.get('id')}`, updatedUser)
                .then((res) => {
                    if (userData.get('id') === getState().auth.user.get('id')) {
                        dispatch(UpdateUser(getState().auth.user.mergeIn(['user'], updatedUser)))
                    }
                    let oldUserList = getState().meta.userList
                    oldUserList = oldUserList.updateIn([oldUserList.findIndex(u => u.get('id') === userData.get('id'))], () => userData)
                    dispatch({
                        type: actionType.UPDATE_USER_LIST,
                        data: oldUserList
                    });
                    resolve()
                })
                .catch((err) => {
                    console.error(err)
                    reject()
                })

        })
    }
}


export const changeUserPassword = (newPassword, newPasswordCopy, user) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            axios_inst.post(`/changePassword`, {newPassword, newPasswordCopy, user})
                .then((res) => resolve())
                .catch((res) => reject())
        })
    }
}


export const inviteUser = (email) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            axios_inst.post(`/inviteUser`, {email})
                .then((res) => resolve())
                .catch((res) => reject())
        })
    }
}


export const signup = (name, surname, email, password) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            axios_inst.post(`/signUp`, {email, password, name, surname})
                .then((res) => resolve())
                .catch((res) => reject())
        })
    }
}

// const edit_user = (user) => {
//     console.log(user)
//     return {
//         type: actionType.UPDATE_USER,
//         user: user
//     }
// }
//
//
// export const login = (userName, password) => {
//
//     return (dispatch, getState) => {
//         axios.post('/login', {username: userName, password: password})
//             .then((response) => {
//                 console.log(response.data.data)
//                 dispatch(edit_user(getState().auth.user.update(() => fromJS(response.data.data))))
//             })
//             .catch((error)=>dispatch(edit_user(getState().auth.user.update(() => Map({token:'failed'})))))
//
//         // dispatch(edit_user(getState().auth.user.updateIn(['token'], val => 'response.data.token')))
//     }
// }
//
//
export const logout = () => {
    return (dispatch, getState) => {
        localStorage.removeItem('refresh_token')
        localStorage.removeItem('token')
        dispatch({type: actionType.AUTH_LOGOUT})

    }
}
