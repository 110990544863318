import React from "react";
import './Plants.scss'
import Plant from "../Plant/Plant";
import {connect} from "react-redux";
import {onSelectedPlantChange} from "../../../store/actions/data";
import {fromJS} from "immutable";

const showPlantsList = fromJS([
    {'pName': 'B', 'id': 10000001},
    {'pName': 'I', 'id': 10000002},
    {'pName': 'R', 'id': 10000003},
    {'pName': 'A', 'id': 10000004},
    {'pName': 'P', 'id': 10000005},
    {'pName': 'I', 'id': 10000006},
    {'pName': 'E', 'id': 10000007},
    {'pName': 'R', 'id': 10000008},
    {'pName': 'D', 'id': 10000009},
    {'pName': 'T', 'id': 10000010},
    {'pName': 'C', 'id': 10000011},
    {'pName': 'J', 'id': 10000012},
    {'pName': 'P', 'id': 10000013},
    {'pName': 'Z', 'id': 10000014},
    {'pName': 'U', 'id': 10000015},
    {'pName': 'M', 'id': 10000016},
    {'pName': 'M', 'id': 10000017},
    {'pName': 'T', 'id': 10000018},
])

const Plants = (props) => {

    let showMode = localStorage.getItem('showMode') == 'true' || false

    let plants
    let showPlants
    if (props.plants.size > 0) {
        let _plants_list = props.plants
        if (showMode){
            _plants_list = _plants_list.concat(showPlantsList)
        }
        plants = _plants_list.sortBy(p=>p.get('pName')).map((p, i) => {
            return <Plant
                key={p.get('id')}
                data={p}
                onPlantClick={(...args) => props.onSelectedPlantChange(...args)}
                showMode={showMode}
            />
        })
    }
    return (
        <div className={'plants'}>
            {plants}
            {showPlants}
        </div>
    )
}

const mapStateToProps = state => {
    return {
        plants: state.meta.plants,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onSelectedPlantChange: (...args) => dispatch(onSelectedPlantChange(...args)),

    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Plants);
