import React from "react";
import './Plant.scss'
import {ButtonBase, Paper, Typography} from "@material-ui/core";

const Plant = (props) => {

    return (
        <Paper className={'plant'}>
            <Typography variant={"h3"}>{props?.showMode && props.data.get('pName') !== 'Fiera'? `${props.data.get('pName')[0]}...`:props.data.get('pName')}</Typography>
            <ButtonBase className={'content'} onClick={() => props.onPlantClick(props.data.get('id'))}/>
        </Paper>
    )
}

export default Plant;
