import React, {PureComponent} from "react";
import './BatchView.scss'
import {Step, StepLabel, Stepper} from "@material-ui/core";
import Plants from "../library/Plants/Plants";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import Machines from "../library/Machines/Machines";
import DataTable from "react-data-table-component";
import {getBatchReports, onSelectedMachineChange, onSelectedPlantChange} from "../../store/actions/data";
import moment from "moment";
import EditIcon from "@material-ui/icons/Edit";
import ConfirmPopUp from "../library/ConfirmPopUp/ConfirmPopUp";
import * as actionType from "../../store/actions/action";
import {PaginationItem, ToggleButton, ToggleButtonGroup} from "@material-ui/lab";
import {withMedia} from "react-media-query-hoc";

const confirmText = [`Sicuro di voler cambiare l'impianto`, `Sicuro di voler cambiare la macchina`,]


class BatchView extends PureComponent {

    state = {
        activeStep: 0,
        popUpConfirmResetFilter: false,
        resetStep: -1,
        activeTable: 1,
        width: 0,
        height: 0
    }
    request = false
    plant = 'Selezione impianto'
    machine = 'Selezione macchina'


    constructor(props) {
        super(props);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        this.setState({width: window.innerWidth, height: window.innerHeight});
    }


    resetFilter = (step) => {
        this.setState({popUpConfirmResetFilter: false})
        if (this.state.resetStep === 0 && this.state.activeStep > 0) {
            this.props.onSelectedPlantChange(0)
            this.props.resetMachineData()
            this.setState({'activeStep': 0})
        } else if (this.state.resetStep === 1 && this.state.activeStep > 1) {
            this.props.onSelectedMachineChange(0)
            this.props.resetMachineData()
            this.setState({'activeStep': 1})
        }
    }

    /* Ritorna true se lo stato attuale è uguale all' indice*/
    isStepActive = (idx) => {
        return idx === this.state.activeStep
    }

    /* Ritorna true se l' indice passato è maggiore dello step attuale*/
    isStepCompleted = (idx) => {
        return idx < this.state.activeStep
    }

    changeView = (id) => {
        this.setState({activeTable: id})
    }

    render() {
        const {t, i18n} = this.props

        if (this.props.plantId !== 0 && this.state.activeStep === 0) {
            this.setState({'activeStep': 1})
            this.request = false
            this.plant = `Impianto`
        }
        if (this.props.plantId !== 0 && this.props.machineId !== 0 && this.state.activeStep === 1) {
            this.setState({'activeStep': 2})
            this.request = false
            this.machine = `Macchina`
        }
        if (this.props.plantId !== 0 && this.props.machineId !== 0 && this.state.activeStep === 2 && !this.request) {
            this.request = true
            this.setState({'activeStep': 3})
            this.props.getBatchReports(this.props.machineId)
                .then(() => this.setState({'activeStep': 4}))
                .catch(() => this.setState({'activeStep': 4}))
        }
        if (this.props.batchReports.get('body') && this.state.activeStep === 3) {
            this.setState({'activeStep': 4})

        }

        /* Lista Step*/
        const showMode = localStorage.getItem('showMode') === 'true' || false
        const _plant = this.props.plants.find(p => p.get('id') === this.props.plantId)?.get('pName') || ''
        const steps = [
            t(this.plant, {'plant': showMode && _plant !== 'Fiera' ? `${_plant[0]}...` : _plant}),
            t(this.machine, {'machine': this.props.machines.find(m => m.get('id') === this.props.machineId)?.get('mName')}),
        ]

        const formatFunction = (unit, value, context, map) => {
            if (unit === 'datetime') {
                if (value === null || value === undefined) return '--'
                return moment.unix(value).format('DD/MM/YYYY HH:mm')
            } else if (unit === 'num') {
                if (value === null || value === undefined) return '--'
                return value
            } else if (unit.includes('context')) {
                if (!context || Object.keys(context).length === 0) return '--'
                const var_name = unit.split('.')[1]
                console.debug(context)
                return context[var_name]
            } else if (unit === 'value_map') {
                if (!value) return '--'
                return map?.get(value.toString())
            } else {
                if (value === null || value === undefined) return '--'
                return `${value} ${unit}`
            }
        }

        const columns = this.props.batchReports?.getIn(['header'])?.map((c, k) => {
            const description = c.getIn(['description'])
            return {
                id: k,
                name: description.get(i18n.language, description.first('NoText')),
                selector: `${k}`,
                sortable: true,
                format: (row) => formatFunction(c.getIn(['unit']), row[k]?.value, row[k]?.context, c.get('value_map')),
                minWidth: c.getIn(['unit']) === 'datetime' ? '150px' : ''
            }
        })

        const columnsCip = this.props.cipReports?.getIn(['header'])?.map((c, k) => {
            const description = c.getIn(['description'])
            return {
                id: k,
                name: description.get(i18n.language, description.first('NoText')),
                selector: `${k}`,
                sortable: true,
                format: (row) => formatFunction(c.getIn(['unit']), row[k]?.value, row[k]?.context, c.get('value_map')),
                minWidth: c.getIn(['unit']) === 'datetime' ? '150px' : ''
            }
        })

        const sortFunction = (rows, selector, direction) => {
            return rows.sort((a, b) => {
                // use the selector to resolve your field names by passing the sort comparators
                let aField = null
                let bField = null;
                if (selector in a) {
                    aField = a[selector]['value'];
                }
                if (selector in b) {
                    bField = b[selector]['value'];
                }

                let comparison = 0;

                if (aField > bField) {
                    comparison = 1;
                } else if (aField < bField) {
                    comparison = -1;
                }

                return direction === 'desc' ? comparison * -1 : comparison;
            });
        }

        const customSort = (col, a, b) => {
            if (a[col]['value'] < b[col]['value']) {
                return 1;
            }
            if (a[col]['value'] > b[col]['value']) {
                return -1;
            }
            return 0;
        };

console.debug(this.props.media)
        return (
            <React.Fragment>
                <div className={'batchView'}>
                    <Stepper activeStep={this.state.activeStep} elevation={5} className={'stepper'}>
                        {steps.map((label, index) => {
                            return (
                                <Step
                                    key={label}
                                    // icon={<EditIcon/>}
                                    onClick={() => this.state.activeStep > index && this.setState({
                                        popUpConfirmResetFilter: true,
                                        resetStep: index
                                    })}
                                >
                                    <StepLabel
                                        icon={
                                            this.isStepCompleted(index) ?
                                                <EditIcon style={{color: '#4caf50'}}/> :
                                                <PaginationItem page={index} shape={"round"} size={"small"} style={{
                                                    backgroundColor: this.isStepActive(index) ? '#ffeb3b' : '#9e9e9e',
                                                    color: '#000'
                                                }} selected={true}/>
                                        }
                                        className={this.isStepCompleted(index) ? 'step completedStep' : 'step'}
                                    >{label}</StepLabel>
                                </Step>
                            )
                        })}
                        {this.state.activeStep === 4 &&
                            <ToggleButtonGroup
                                value={this.state.activeTable}
                                exclusive
                                onChange={(e, newId) => this.changeView(newId)}
                                className={'tableSelection'}
                            >
                                <ToggleButton value={1} className={'buttonTableSelection'}>
                                    {t('Batch')}
                                </ToggleButton>
                                <ToggleButton value={2} className={'buttonTableSelection'}>
                                    {t('Cip')}
                                </ToggleButton>
                            </ToggleButtonGroup>
                        }

                    </Stepper>
                    <div className={'contentBatch'}>

                        {
                            this.state.activeStep === 0 ? <Plants/> : null
                        }
                        {
                            this.state.activeStep === 1 ? <Machines/> : null
                        }
                        {/*{*/}
                        {/*    this.state.activeStep === 2 ? <Loading/> : null*/}
                        {/*}*/}
                        {
                            (this.state.activeStep >= 2) && this.state.activeTable === 1 &&
                            <div className={'batch'}>
                                <DataTable
                                    data={this.props.batchReports.get('body')?.toJS().sort((a, b) => customSort(0, a, b))}
                                    title={t('Batch')}
                                    columns={columns?.toList().sortBy(c => parseInt(c.id)).toJS()}
                                    theme="dark"
                                    progressPending={this.state.activeStep < 4}
                                    highlightOnHover
                                    // selectableRows
                                    // Clicked
                                    // conditionalRowStyles={conditionalRowStyles}
                                    // dense
                                    // style={{height:'Calc(100% - 50px)'}}
                                    fixedHeader={true}
                                    fixedHeaderScrollHeight={'Calc(100vh - 335px)'}
                                    defaultSortFieldId={'100'}
                                    defaultSortAsc={false}
                                    sortFunction={sortFunction}
                                    pagination={true}
                                    paginationPerPage={this.props.media.show?(this.state.height - 370) / 50: 20}
                                    paginationRowsPerPageOptions={[15, 20, 25, 30]}
                                />
                            </div>
                        }
                        {
                            (this.state.activeStep >= 2) && this.state.activeTable === 2 &&
                            <div className={'batch'}>
                                <DataTable
                                    data={this.props.cipReports.get('body')?.toJS().sort((a, b) => customSort(100, a, b))}
                                    title={t('Cip')}
                                    columns={columnsCip?.toList().sortBy(c => parseInt(c.id)).toJS()}
                                    theme="dark"
                                    progressPending={this.state.activeStep < 4}
                                    highlightOnHover
                                    // selectableRows
                                    // Clicked
                                    // conditionalRowStyles={conditionalRowStyles}
                                    // dense
                                    // style={{height:'Calc(100% - 50px)'}}
                                    fixedHeader={true}
                                    fixedHeaderScrollHeight={'Calc(100vh - 335px)'}
                                    defaultSortFieldId={'100'}
                                    sortFunction={sortFunction}
                                    pagination={true}
                                    paginationPerPage={this.props.media.show?(this.state.height - 370) / 50: 20}
                                    paginationRowsPerPageOptions={[15, 20, 25, 30]}
                                    defaultSortAsc={false}
                                    paginationComponentOptions={{
                                        rowsPerPageText: `${t('Righe per pagina')}:`,
                                        rangeSeparatorText: t('Di'),
                                        noRowsPerPage: false,
                                        selectAllRowsItem: false,
                                        selectAllRowsItemText: 'All'
                                    }}
                                />
                            </div>
                        }
                    </div>
                </div>
                <ConfirmPopUp
                    open={this.state.popUpConfirmResetFilter}
                    handleAccept={this.resetFilter}
                    handleDecline={() => this.setState({popUpConfirmResetFilter: false})}
                    title={t('Reset')}
                    description={t(confirmText[this.state.resetStep])}
                    ko={t('Annulla')}
                    ok={t('Conferma')}
                />
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        machineId: state.data.machineId,
        plantId: state.data.plantId,
        batchReports: state.data.batchReports,
        cipReports: state.data.cipReports,
        plants: state.meta.plants,
        machines: state.meta.machines,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getBatchReports: (...args) => dispatch(getBatchReports(...args)),
        onSelectedPlantChange: (...args) => dispatch(onSelectedPlantChange(...args)),
        onSelectedMachineChange: (...args) => dispatch(onSelectedMachineChange(...args)),
        resetMachineData: () => dispatch(dispatch({
            type: actionType.RESET_MACHINE_META
        })),

    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withMedia(BatchView)));
