import React from "react";
import './ConfirmPopUp.scss'
import {Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import DialogContentText from "@material-ui/core/DialogContentText";
import Button from "@material-ui/core/Button";


const ConfirmPopUp = (props) => {
    return (
        <Dialog
            open={props.open}
            onClose={props.handleDecline}
        >
            <DialogTitle>{props.title}</DialogTitle>
            <DialogContent>
                <DialogContentText>{props.description}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={()=>props.handleDecline()} variant={"text"}>
                    {props.ko}
                </Button>
                <Button onClick={()=>props.handleAccept()} variant={"outlined"} autoFocus>
                    {props.ok}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default ConfirmPopUp;