import React, {useEffect, useRef} from "react";
import {withMedia} from "react-media-query-hoc";
import useDimensions from "react-cool-dimensions";
import * as echarts from "echarts";
import * as _ from 'lodash';

const EchartsGauge = (props) => {
    const chartRef = useRef();
    const chartInst = useRef();
    let myChart
    const resize = _.debounce(() => chartInst.current?.resize(), 1000)

    const {observe, unobserve, width, height, entry} = useDimensions({
        onResize: ({observe, unobserve, width, height, entry}) => {
            // Triggered whenever the size of the target is changed...
            chartInst.current?.resize()
            unobserve(); // To stop observing the current target element
            observe(); // To re-start observing the current target element
        },
    });


    useEffect(() => {
        myChart = echarts.init(chartRef.current);
        chartInst.current = myChart
        const option = {
            series: [
                {
                    name: 'main',
                    type: 'gauge',
                    center: ['50%', '68%'],
                    startAngle: 200,
                    endAngle: -20,
                    min: props.minScale || 0,
                    max: props.maxScale || 60,
                    // radius: '100%',
                    splitNumber: 10,
                    itemStyle: {
                        color: '#FFAB91'
                    },
                    progress: {
                        show: true,
                        width: 30
                    },
                    pointer: {
                        show: false
                    },
                    axisLine: {
                        lineStyle: {
                            width: 30
                        }
                    },
                    axisTick: {
                        distance: -45,
                        splitNumber: 5,
                        lineStyle: {
                            width: 1,
                            color: '#999'
                        }
                    },
                    splitLine: {
                        distance: -52,
                        length: 14,
                        lineStyle: {
                            width: 2,
                            color: '#999'
                        }
                    },
                    axisLabel: {
                        distance: -10,
                        color: '#999',
                        fontSize: 14
                    },
                    anchor: {
                        show: false
                    },
                    title: {
                        show: false
                    },
                    detail: {
                        valueAnimation: true,
                        width: '60%',
                        lineHeight: 40,
                        borderRadius: 8,
                        offsetCenter: [0, '-5%'],
                        fontSize: 16,
                        fontWeight: 'bolder',
                        formatter: `{value} ${props.unit}`,
                        color: 'auto'
                    },
                    data: [
                        {
                            value: Math.round(props.value*100)/100
                        }
                    ]
                },
                {
                    name: 'sec',
                    type: 'gauge',
                    center: ['50%', '68%'],
                    startAngle: 200,
                    endAngle: -20,
                    min: props.minScale || 0,
                    max: props.maxScale || 60,
                    itemStyle: {
                        color: '#FD7347'
                    },
                    progress: {
                        show: true,
                        width: 8
                    },
                    pointer: {
                        show: false
                    },
                    axisLine: {
                        show: false
                    },
                    axisTick: {
                        show: false
                    },
                    splitLine: {
                        show: false
                    },
                    axisLabel: {
                        show: false
                    },
                    detail: {
                        show: false
                    },
                    data: [
                        {
                            value: Math.round(props.value*100)/100
                        }
                    ]
                }
            ]
        };
        // use configuration item and data specified to show chart
        myChart.setOption(option);

    }, [])

    useEffect(() => {
        chartInst.current?.setOption({
            series: [
                {
                    name: 'main',
                    min: props.minScale || 0,
                    max: props.maxScale || 60,
                    data: [
                        {
                            value: Math.round(props.value*100)/100
                        }
                    ],
                    detail:{
                        fontSize: Math.min(width/10, height/10)
                    }
                },
                {
                    name: 'sec',
                    min: props.minScale || 0,
                    max: props.maxScale || 60,
                    data: [
                        {
                            value: Math.round(props.value*100)/100
                        }
                    ]
                }
            ]
        })
        chartInst.current.resize()
    }, [props, chartInst])

    return (
        <div
            id={'sdchart-gauge'}
            ref={observe}
            style={{width: '100%', height: 'calc(100% - 32px)'}}
        >
            <div ref={chartRef}
                 id={'chart-gauge'}
                 style={{width: '100%', height: '90%'}}
            />

        </div>


        // </div>
    )
}

export default withMedia(EchartsGauge);
