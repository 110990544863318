import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import Backend from 'i18next-chained-backend';
import LocalStorageBackend from 'i18next-localstorage-backend';
import HttpApi from 'i18next-http-backend';
import yaml from 'js-yaml';

i18n
    .use(Backend)
    .use(initReactI18next)
    .init({
        // we init with resources
        preload: ['it'],
        lng: localStorage.getItem('language'),
        fallbackLng: "it",
        debug: false,
        wait: true,
        initImmediate: false,

        // have a common namespace used around the full app
        ns: ["translations"],
        defaultNS: "translations",

        keySeparator: false, // we use content as keys

        interpolation: {
            escapeValue: false, // not needed for react!!
            formatSeparator: ","
        },
        backend: {
            backends: [
                LocalStorageBackend,  // primary
                HttpApi               // fallback
            ],
            backendOptions: [
                {
                    // prefix for stored languages
                    prefix: 'i18next_res_',

                    // expiration
                    expirationTime: 1 * 1 * 1 * 60 * 1000,
                    // expirationTime: 7*24*60*60*1000,

                    // Version applied to all languages, can be overriden using the option `versions`
                    defaultVersion: '',

                    // language versions
                    versions: {},

                    // can be either window.localStorage or window.sessionStorage. Default: window.localStorage
                    store: window.localStorage
                },
                {
                    // loadPath: basePath + '/locales/{{lng}}/{{ns}}.yaml', // xhr load path for my own fallback
                    loadPath: '/locales/{{lng}}/{{ns}}.yml', // xhr load path for my own fallback
                    parse: function(data) { return yaml.load(data) },
                }
                ]
        },
        // backend: {
        //     // for all available options read the backend's repository readme file
        //     // loadPath: '/locales/{{lng}}/{{ns}}.json'
        //     loadPath: 'http://10.1.3.152:5000/API/v1/locales/{{lng}}/{{ns}}.json',
        //     requestOptions: { // used for fetch, can also be a function (payload) => ({ method: 'GET' })
        //         cache: 'no-cache'
        //     }
        // },
        react: {
            wait: true,
            useSuspense: false
        }
    }, (err, t) => {
         console.error('i18next.language', i18n.language)
    });

export default i18n;
