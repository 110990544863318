import React, {useEffect, useState} from "react";
import style from './NumberWidget.module.scss';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Paper,
    Typography
} from "@material-ui/core";
import EchartsGauge from "../EchartsGauge/EchartsGauge";
import {useTranslation} from "react-i18next";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {Map} from "immutable";
import {useSelector} from "react-redux";
import EditDashboard from "../icons/EditDashboard";
import ConfirmPopUp from "../ConfirmPopUp/ConfirmPopUp";


const NumberWidget = ({title, value, eventCodeKey, unit, editMode, minScale, maxScale, onEdit, onDelete, index}) => {
    const {t} = useTranslation()
    const [diagState, setDiagState] = useState(false)
    const [savEventCodeKey, setSavEventCodeKey] = useState(eventCodeKey)
    const [savMinScale, setSavMinScale] = useState(minScale)
    const [savMaxScale, setSavMaxScale] = useState(maxScale)
    const [popUpDeleteConfirm, setPopUpDeleteConfirm] = useState(false)
    const eventCodes = useSelector(state => state.meta.eventCode)

    useEffect(() => {
        setSavEventCodeKey(eventCodeKey)
        setSavMinScale(minScale)
        setSavMaxScale(maxScale)
    }, [diagState])

    const tmp = savEventCodeKey.split('.')
    const eventType = tmp[0]
    const eventCodeId = tmp[1]
    const eventCode = eventCodes.find(ec => ec.get('eventType') === parseInt(eventType) && ec.get('id') === parseInt(eventCodeId))
    return (
        <>
            <Paper className={style.numberWidget}>
                <Typography variant={'h6'} className={'title'}
                            style={{textAlign: 'initial', padding: '5px 0 5px 8px', cursor: editMode?'grab':"auto"}}>{title}</Typography>
                {/*<Divider style={{margin:'7px 0 0'}}/>*/}
                <EchartsGauge value={value} unit={unit} minScale={savMinScale} maxScale={savMaxScale}/>
                {
                    editMode &&
                    <IconButton color="primary" component="span" className={style.editButton}>
                        <EditDashboard style={{fill: 'white', height: '20px', width: '20px'}}
                                       onClick={() => setDiagState(true)}/>
                    </IconButton>
                }
            </Paper>
            <Dialog open={diagState} onClose={() => setDiagState(false)} className={style.editDialog}
                    component={<div draggable={false}></div>}>
                <DialogTitle>{t('Modifica widget')}</DialogTitle>
                <DialogContent className={style.content}>
                    <Autocomplete
                        size="medium"
                        limitTags={5}
                        disableCloseOnSelect
                        value={eventCode.get('pkId')}
                        onChange={(e, v) => {
                            const res = eventCodes.find(ec => ec.get('pkId') == v)
                            setSavEventCodeKey(`${res.get('eventType')}.${res.get('id')}`)
                        }}
                        options={eventCodes?.filter(ec => [1, 3, 6].includes(ec.get('eventType')))?.map(ec => ec.get('pkId')?.toString()).toJS()}
                        getOptionLabel={(option) => {
                            const description = eventCodes.find(ec => ec.get('pkId') == option)?.getIn(['description'])
                            return description?.get(localStorage.getItem('lang'), description.first())
                            // props.eventCode.find(ec => ec.get('pkId') == option).getIn(['description', localStorage.getItem('lang')], '')
                        }}
                        noOptionsText={t('Nessuna scelta disponibile')}
                        className={'textFieldMultiple'}
                        renderInput={(params) => (
                            <TextField {...params}
                                       variant="outlined"
                                       label={t("Codice Evento")}
                                       size={"medium"}
                            />
                        )}
                    />

                    <TextField
                        label={t('Valore scala minimo')}
                        variant="outlined"
                        type={'number'}
                        value={savMinScale}
                        style={{marginRight: '12px'}}
                        onChange={(e) => setSavMinScale(e.target.value)}
                        onBlur={(e) => {
                            !e.target.value ? setSavMinScale(0) : setSavMinScale(parseInt(e.target.value))
                        }}
                    />

                    <TextField
                        label={t('Valore scala massimo')}
                        variant="outlined"
                        type={'number'}
                        value={savMaxScale}
                        onChange={(e) => setSavMaxScale(e.target.value)}
                        onBlur={(e) => {
                            !e.target.value ? setSavMaxScale(0) : setSavMaxScale(parseInt(e.target.value))
                        }}
                    />
                </DialogContent>
                <DialogActions className={style.actions}>
                    <Button
                        variant={'outlined'}
                        onClick={() => setPopUpDeleteConfirm(true)}
                        style={{margin: 'auto auto auto 0px'}}
                    >
                        {t('Elimina')}
                    </Button>
                    <Button
                        variant={'outlined'}
                        onClick={() => setDiagState(false)}
                    >
                        {t('Annulla')}
                    </Button>
                    <Button
                        variant={'contained'}
                        onClick={() => onEdit(eventCodeKey, savEventCodeKey, Map({
                            maxScale: savMaxScale,
                            minScale: savMinScale
                        })).then(() => setDiagState(false))}
                    >
                        {t('Conferma')}
                    </Button>
                </DialogActions>
            </Dialog>

            <ConfirmPopUp
                open={popUpDeleteConfirm}
                handleAccept={() => onDelete(index)}
                handleDecline={() => setPopUpDeleteConfirm(false)}
                title={t('Eliminazione Widget')}
                description={t('Sicuro di voler eliminare il widget?')}
                ko={t('Annulla')}
                ok={t('Conferma')}
            />
        </>
    )
}

export default NumberWidget
