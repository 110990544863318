import React, {useState} from "react";
import './DeviceFilterPage.scss'
import Autocomplete from "@material-ui/lab/Autocomplete";
import {Button, TextField} from "@material-ui/core";
import {fromJS} from "immutable";
import {KeyboardDateTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import moment from "moment";
import MomentUtils from "@date-io/moment";
import {withTranslation} from "react-i18next";
import VisualizationType from "../VisualizationType/VisualizationType";
import ConfirmPopUp from "../ConfirmPopUp/ConfirmPopUp";


const DeviceFilterPage = (props) => {
    const {t} = props
    const [errors, setErrors] = useState({})
    const [showTable, setShowTable] = useState(props.showTable)
    const [showGraph, setShowGraph] = useState(props.showGraph)
    const [resetFilter, setResetFilter] = useState(false)

    const handleValidation = () => {
        let new_errors = {}
        if (props.filters.getIn(['device']).size <= 0) new_errors = {
            ...new_errors,
            device: t('Nessun dispositivo selezionato')
        }
        if ((props.filters.get('startTime') instanceof moment && !props.filters.get('startTime')._isValid) || props.filters.get('startTime') === null) new_errors = {
            ...new_errors,
            startTime: t('Data di inizio non valida')
        }
        if (props.filters.get('endTime') instanceof moment && !props.filters.get('endTime')._isValid) new_errors = {
            ...new_errors,
            endTime: t('Data di fine non valida')
        }
        if (props.filters.getIn(['device']).size > 0 && props.filters.getIn(['eventCode']).size <= 0) new_errors = {
            ...new_errors,
            eventCode: t('Nessun evento selezionato')
        }
        if (!showTable && !showGraph) new_errors = {
            ...new_errors,
            visualization: t('Nessuna modalità di visualizzazione selezionata')
        }
        setErrors(old => ({
            ...old,
            ...new_errors
        }))
        if (Object.keys(new_errors)?.length === 0) props.sendFilters(showTable, showGraph)
    }

    /* Funzione di reset allarmi e invio modifiche */
    const handleChange = (key, value) => {
        let temp = {...errors}
        delete temp[key]
        setErrors(temp)
        props.onChangeFilter(key, value)
    }


    return (
        <div className={'deviceFilterPage'}>
            <Autocomplete
                multiple
                size="medium"
                limitTags={5}
                disableCloseOnSelect
                value={props.filters.getIn(['device'])?.toJS()}
                onChange={(e, v, r) => handleChange('device', fromJS(v))}
                options={props.devices?.map(d => d.get('id')?.toString()).toJS()}
                getOptionLabel={(option) => props.devices.find(d => d.get('id') == option)?.getIn(['dName'], '')}
                // renderOption={}
                noOptionsText={t('Nessuna scelta disponibile')}
                className={'textFieldMultiple'}
                renderInput={(params) => (
                    <TextField {...params}
                               error={errors['device']}
                               helperText={errors['device']}
                               variant="outlined"
                               label={t("Dispositivi")}
                               size={"medium"}
                    />
                )}
            />
            <Autocomplete
                multiple
                size="medium"
                limitTags={5}
                disableCloseOnSelect
                value={props.filters.getIn(['eventCode'])?.toJS()}
                onChange={(e, v, r) => handleChange('eventCode', fromJS(v))}
                options={props.eventCode?.filter(ec => props.filters.getIn(['device']).includes(ec.get('deviceId')?.toString())).map(ec => ec.get('pkId')?.toString()).toJS()}
                getOptionLabel={(option) => props.eventCode.find(ec => ec.get('pkId') == option)?.getIn(['description', localStorage.getItem('lang')], '')}
                // renderOption={}
                noOptionsText={t('Nessuna scelta disponibile')}
                className={'textFieldMultiple'}
                renderInput={(params) => (
                    <TextField {...params}
                               error={errors['eventCode']}
                               helperText={errors['eventCode']}
                               variant="outlined"
                               label={t("Codice Evento")}
                               size={"medium"}
                    />
                )}
            />

            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={moment.locale()}>
                <KeyboardDateTimePicker
                    variant="inline"
                    inputVariant="outlined"
                    className={'textField'}
                    ampm={false}
                    label={t('Da')}
                    value={props.filters.getIn(['startTime'])}
                    onChange={(date) => handleChange('startTime', date)}
                    onError={console.log}
                    // disablePast
                    format="DD/MM/yyyy HH:mm"
                    error={errors['startTime']}
                    helperText={errors['startTime']}
                />
                <KeyboardDateTimePicker
                    variant="inline"
                    inputVariant="outlined"
                    className={'textField'}
                    ampm={false}
                    label={t('A')}
                    value={props.filters.getIn(['endTime'])}
                    onChange={(date) => handleChange('endTime', date)}
                    onError={console.log}
                    // disablePast
                    disableFuture
                    format="DD/MM/yyyy HH:mm"
                    error={errors['endTime']}
                    // helperText={props.filters.getIn(['endTime']) - props.filters.getIn(['startTime']) > 86400000? 'tanto':'poco'}
                    helperText={errors['endTime']}
                />
            </MuiPickersUtilsProvider>
            <VisualizationType
                showGraph={showGraph}
                showTable={showTable}
                setShowTable={setShowTable}
                setShowGraph={setShowGraph}
            />

            <div>
                <Button
                    variant={"outlined"}
                    className={'button'}
                    color={'primary'}
                    onClick={()=>setResetFilter(true)}
                >{t('Reset Filtri')}</Button>
                <Button
                    variant={"contained"}
                    color={'primary'}
                    className={'button'}
                    onClick={handleValidation}
                >{t('Invia filtri')}</Button>
            </div>

            <ConfirmPopUp
                open={resetFilter}
                handleAccept={() => {
                    setResetFilter(false)
                    props.resetFilter()
                }}
                handleDecline={() => setResetFilter(false)}
                title={t('Reset Filtro')}
                description={t('Sei sicuro di voler cancellare i filtri?')}
                ko={t('Annulla')}
                ok={t('Conferma')}
            />
        </div>
    )
}

export default withTranslation()(DeviceFilterPage);