import React, {useState} from "react";
import './AvatarUser.scss'
import {Avatar, ClickAwayListener, Paper, Typography} from "@material-ui/core";
import {withTranslation} from "react-i18next";
import Button from "@material-ui/core/Button";
import UserSettingsPopUp from "../UserSettingsPopUp/UserSettingsPopUp";

const AvatarUser = (props) => {
    const [popUpInfo, setPopUpInfo] = useState(false);
    const [popUpSettings, setPopUpSettings] = useState(false);

    const openInfoPopUp = () => {
        setPopUpInfo(!popUpInfo)
    }
    const closeInfoPopUp = () => {
        setPopUpInfo(false)
    }
    const openSettingsPopUp = () => {
        setPopUpSettings(true)
    }
    const closeSettingsPopUp = () => {
        setPopUpSettings(false)
    }

    return (
        <React.Fragment>
            <ClickAwayListener
                mouseEvent={"onMouseUp"}
                onClickAway={closeInfoPopUp}>
                <div>
                    <Avatar onClick={openInfoPopUp} style={{backgroundColor: '#d7d7d7'}}>{props.user.get('name', '')?.slice(0, 1)}</Avatar>
                    <Paper
                        id="mouse-over-popover"
                        className={popUpInfo ? 'userPopUp userPopUpOpen' : 'userPopUp userPopUpClose'}
                        style={{zIndex: 3000}}
                    >
                        <div className={'container'}>
                            <Avatar
                                className={'avatar'}>{props.user.get('name', '')?.slice(0, 1)}</Avatar>
                            <Typography
                                className={'username'}>{`${props.user.get('name')} ${props.user.get('surname')}`}</Typography>
                            <Typography
                                className={'email'}>{props.user.getIn(['email'])}</Typography>
                            <div className={'action'}>
                                <Button className={'logoutUser'} variant="outlined"
                                        onClick={props.logout}>Logout</Button>
                                <Button className={'logoutUser'} variant="contained"
                                        onClick={openSettingsPopUp}>Impostazioni</Button>
                            </div>
                        </div>
                    </Paper>
                </div>
            </ClickAwayListener>
            <UserSettingsPopUp
                open={popUpSettings}
                user={props.user}
                onClose={closeSettingsPopUp}
                onChangePassword={(...args) => props.changeUserPassword(...args)}
                onSave={(...args) => props.updateUser(...args)}
            />
        </React.Fragment>
    )
}

export default withTranslation()(AvatarUser);