import React, {Component} from 'react'
import TextField from '@material-ui/core/TextField';
import "./AddUserPopUp.scss"
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import {connect} from "react-redux";
import {Dialog, MenuItem} from "@material-ui/core";
import {withTranslation} from "react-i18next";
import {addUser} from "../../../store/actions/meta";

class AddUserPopUp extends Component {

    state = {
        email: {
            value: '',
            validation: {
                required: true,
                isEmail: true
            },
            valid: false,
        },
        password: {
            value: '',
            validation: {
                required: true,
                minLength: 8
            },
            valid: true,
        },
        name: {
            value: '',
            validation: {
                required: false,
                minLength: 1
            },
            valid: false,
        },
        surname: {
            value: '',
            validation: {
                required: false,
                minLength: 1
            },
            valid: false,
        },
        role: {
            value: 0,
            validation: {
                isNumeric: true
            },
            valid: false,
        },
        logged: false,
    };

    checkValidity(value, rules) {
        let isValid = true;
        if (!rules) {
            return true;
        }

        if (rules.required) {
            isValid = value.trim() !== '' && isValid;
        }

        if (rules.minLength) {
            isValid = value.length >= rules.minLength && isValid
        }

        if (rules.maxLength) {
            isValid = value.length <= rules.maxLength && isValid
        }

        if (rules.isEmail) {
            const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
            isValid = pattern.test(value) && isValid
        }

        if (rules.isNumeric) {
            const pattern = /^\d+$/;
            isValid = pattern.test(value) && isValid
        }

        return isValid;
    }

    handleEmailChange = (event) => {
        this.setState({
                email: {
                    ...this.state.email,
                    value: event.target.value,
                    valid: this.checkValidity(event.target.value, this.state.email.validation),
                }
            }
        )
    };

    handleNameChange = (event) => {
        this.setState({
                name: {
                    ...this.state.name,
                    value: event.target.value,
                    valid: this.checkValidity(event.target.value, this.state.name.validation),
                }
            }
        )
    };

    handleSurnameChange = (event) => {
        this.setState({
                surname: {
                    ...this.state.surname,
                    value: event.target.value,
                    valid: this.checkValidity(event.target.value, this.state.surname.validation),
                }
            }
        )
    };

    handlePasswordChange = (event) => {
        this.setState({
                password: {
                    ...this.state.password,
                    value: event.target.value,
                    valid: this.checkValidity(event.target.value, this.state.password.validation),
                }
            }
        )
    };

    handleRoleChange = (event) => {
        this.setState({
                role: {
                    ...this.state.role,
                    value: event.target.value,
                    valid: this.checkValidity(event.target.value, this.state.role.validation),
                }
            }
        )
    };

    render() {
        const {t} = this.props
        if (!this.state.logged) {
            return (
                <Dialog open={this.props.open} onClose={this.props.onCLose} className="addUserPopUp">
                    <form className={"auth_form"}>
                        <Typography variant={'h5'} style={{
                            marginBottom: '15px',
                            color: '#e0e0e0',
                            alignSelf: 'start',
                            marginLeft: '17px'
                        }} color="textPrimary">{t('Aggiunta utente')}</Typography>
                        <TextField
                            id={'regSurname'}
                            className="text_field"
                            label="Cognome"
                            fullWidth={true}
                            type="text"
                            variant="outlined"
                            inputProps={{
                                autocomplete: 'new-password',
                                form: {
                                    autocomplete: 'off',
                                },
                            }}
                            onChange={this.handleSurnameChange}
                            error={this.state.surname.value.length > 0 && !this.state.surname.valid}
                            value={this.state.surname.value}

                        />
                        <TextField
                            id={'regName'}
                            className="text_field"
                            label="Nome"
                            fullWidth={true}
                            type="text"
                            variant="outlined"
                            inputProps={{
                                autocomplete: 'new-password',
                                form: {
                                    autocomplete: 'off',
                                },
                            }}

                            onChange={this.handleNameChange}
                            error={this.state.name.value.length > 0 && !this.state.name.valid}
                            value={this.state.name.value}

                        />
                        <TextField
                            id={'regEmail'}
                            className="text_field"
                            label="Email"
                            fullWidth={true}
                            type="email"
                            variant="outlined"
                            autoComplete='off'
                            inputProps={{
                                autocomplete: 'new-password',
                                form: {
                                    autocomplete: 'off',
                                },
                            }}
                            onChange={this.handleEmailChange}
                            error={this.state.email.value.length > 0 && !this.state.email.valid}
                            value={this.state.email.value}

                        />
                        <TextField
                            id={'regPass'}
                            className="text_field"
                            label="Password"
                            type="Password"
                            fullWidth={true}
                            variant="outlined"
                            autoComplete='off'
                            helperText={t('Lunghezza minima 8 caratteri')}
                            inputProps={{
                                autocomplete: 'new-password',
                                form: {
                                    autocomplete: 'off',
                                },
                            }}
                            onChange={this.handlePasswordChange}
                            error={!this.state.password.valid}
                            value={this.state.password.value}
                        />
                        <TextField
                            select
                            className="text_field"
                            label={t('Ruolo')}
                            type={'numeric'}
                            fullWidth={true}
                            variant="outlined"
                            autoComplete='off'
                            onChange={this.handleRoleChange}
                            error={!this.state.role.valid}
                            value={this.state.role.value}
                        >
                            {this.props.roles.filter(u => u.get('name') !== 'dev').map((option) => (
                                <MenuItem key={option.get('id')} value={option.get('id')}>
                                    {option.get('name')}
                                </MenuItem>
                            ))}
                        </TextField>
                        <div className={'action'}>
                            <Button
                                className="login_button"
                                variant="text"
                                color="primary"
                                fullWidth={true}
                                onClick={this.props.onCLose}
                            >
                                {t('Annulla')}
                            </Button>
                            <Button
                                className="login_button"
                                variant="contained"
                                color="primary"
                                fullWidth={true}
                                disabled={
                                    !this.state.role.valid ||
                                    !this.state.password.valid ||
                                    !this.state.surname.valid ||
                                    !this.state.name.valid ||
                                    !this.state.email.valid
                                }
                                onClick={() => this.props.addUser(
                                    this.state.name.value,
                                    this.state.surname.value,
                                    this.state.email.value,
                                    this.state.password.value,
                                    this.state.role.value).then(this.props.onCLose)}
                            >
                                {t('Aggiungi')}
                            </Button>
                        </div>
                        {/*<Link to={{pathname: '/SignIn'}}>*/}
                        {/*    <Typography className="registration_button" color="textPrimary"*/}
                        {/*                variant="caption">Accedi</Typography>*/}
                        {/*</Link>*/}
                    </form>
                </Dialog>
            )
        } else {
            return (
                <div>
                    {this.props.children}
                </div>
            )
        }
    }
}

const mapStateToProps = state => {
    return {
        roles: state.meta.roles,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        addUser: (...args) => dispatch(addUser(...args)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(AddUserPopUp));
