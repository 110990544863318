import React, {Component} from "react";
import "./Layout.scss"
import {AppBar, Button, Toolbar, Typography} from "@material-ui/core";
import {withRouter} from "react-router";
import {getEventClass, getGroups, getMachines, getPlants, getPlantsAndMachines, getType} from "../store/actions/meta";
import {connect} from "react-redux";
import AvatarUser from "../components/library/AvatarUser/AvatarUser";
import {changeUserPassword, logout, updateUser} from "../store/actions/auth";
import {withMedia} from "react-media-query-hoc";
import SettingsRoundedIcon from '@material-ui/icons/SettingsRounded';
import TableChartRoundedIcon from '@material-ui/icons/TableChartRounded';
import AssessmentRoundedIcon from '@material-ui/icons/AssessmentRounded';
import {withTranslation} from "react-i18next";
import DashboardRoundedIcon from '@material-ui/icons/DashboardRounded';
import ConfirmationNumberRoundedIcon from "@material-ui/icons/ConfirmationNumberRounded";

class Layout extends Component {

    componentDidMount() {
        this.props.getPlants()
        this.props.getMachines()
        this.props.getType()
        this.props.getEventClass()
        this.props.getGroups()
    }

    onClickNavigation(path) {
        this.props.history.push(path);
    }

    render() {
        const {t} = this.props

        return (
            <div className={'layout'}>
                <AppBar position="static" className={'appBar'}>
                    <Toolbar className={'toolBar'}>
                        <div className={'left'}>
                            {
                                (this.props.media.desktop || this.props.media.largeDesktop || this.props.media.tablet) &&
                                <div className={'logo'}>
                                    <img src="logo-compact-white.png" alt="" style={{height: '40px',}}/>
                                    {/*<Typography variant="h6">*/}
                                    {/*    Customer First*/}
                                    {/*</Typography>*/}
                                </div>
                            }
                            <Button color="inherit" onClick={() => this.onClickNavigation('/')}
                                    className={'menuButton'}>
                                <DashboardRoundedIcon/>
                                {
                                    this.props.media.genPc &&
                                    <Typography>{t('Dashboard')}</Typography>
                                }
                            </Button>
                            <Button color="inherit" onClick={() => this.onClickNavigation('/batch')}
                                    className={'menuButton'}>
                                <TableChartRoundedIcon/>
                                {
                                    this.props.media.genPc &&
                                    <Typography>{t('Bach/Cip')}</Typography>
                                }
                            </Button>
                            <Button color="inherit" onClick={() => this.onClickNavigation('/DataView')}
                                    className={'menuButton'}>
                                <AssessmentRoundedIcon/>
                                {
                                    this.props.media.genPc &&
                                    <Typography>{t('Dati')}</Typography>
                                }
                            </Button>
                            {
                                this.props.userRole.get('mngTickets') &&
                                <Button color="inherit" onClick={() => this.onClickNavigation('/tickets')}
                                        className={'menuButton'}>
                                    <ConfirmationNumberRoundedIcon/>
                                    {
                                        this.props.media.genPc &&
                                        <Typography>{t('Tickets')}</Typography>
                                    }
                                </Button>
                            }
                            <Button color="inherit" onClick={() => this.onClickNavigation('/settings')}
                                    className={'menuButton'}>
                                <SettingsRoundedIcon/>
                                {
                                    this.props.media.genPc &&
                                    <Typography>{t('Impostazioni')}</Typography>
                                }
                            </Button>
                        </div>
                        {/*<div className={'center'}>*/}
                        {/*</div>*/}
                        <div className={'right'}>
                            <AvatarUser
                                user={this.props.user}
                                changeUserPassword={(...args) => this.props.changeUserPassword(...args)}
                                updateUser={(...args) => this.props.updateUser(...args)}
                                logout={this.props.logout}
                            />
                        </div>
                    </Toolbar>
                </AppBar>
                <div className={'content'}>
                    {this.props.children}
                </div>
            </div>
        )
    }
}


const mapStateToProps = state => {
    return {
        user: state.auth.user.get('user'),
        userRole: state.auth.user.getIn(['user', 'role'])

    };
};

const mapDispatchToProps = dispatch => {
    return {
        getType: (...args) => dispatch(getType(...args)),
        getGroups: (...args) => dispatch(getGroups(...args)),
        getEventClass: (...args) => dispatch(getEventClass(...args)),
        getPlants: (...args) => dispatch(getPlants(...args)),
        getMachines: (...args) => dispatch(getMachines(...args)),
        updateUser: (...args) => dispatch(updateUser(...args)),
        changeUserPassword: (...args) => dispatch(changeUserPassword(...args)),
        logout: () => dispatch(logout()),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withRouter(withMedia(Layout))));
