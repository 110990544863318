import {enqueueSnackbar} from "./actions/notification";

export const updateObject = (oldObject, updatedProperties) => {
    return {
        ...oldObject,
        ...updatedProperties
    };
};

/**
 * Parsing dell'oggetto query
 * @param query
 */
export const createQueryString = (query) => {
    let q = new URLSearchParams('');
    Object.keys(query).forEach(key => {
        let val = query[key];

        if (val === undefined || val == null) return;

        if (typeof val === "object" && val.length > 0) {
            val.forEach(elem => q.append(key, elem))
        } else {
            q.append(key, val);
        }

    })
    return '?' + q.toString();
}

export const sendGetRequest = (url, token = null) => {
    let xhr = new XMLHttpRequest();
    xhr.open('GET', url, false);
    xhr.setRequestHeader('Content-Type', 'application/json');
    if (token !== null) {
        xhr.setRequestHeader('Authorization', `Bearer ${token}`);
    }
    xhr.send();
    return JSON.parse(xhr.response);
}

export const sendPostRequest = (url, data, token = null) => {
    let xhr = new XMLHttpRequest();
    xhr.open('POST', url, false);
    xhr.setRequestHeader('Content-Type', 'application/json');

    if (token !== null) xhr.setRequestHeader('Authorization', `Bearer ${token}`);

    xhr.send(JSON.stringify(data));
    return JSON.parse(xhr.response);
}

export const errorNotification = (err, msgList = {}) => {
    let message = 'Errore interno,\nRiprovare!'
    if (err.response) {
        Object.keys(msgList).forEach(k => {
            if (err.response.status === k) message = msgList[k]
        })
    } else {
        message = err.message
    }
    return enqueueSnackbar({
        message: message,
        options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
            autoHideDuration: 3000,
        },
    })
}