import React, {useEffect, useState} from "react";
import style from './MachineSettingsPopUp.module.scss'
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    IconButton,
    MenuItem,
    Paper,
    TextField
} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {Map} from 'immutable'
import Typography from "@material-ui/core/Typography";
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded';

const MachineSettingsPopUp = ({state, machine, userEmail, mngMode, userList, onClose, onSave}) => {
    const {t, i18n} = useTranslation()
    const [tempMachine, setTempMachine] = useState(Map({}))

    useEffect(() => setTempMachine(machine), [machine])


    if (state) {
        const alreadySharedUser = machine.get('sharedTo').map(s=>s.get('userEmail'))

        return (
            <Dialog open={state} className={style.machineSettingsPopUp} fullWidth maxWidth={"xs"} onClose={onClose}>
                <DialogTitle>{t('Macchina')} {machine.get('mName')}</DialogTitle>
                <DialogContent className={style.content}>
                    <div className={style.general}>
                        <TextField
                            label={t('Descrizione')}
                            value={tempMachine.get('mName')}
                            variant={"outlined"}
                            onChange={(e) => setTempMachine(old => {
                                if (mngMode){
                                    return old.update('mName', () => e.target.value)
                                }
                            })}
                            error={tempMachine.get('mName', '').length <= 0}
                            required={true}
                            className={'textField'}
                            disabled={!mngMode}
                        />
                        <TextField
                            label={t('Numero seriale')}
                            value={tempMachine.get('serialNumber')}
                            variant={"outlined"}
                            type={'number'}
                            onChange={(e) => setTempMachine(old => {
                                if (mngMode){
                                    return old.update('serialNumber', () => e.target.value)
                                }
                            })}
                            error={tempMachine.get('serialNumber', '').length <= 0}
                            required={true}
                            disabled={!mngMode}
                        />
                        <div className={style.emailAlert}>
                            <Typography>{t('Alert email')}</Typography>
                            <Checkbox
                                size={"medium"}
                                color={"primary"}
                                checked={tempMachine.get('sharedTo')?.find(s => s.get('userEmail') === userEmail)?.get('emailAlert')}
                                disabled={!tempMachine.get('sharedTo')?.find(s => s.get('userEmail') === userEmail)}
                                onChange={(e) => {
                                    setTempMachine(old => {
                                        const shareIndex = old.get('sharedTo').findIndex(s => s.get('userEmail') === userEmail)
                                        return old.updateIn(['sharedTo', shareIndex, 'emailAlert'], () => e.target.checked)
                                    })
                                }}
                            />
                        </div>
                    </div>
                    {
                        mngMode &&
                        <>
                            <Divider variant={"fullWidth"}/>
                            <div className={style.sharing}>

                                <Typography>{t('Condivisioni')}</Typography>
                                <div className={style.sharingComponents}>

                                    {tempMachine.get('sharedTo')?.map((s, index) => {
                                        return <Paper
                                            key={s.get('new') ? s.get('machineId') + s.get('new') : s.get('machineId') + s.get('userEmail')}
                                            variant={"outlined"}
                                            elevation={0}
                                            className={style.shareComponent}
                                        >
                                            {s.get('new') ?

                                                <TextField
                                                    select
                                                    className="text_field"
                                                    label={t('Gruppo')}
                                                    type={'numeric'}
                                                    fullWidth={true}
                                                    variant="outlined"
                                                    autoComplete='off'
                                                    // onChange={(e, n) => editUser('groupId', e.target.value)}
                                                    onChange={(e) => {
                                                        setTempMachine(old => {
                                                            return old.updateIn(['sharedTo', index, 'userEmail'], () => e.target.value)
                                                        })
                                                    }}
                                                    value={s.get('userEmail')}
                                                >
                                                    {userList.filter(us => !alreadySharedUser.includes(us.get('email')) && us.getIn(['role', 'name']) !== 'dev').map((option) => (
                                                        <MenuItem key={option.get('id')} value={option.get('email')}>
                                                            {option.get('email')}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                                // <TextField
                                                //     variant={"standard"}
                                                //     fullWidth
                                                //     value={s.get('userEmail')}
                                                //     onChange={(e) => {
                                                //         setTempMachine(old => {
                                                //             const shareIndex = old.get('sharedTo').findIndex(s => s.get('userEmail') === userEmail)
                                                //             return old.updateIn(['sharedTo', index, 'userEmail'], () => e.target.value)
                                                //         })
                                                //     }}
                                                // />
                                                : <Typography>{s.get('userEmail')}</Typography>
                                            }
                                            <IconButton size={"small"} onClick={() => setTempMachine(old => {
                                                return old.updateIn(['sharedTo'], o => o.delete(index))
                                            })}>
                                                <DeleteRoundedIcon/>
                                            </IconButton>

                                        </Paper>
                                    })}
                                    <Button
                                        key={'new'}
                                        color={"secondary"}
                                        endIcon={<AddCircleRoundedIcon/>}
                                        variant={"contained"}
                                        fullWidth
                                        onClick={() => setTempMachine(old => {
                                            return old.updateIn(['sharedTo'], o => o.push(Map({
                                                emailAlert: true,
                                                machineId: tempMachine.get('id'),
                                                sharedFrom: null,
                                                userEmail: '',
                                                new: new Date().getMilliseconds()
                                            })))
                                        })}
                                    >
                                        {t('Aggiungi utente')}
                                    </Button>
                                </div>
                            </div>
                        </>
                    }
                </DialogContent>
                <DialogActions>
                    <Button variant={"outlined"} onClick={onClose}>{t('Annulla')}</Button>
                    <Button variant={"contained"} onClick={() => onSave(tempMachine)}>{t('Salva')}</Button>
                </DialogActions>
            </Dialog>
        )
    }
    return <></>
}

export default MachineSettingsPopUp
