import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import style from "./NewWidgetDialog.module.scss";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import Typography from "@material-ui/core/Typography";
import {ToggleButton, ToggleButtonGroup} from "@material-ui/lab";
import Gauge from "../icons/Gauge";
import Counter from "../icons/Counter";

const NewWidgetDialog = ({state, onClose, onAdd}) => {
    const {t} = useTranslation()
    const [savEventCodeKey, setSavEventCodeKey] = useState()
    const [savVisType, setSavVisType] = useState(1)
    const [savMinScale, setSavMinScale] = useState(0)
    const [savMaxScale, setSavMaxScale] = useState(10)
    const [error, setError] = useState('')
    const eventCodes = useSelector(state => state.meta.eventCode)

    useEffect(() => {

    }, [])

    let eventCode
    if (savEventCodeKey) {
        const tmp = savEventCodeKey?.split('.')
        const eventType = tmp[0]
        const eventCodeId = tmp[1]
        eventCode = eventCodes.find(ec => ec.get('eventType') === parseInt(eventType) && ec.get('id') === parseInt(eventCodeId))
    }


    return (
        <Dialog open={state} onClose={() => onClose(false)} className={style.newWidgetDialog}
                component={<div draggable={false}></div>}>
            <DialogTitle>{t('Modifica widget')}</DialogTitle>
            <DialogContent className={style.content}>
                <div className={style.firstRow}>
                    <Autocomplete
                        size="medium"
                        limitTags={5}
                        disableCloseOnSelect
                        value={eventCode?.get('pkId')}
                        onChange={(e, v, r) => {
                            const res = eventCodes.find(ec => ec.get('pkId') == v)
                            setSavEventCodeKey(`${res.get('eventType')}.${res.get('id')}`)
                        }}
                        options={eventCodes?.filter(ec => [1, 3, 5, 6].includes(ec.get('eventType')))?.map(ec => ec.get('pkId')?.toString()).toJS()}
                        getOptionLabel={(option) => {
                            const description = eventCodes.find(ec => ec.get('pkId') == option)?.getIn(['description'])
                            return description?.get(localStorage.getItem('lang'), description.first())
                            // props.eventCode.find(ec => ec.get('pkId') == option).getIn(['description', localStorage.getItem('lang')], '')
                        }}
                        noOptionsText={t('Nessuna scelta disponibile')}
                        className={style.event}
                        renderInput={(params) => (
                            <TextField {...params}
                                       variant="outlined"
                                       label={t("Codice Evento")}
                                       size={"medium"}
                            />
                        )}
                    />
                    {eventCode?.get('unit') !== 'value_map' && !eventCode?.get('unit').includes('context') &&
                        <ToggleButtonGroup
                            size={"small"}
                            style={{height: 40, margin: '8px auto 4px 7px'}}
                            value={savVisType}
                            exclusive
                            onChange={(e, v)=>setSavVisType(v)}
                            aria-label="text alignment"
                        >
                            <ToggleButton value={1} style={{height: 40}}>
                                <Gauge style={{fill: "#FFF", height: '24px'}}/>
                            </ToggleButton>
                            <ToggleButton value={2} style={{height: 40}}>
                                <Counter style={{fill: "#FFF", height: '24px'}}/>
                            </ToggleButton>
                        </ToggleButtonGroup>
                    }
                </div>

                {eventCode?.get('unit') !== 'value_map' && !eventCode?.get('unit').includes('context') && savVisType === 1 &&<>
                    <TextField
                        label={t('Valore scala minimo')}
                        style={{marginRight: '12px'}}
                        variant="outlined"
                        type={'number'}
                        value={savMinScale}
                        onChange={(e) => setSavMinScale(e.target.value)}
                        onBlur={(e) => {
                            !e.target.value ? setSavMinScale(0) : setSavMinScale(parseInt(e.target.value))
                        }}
                    />
                    <TextField
                        label={t('Valore scala massimo')}
                        variant="outlined"
                        type={'number'}
                        value={savMaxScale}
                        onChange={(e) => setSavMaxScale(e.target.value)}
                        onBlur={(e) => {
                            !e.target.value ? setSavMaxScale(0) : setSavMaxScale(parseInt(e.target.value))
                        }}
                    />
                </>}
            </DialogContent>
            <Typography>{error}</Typography>
            <DialogActions>
                <Button
                    variant={'outlined'}
                    onClick={() => onClose(false)}
                >
                    {t('Annulla')}
                </Button>
                <Button
                    variant={'contained'}
                    onClick={() => onAdd(savEventCodeKey, savMinScale, savMaxScale, savVisType).then(() => onClose(false)).catch(e => setError(e))}
                >
                    {t('Conferma')}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default NewWidgetDialog;
