import React, {useEffect, useState} from "react";
import './UserSettingsPopUp.scss'
import {Dialog, DialogContent, MenuItem, TextField, Typography} from "@material-ui/core";
import {withTranslation} from "react-i18next";
import Button from "@material-ui/core/Button";
import {Map} from 'immutable'


const UserSettingsPopUp = ({t, user, open, mngMode, roles, groups, onClose, onSave, onChangePassword}) => {
    const [tempUser, setTempUser] = useState(user)
    const [newPassword, setNewPassword] = useState(Map({
        newPassword: '',
        newPasswordCopy: ''
    }))

    useEffect(() => {
        setTempUser(user)
    }, [user])

    const editUser = (key, value) => {
        setTempUser(old => old.update(key, () => value))
    }

    const editPassword = (key, value) => {
        setNewPassword(old => old.update(key, () => value))
    }


    return (
        <Dialog open={open} onClose={onClose} onEnter={() => setTempUser(tempUser)} className={'userSettingsPopUp'}>
            {/*<DialogTitle className={'title'}>*/}
            {/*    {t('Impostazioni utente')}*/}
            {/*</DialogTitle>*/}
            <DialogContent className={'content'} >
                <Typography variant={'h5'} style={{margin: '0 0 10px 0'}}>{t('Impostazioni utente')}</Typography>
                <TextField
                    label={t('Nome')}
                    value={tempUser.get('name')}
                    variant={"outlined"}
                    onChange={(e) => editUser('name', e.target.value)}
                    error={tempUser.get('name', '').length <= 0}
                    required={true}
                    className={'textField'}
                />
                <TextField
                    label={t('Cognome')}
                    value={tempUser.get('surname')}
                    variant={"outlined"}
                    onChange={(e) => editUser('surname', e.target.value)}
                    error={tempUser.get('surname', '').length <= 0}
                    required={true}
                />
                <TextField
                    label={t('Email')}
                    value={tempUser.get('email')}
                    variant={"outlined"}
                    onChange={(e) => editUser('email', e.target.value)}
                    error={tempUser.get('email', '').length <= 0}
                    required={true}
                />
                {
                    mngMode &&
                    <>
                        <TextField
                            select
                            className="text_field"
                            label={t('Ruolo')}
                            type={'numeric'}
                            fullWidth={true}
                            variant="outlined"
                            autoComplete='off'
                            onChange={(e, n) => editUser('roleId', e.target.value)}
                            value={tempUser.get('roleId')}
                        >
                            {roles.filter(u => u.get('name') !== 'dev').map((option) => (
                                <MenuItem key={option.get('id')} value={option.get('id')}>
                                    {option.get('name')}
                                </MenuItem>
                            ))}
                        </TextField>
                        <TextField
                            select
                            className="text_field"
                            label={t('Gruppo')}
                            type={'numeric'}
                            fullWidth={true}
                            variant="outlined"
                            autoComplete='off'
                            onChange={(e, n) => editUser('groupId', e.target.value)}
                            value={tempUser.get('groupId')}
                        >
                            {groups.filter(u => u.get('name') !== 'dev').map((option) => (
                                <MenuItem key={option.get('id')} value={option.get('id')}>
                                    {option.get('name')}
                                </MenuItem>
                            ))}
                        </TextField>
                    </>
                }
                <div className={'action'}>
                    <Button variant={"outlined"} onClick={onClose}>{t('Annulla')}</Button>
                    <Button variant={"contained"} onClick={() => onSave(tempUser)}>{t('Salva')}</Button>
                </div>
                {/*{*/}
                {/*    !mngMode &&*/}
                    <>
                        <Typography variant={'h5'} style={{margin: '20px 0 10px 0'}}>{t('Cambio Password')}</Typography>
                        <TextField
                            id="outlined-password-input"
                            label={t('Nuova password')}
                            value={newPassword.get('newPassword')}
                            variant={"outlined"}
                            type={'password'}
                            onChange={(e) => editPassword('newPassword', e.target.value)}
                            error={newPassword.get('newPassword', '')?.length < 8 && newPassword.get('newPassword', '')?.length > 0}
                            required={true}
                            inputProps={{
                                autocomplete: 'new-password',
                                form: {
                                    autocomplete: 'off',
                                },
                            }}
                        />
                        <TextField
                            id="outlined-copy-password-input"
                            label={t('Ripeti password')}
                            value={newPassword.get('newPasswordCopy')}
                            variant={"outlined"}
                            type={'password'}
                            onChange={(e) => editPassword('newPasswordCopy', e.target.value)}
                            error={newPassword.get('newPasswordCopy')?.length < 8 && newPassword.get('newPasswordCopy')?.length > 0}
                            required={true}
                            helperText={t('min. 8')}
                            inputProps={{
                                autocomplete: 'new-password',
                                form: {
                                    autocomplete: 'off',
                                },
                            }}
                        />
                        <Button
                            variant={"contained"}
                            style={{margin: '15px 0 10px'}}
                            onClick={() => onChangePassword(newPassword.get('newPassword'), newPassword.get('newPasswordCopy'), tempUser.get('id')).then(onClose)}
                            disabled={newPassword.get('newPassword') !== newPassword.get('newPasswordCopy') || newPassword.get('newPasswordCopy').length < 8}
                        >{t('Cambia password')}</Button>
                    </>
                {/*}*/}
            </DialogContent>
        </Dialog>
    )
}

export default withTranslation()(UserSettingsPopUp);
