import React, {Component} from "react";
import "./Login.scss"
import {Button, IconButton, InputAdornment, Paper, TextField, Typography} from "@material-ui/core";
import {connect} from "react-redux";
import {login} from "../../store/actions/auth";
import {withTranslation} from "react-i18next";
import {Visibility, VisibilityOff} from "@material-ui/icons";


class Login extends Component {

    state = {
        email: '',
        password: '',
        showPassword: false,
    }

    login = () => {
        this.props.login(this.state.email, this.state.password)
    }

    onEmailChangeHandle = (value) => {
        this.setState({email: value})
    }

    onPasswordChangeHandle = (value) => {
        this.setState({password: value})
    }

    handleClickShowPassword = () => {
        this.setState((old) => ({showPassword: !old.showPassword}))
    };

    handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    render() {
        const {t} = this.props;
        return (
            <div className={'login'}>
                <Paper className={'loginBody'}>
                    <div className={'logo'}>
                        <img src="logo_TMCI.png" alt=""/>
                    </div>
                    <div className={'auth_form'}>
                        <Typography variant={"h4"} style={{
                            marginBottom: '15px',
                            marginTop: '20px',
                            color: '#e0e0e0',
                            alignSelf: 'start',
                            marginLeft: '17px'
                        }}>{t('Login')}</Typography>
                        <form>
                            <TextField
                                className="text_field"
                                label={t('Email')}
                                id={'email'}
                                type={'email'}
                                variant={"outlined"}
                                fullWidth={true}
                                value={this.state.email}
                                onChange={(e, t) => this.onEmailChangeHandle(e.target.value)}
                            />
                            <TextField
                                className="text_field"
                                label={t('Password')}
                                id={'password'}
                                variant={"outlined"}
                                fullWidth={true}
                                value={this.state.password}
                                onChange={(e) => this.onPasswordChangeHandle(e.target.value)}
                                type={this.state.showPassword ? 'text' : 'password'}
                                InputProps={{
                                    endAdornment:
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={this.handleClickShowPassword}
                                                onMouseDown={this.handleMouseDownPassword}
                                            >
                                                {this.state.showPassword ? <Visibility/> : <VisibilityOff/>}
                                            </IconButton>
                                        </InputAdornment>
                                }}
                            />
                            <div className={'action'}>
                                <Button className="login_button" color="primary" variant={"contained"}
                                        onClick={this.login}>{t('Login')}</Button>
                            </div>
                        </form>
                    </div>
                </Paper>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {};
};

const mapDispatchToProps = dispatch => {
    return {
        login: (...args) => dispatch(login(...args))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Login));
