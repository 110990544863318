import React, {Component} from 'react';

const AxiosManager = (WrappedComponent, axiosInst) => {

    class Jwt extends Component {

        componentWillMount() {
            this.reqInterceptor = axiosInst.interceptors.request.use(req => {
                // req.headers['Content-Type'] = 'application/json'
                if (req.headers.__isRetry) {
                    console.log('Retry Request', req)
                    return req
                }
                req.headers['Authorization'] = 'Bearer ' + localStorage.getItem("token")
                // console.log('Request', req)
                return req
            })
            this.resInterceptor = axiosInst.interceptors.response.use(res => {
                // console.log(res)
                return res

            }, error => {
                // Se l'errore è diverso da 401 ritorno l'errore
                if (!error.response) {
                    return new Promise((resolve, reject) => {
                        reject(error);
                    });
                }
                if (error.response.status !== 401 || !process.env.REACT_APP_REFRESH_TOKEN_ENABLED || error.config.headers.__isRetry || !localStorage.getItem("refresh_token")) {
                    return new Promise((resolve, reject) => {
                        reject(error);
                    });
                }

                // Logout user if token refresh didn't work or user is disabled
                if (error.config.url === 'refresh' || error.response.message === 'Account is disabled.') {
                    // TokenStorage.clear();
                    // router.push({name: 'root'});

                    return new Promise((resolve, reject) => {
                        reject(error);
                    });
                }

                // Richiesta aggiornamento token
                return fetch(process.env.REACT_APP_BASE_URL + '/refresh', {
                    method: 'POST',
                    mode: 'cors',
                    cache: 'no-cache',
                    credentials: 'same-origin',
                    headers: {
                        'Content-Type': 'application/json',
                        'Device': 'device',
                        'Authorization': 'Bearer ' + localStorage.getItem("refresh_token")
                    },
                    redirect: 'follow',
                    referrer: 'no-referrer',
                })
                    .then(res => {
                        if (!res.ok) {
                            console.error(`Token non rinnovabile ${res.status} - ${res.statusText}`)
                            return
                        }
                        return res.json()
                    })
                    .then((res) => {
                        if (!res) return new Promise((resolve, reject) => {
                            reject(error);
                        });
                        const token = res.data.token

                        localStorage.setItem('token', token);

                        const config = error.config;
                        config.headers['Authorization'] = `Bearer ${token}`;
                        config.headers['__isRetry'] = true;

                        // Tentativo di rieseguire la richiesta
                        return new Promise((resolve, reject) => {
                            axiosInst.request(config).then(response => {
                                resolve(response);
                            }).catch((error) => {
                                reject(error);
                            })
                        });
                    })
                    // .catch((error) => {
                    //     Promise.reject(error);
                    // });
            })
        }

        componentWillUnmount() {
            axiosInst.interceptors.request.eject(this.reqInterceptor)
            axiosInst.interceptors.response.eject(this.resInterceptor)
        }

        render() {
            return <WrappedComponent {...this.props}/>
        }
    }

    return Jwt;

}

export default AxiosManager;
