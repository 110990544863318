import style from './TextWidget.module.scss';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Paper,
    Typography
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import ConfirmPopUp from "../ConfirmPopUp/ConfirmPopUp";
import React, {useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import EditDashboard from "../icons/EditDashboard";
import * as _ from "lodash";
import useDimensions from "react-cool-dimensions";
import moment from "moment";

const TextWidget = ({title, value, unit, eventCodeKey, editMode, onEdit, onDelete, index, addTitleText}) => {
    const {t} = useTranslation()
    const chartInst = useRef();
    const [diagState, setDiagState] = useState(false)
    const [savEventCodeKey, setSavEventCodeKey] = useState(eventCodeKey)
    const [popUpDeleteConfirm, setPopUpDeleteConfirm] = useState(false)
    const eventCodes = useSelector(state => state.meta.eventCode)

    const resize = _.debounce(() => chartInst.current?.resize(), 1000)

    const {observe, unobserve, width, height, entry} = useDimensions({
        onResize: ({observe, unobserve, width, height, entry}) => {
            // Triggered whenever the size of the target is changed...
            chartInst.current?.resize()
            unobserve(); // To stop observing the current target element
            observe(); // To re-start observing the current target element
        },
    });

    useEffect(() => {
        setSavEventCodeKey(eventCodeKey)
    }, [diagState])

    const tmp = savEventCodeKey.split('.')
    const eventType = tmp[0]
    const eventCodeId = tmp[1]
    const eventCode = eventCodes.find(ec => ec.get('eventType') === parseInt(eventType) && ec.get('id') === parseInt(eventCodeId))

    if (unit === 'datetime'){
        value = moment(value*1000).format('DD/MM/YYYY HH:mm')
        unit = ''
    }

    const fontSize = Math.min(-0.034 * value?.length + 1.28, height/(20*Math.max(value?.length, 5)+50))
    return (
        <>
            <Paper className={style.textWidget} ref={observe}>
                <Typography variant={'h6'} className={'title'}
                            style={{
                                minHeight:10,
                                textAlign: 'initial',
                                margin: '10px 0 0 10px',
                                cursor: editMode ? 'grab' : "auto"
                            }}>{title} {addTitleText? '- ' + addTitleText:''}</Typography>
                <svg width="100%" height="100%" viewBox='0 0 110 9'>
                    <text x="55" y={height / (3.0*Math.max(value?.length, 5))} fontSize={`${fontSize}rem`} fill={"#FFF"}
                          text-anchor="middle">{value} {unit!=='num'?unit:''}</text>
                </svg>
                {/*<Typography variant={'h3'} className={style.data}*/}
                {/*            style={{textAlign: 'center', fontSize: ((width) / (value?.length>9?value?.length:value?.length*2))*1.8}}>{value}</Typography>*/}
                {
                    editMode &&
                    <IconButton color="primary" component="span" className={style.editButton}>
                        <EditDashboard style={{fill: 'white', height: '24px', width: '24px'}}
                                       onClick={() => setDiagState(true)}/>
                    </IconButton>
                }
            </Paper>
            <Dialog open={diagState} onClose={() => setDiagState(false)} className={style.editDialog}
                    component={<div draggable={false}></div>}>
                <DialogTitle>{t('Modifica widget')}</DialogTitle>
                <DialogContent className={style.content}>
                    <Autocomplete
                        size="medium"
                        limitTags={5}
                        disableCloseOnSelect
                        value={eventCode?.get('pkId')}
                        onChange={(e, v) => {
                            const res = eventCodes.find(ec => ec.get('pkId') == v)
                            setSavEventCodeKey(`${res.get('eventType')}.${res.get('id')}`)
                        }}
                        options={eventCodes?.filter(ec => [1, 3, 6].includes(ec.get('eventType')))?.filter(ec => ec.get('unit') === 'value_map')?.map(ec => ec.get('pkId')?.toString()).toJS()}
                        getOptionLabel={(option) => {
                            const description = eventCodes.find(ec => ec.get('pkId') == option)?.getIn(['description'])
                            return description?.get(localStorage.getItem('lang'), description.first())
                            // props.eventCode.find(ec => ec.get('pkId') == option).getIn(['description', localStorage.getItem('lang')], '')
                        }}
                        noOptionsText={t('Nessuna scelta disponibile')}
                        className={'textFieldMultiple'}
                        renderInput={(params) => (
                            <TextField {...params}
                                       variant="outlined"
                                       label={t("Codice Evento")}
                                       size={"medium"}
                            />
                        )}
                    />
                </DialogContent>
                <DialogActions className={style.actions}>
                    <Button
                        variant={'outlined'}
                        onClick={() => setPopUpDeleteConfirm(true)}
                        style={{margin: 'auto auto auto 0px'}}
                    >
                        {t('Elimina')}
                    </Button>
                    <Button
                        variant={'outlined'}
                        onClick={() => setDiagState(false)}
                    >
                        {t('Annulla')}
                    </Button>
                    <Button
                        variant={'contained'}
                        onClick={() => onEdit(eventCodeKey, savEventCodeKey).then(() => setDiagState(false))}
                    >
                        {t('Conferma')}
                    </Button>
                </DialogActions>
            </Dialog>

            <ConfirmPopUp
                open={popUpDeleteConfirm}
                handleAccept={() => onDelete(index)}
                handleDecline={() => setPopUpDeleteConfirm(false)}
                title={t('Eliminazione Widget')}
                description={t('Sicuro di voler eliminare il widget?')}
                ko={t('Annulla')}
                ok={t('Conferma')}
            />
        </>
    )
}

export default TextWidget
