import './settings.scss'
import {Divider, List, ListItem, ListItemIcon, ListItemText, Paper} from "@material-ui/core";
import SupervisorAccountRoundedIcon from '@material-ui/icons/SupervisorAccountRounded';
import DeveloperBoardRoundedIcon from '@material-ui/icons/DeveloperBoardRounded';
import {Route} from "react-router-dom";
import React, {useEffect} from "react";
import {Switch, useHistory, useRouteMatch} from "react-router";
import UserSettings from "../UserSettings/UserSettings";
import {connect, useSelector} from "react-redux";
import {getRoles, getUsers} from "../../store/actions/meta";
import MachineSettings from "../MachineSettings/MachineSettings";
import WebRoundedIcon from '@material-ui/icons/WebRounded';
import GeneralSettings from "../GeneralSettings/GeneralSettings";

const Settings = ({getUsers, getRoles}) => {
    let {path} = useRouteMatch();
    const history = useHistory()

    const role = useSelector(state => state.auth.user.getIn(['user', 'role']))

    useEffect(() => {
        getRoles();
    }, [])

    return (
        <div className={'settings'}>
            <Paper className={'menu'}>
                <List>
                    {
                        role.get('settings') &&
                        <>
                            <ListItem button className={'navigationButton'}
                                      onClick={() => history.push(path + '/general')}>
                                <ListItemIcon><WebRoundedIcon/></ListItemIcon>
                                <ListItemText primary={'Generali'}/>
                            </ListItem>
                            <Divider/>
                            <ListItem button className={'navigationButton'}
                                      onClick={() => history.push(path + '/user')}>
                                <ListItemIcon><SupervisorAccountRoundedIcon/></ListItemIcon>
                                <ListItemText primary={'Utenti'}/>
                            </ListItem>
                            <Divider/>
                        </>
                    }
                    <ListItem button className={'navigationButton'} onClick={() => history.push(path + '/machine')}>
                        <ListItemIcon><DeveloperBoardRoundedIcon/></ListItemIcon>
                        <ListItemText primary={'Macchine'}/>
                    </ListItem>
                    <Divider/>
                </List>
            </Paper>
            <div className="settingsContent">
                <Switch>
                    <Route exact path={path + '/machine'} component={MachineSettings}/>
                    {
                        role.get('settings') &&
                        <>
                        <Route exact path={path + '/user'} component={UserSettings}/>
                        <Route exact path={path + '/general'} component={GeneralSettings}/>
                        </>
                    }
                </Switch>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        userList: state.meta.userList,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getUsers: (...args) => dispatch(getUsers(...args)),
        getRoles: (...args) => dispatch(getRoles(...args)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
