import React from "react";
import './FavoritesFilterPage.scss'
import {withTranslation} from "react-i18next";
import {List, Typography} from "@material-ui/core";
import FavoritesFilter from "../../FavoritesFilter/FavoritesFilter";
import {connect} from "react-redux";
import {deleteFavoriteFilters} from "../../../store/actions/meta";


const FavoritesFilterPage = ({t, favoriteFilters, deleteFavoriteFilters, onSelect}) => {

    let items
    if (favoriteFilters && favoriteFilters.size > 0) {
        items = favoriteFilters.map(f => {
            return (<FavoritesFilter
                key={f.get('id')}
                filter={f}
                onDelete={(...args)=>deleteFavoriteFilters(...args)}
                onSelect={onSelect}
            />)
        })
    } else{
        items =<Typography variant={'h5'} style={{textAlign:"center", marginTop:'10px'}}>{t('Nessun filtro preferito salvato')}</Typography>
    }

    return (
        <List dense={true}>
            {items}
        </List>
    )
}

export default withTranslation()(FavoritesFilterPage);
