import React, {PureComponent} from "react";
import * as echarts from "echarts";
import {withMedia} from "react-media-query-hoc";

class EchartsLineChart extends PureComponent {
    chartRef = React.createRef();

    // componentDidMount() {
    //     const myChart = echarts.init(this.chartRef.current);
    //     var option = {
    //         title: {
    //             text: 'ECharts entry example'
    //         },
    //         xAxis: {
    //             type: 'time'
    //         },
    //         yAxis: this.props.graphSeries,
    //         tooltip: {
    //             trigger: 'axis',
    //             axisPointer: {
    //                 type: 'cross',
    //                 animation: false,
    //                 label: {
    //                     backgroundColor: '#505765'
    //                 }
    //             }
    //         },
    //         series: this.props.graphData
    //     };
    //
    //     // use configuration item and data specified to show chart
    //     myChart.setOption(option);
    //
    // }
    componentDidMount() {
        this.myChart = echarts.init(this.chartRef.current);

        window.addEventListener('resize', () => resize());
        // Resize function
        const resize = () => {
            setTimeout(() => {
                // Resize chart
                this.myChart.resize();
            }, 200);
        }



        var option = {
            title: {
                // text: this.props.data.name,
                textStyle: {
                    color: 'white',
                    // fontFamily: 'sdaa'
                    fontSize: 15
                }
            },

            legend: {
                type: "scroll",
                // left: "5%",
                textStyle: {
                    color: '#FFF'
                },
                icon: "roundRect",
                pageButtonItemGap: 10,
                pageIconColor: '#FFF',
                pageIconInactiveColor: '#bdbdbd',
                pageIconSize: 25,
                pageTextStyle: {
                    color: '#FFF',
                    fontSize: 18,
                },
            },
            // color: this.props.color,
            grid: {
                // left: '8%',
                right: '2%',
                // bottom: '0%',
                // top: '10%',
                containLabel: true
            },
            xAxis: {
                type: 'time',

                // axisLabel: {
                //     formatter: (function(value){
                //         return moment(value).format();
                //     })
                // },
                axisLabel: {
                    interval: 0,
                    rotate: 30 //If the label names are too long you can manage this by rotating the label.
                }
            },
            yAxis: this.props.graphSeries,
            tooltip: {
                renderMode: 'html',
                confine: true,
                trigger: 'axis',
                axisPointer: {
                    type: 'cross',
                    // animation: false,
                    label: {
                        backgroundColor: '#505765'
                    }
                },
            },
            dataZoom: [
                // {
                //     type: 'inside',
                //     xAxisIndex: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
                //     zoomOnMouseWheel: true,
                //     preventDefaultMouseMove: true,
                //     start: 88,
                //     end: 100
                // },
                // {
                //     type: 'inside',
                //     yAxisIndex: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
                //     start: 0,
                //     end: 100,
                //     filterMode: 'none',
                //     zoomOnMouseWheel: 'shift'
                // },
                {
                    // type: 'inside',
                    show: true,
                    xAxisIndex: 0,
                    // yAxisIndex: 0,
                    type: 'slider',
                    top: '85%',
                    start: 88,
                    end: 100,
                    // labelPrecision:1000000,
                    filterMode: 'none',
                    // handleSize: '80%'
                    showDataShadow: true,
                    textStyle: {
                        color: "rgba(255, 255, 255, 1)"
                    }

                }
            ],
            series: this.props.graphData
        };

        // use configuration item and data specified to show chart
        this.myChart.setOption(option);

    }

    render() {

        if (this.myChart) {
            this.myChart.setOption({
                yAxis: this.props.graphSeries,
                series: this.props.graphData
            })
            if (this.props.media.tablet || this.props.media.desktop || this.props.media.largeDesktop) {
                this.myChart.setOption({
                    grid: {
                        left: '5%',
                    }
                })
            }
        }

        return (
            // <div className={''} style={{position: 'relative', padding:'10px'}}>
            <div id={'chart-line'} ref={this.chartRef} style={{width: '100%', height: this.props.fullHeight? document.body.scrollHeight - 100: this.props.midHeight?'1500px':'500px'}}/>


            // </div>
        )
    }
}

export default withMedia(EchartsLineChart);
