import style from './GeneralSettings.module.scss'
import {FormControlLabel, Switch} from "@material-ui/core";
import {useState} from "react";

const GeneralSettings = ({}) => {

    const [showMode, setShowMode] = useState(localStorage.getItem('showMode') == 'true' || false)


    return <div className={style.generalSettings}>
        <FormControlLabel
            control={<Switch
                checked={showMode}
                onChange={(event)=> {
                    setShowMode(event.target.checked)
                    localStorage.setItem('showMode', event.target.checked)
                }}
                name="Modalità fiera"
                size={"medium"}
            />}
            label="Modalità fiera"
        />
    </div>
}

export default GeneralSettings;
