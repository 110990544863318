import React from 'react';
import './Machines.scss'
import {onSelectedMachineChange} from "../../../store/actions/data";
import {connect} from "react-redux";
import Machine from "../Machine/Machine";

const Machines = (props) => {

    let machines
    if (props.machines.size > 0){
        machines = props.machines.filter(m => m.get('plantId') === props.plantId).sortBy(m => m.get('id')).map(m => {
            return <Machine
            key={m.get('id')}
            data={m}
            onMachineClick={(...args)=> props.onSelectedMachineChange(...args)}
            />
        })
    }

    return (
        <div className={'machines'}>
            {machines}
        </div>
    );
}

const mapStateToProps = state => {
    return {
        machines: state.meta.machines,
        plantId: state.data.plantId,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onSelectedMachineChange: (...args) => dispatch(onSelectedMachineChange(...args)),

    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Machines);
