import React, {useMemo} from "react";
import './PdfPopUpVisualization.scss'
import {Dialog, DialogTitle, Typography} from "@material-ui/core";
import {Viewer} from '@react-pdf-viewer/core';
import {defaultLayoutPlugin} from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import {toolbarPlugin, ToolbarSlot} from '@react-pdf-viewer/toolbar';


const transform = (slot: ToolbarSlot) => ({
    ...slot,
    // These slots will be empty
    Download: () => <></>,
    DownloadMenuItem: () => <></>,
    // EnterFullScreen: () => <></>,
    // EnterFullScreenMenuItem: () => <></>,
    // SwitchTheme: () => <></>,
    // SwitchThemeMenuItem: () => <></>,
    Print: () => <></>,
    Open: () => <></>,
});


const PdfPopUpVisualization = ({open, pdfUrl, fileName, onClose}) => {
    const toolbarPluginInstance = toolbarPlugin();
    const {renderDefaultToolbar, Toolbar} = toolbarPluginInstance;

    const renderToolbar = (Toolbar: (props) => React.ReactElement) => (
        <Toolbar>{renderDefaultToolbar(transform)}</Toolbar>
    );

    const defaultLayoutPluginInstance = defaultLayoutPlugin({
        renderToolbar: renderToolbar,
        sidebarTabs: (defaultTabs) => [
            // Remove the attachments tab (\`defaultTabs[2]\`)
            defaultTabs[0], // Bookmarks tab
            defaultTabs[1], // Thumbnails tab
        ],

    });

    let documentViewer = useMemo(()=> {
        if (pdfUrl) {
            return <Viewer fileUrl={pdfUrl}
                                     plugins={[defaultLayoutPluginInstance]}
                                     theme={{
                                         theme: 'dark',
                                     }}

            />
        } else {
            return <div/>
        }
    }, [pdfUrl])
    return (
        <Dialog open={open} maxWidth={"xl"} fullWidth className={'pdfPopUpVisualization'} onClose={onClose}>
            <DialogTitle> <Typography variant={"body1"}>{fileName}</Typography></DialogTitle>
            <div style={{height: window.innerHeight - 100}}>
                {documentViewer}
            </div>
        </Dialog>
    )
}

export default PdfPopUpVisualization;
