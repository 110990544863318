import React, {useState} from "react";
import style from './Ticket.module.scss'
import {ButtonBase, Tooltip, Typography} from "@material-ui/core";
import ConfirmationNumberRoundedIcon from '@material-ui/icons/ConfirmationNumberRounded';
import {useTranslation} from "react-i18next";
import moment from "moment";
import {Rating} from "@material-ui/lab";
import TicketDialog from "../TicketDialog/TicketDialog";

const Ticket = ({ticket, groups, onRate}) => {
    const {t} = useTranslation()

    const [diagState, setDiagState] = useState(ticket.get('closedAt') && !ticket.get('rateAt'))

    const onClose = () => {
        setDiagState(false)
    }

    const createdAt = moment(ticket.get('createdAt'))
    return (
        <>
            <ButtonBase className={style.ticket} onClick={() => setDiagState(true)}>
                <ConfirmationNumberRoundedIcon style={{marginRight: '4px'}}/>
                <Tooltip title={ticket.get('preface')}>
                    <Typography className={style.title}>{ticket.get('preface')}</Typography>
                </Tooltip>
                {
                    ticket.get('clientRate') === null ?
                        <Typography variant={"caption"} style={{
                            color: '#e0e0e0',
                            alignSelf: "flex-end",
                            minWidth: '105px',
                            margin: 'auto 0 auto auto'
                        }}>{createdAt.format('DD/MM/YYYY HH:mm')}</Typography> :
                        <div style={{
                            color: '#e0e0e0',
                            alignSelf: "flex-end",
                            minWidth: '125px',
                            margin: 'auto 0 auto auto'
                        }}>
                            <Rating name="read-only" value={ticket.get('clientRate')} readOnly/>
                        </div>
                }
            </ButtonBase>
            <TicketDialog
                diagState={diagState}
                ticket={ticket}
                groups={groups}
                onRate={onRate}
                onClose={onClose}
                mngMode={false}
            />
        </>
    )
}

export default Ticket;
