import React, {useState} from 'react';
import style from './TicketDialog.module.scss'
import {Button, Dialog, Divider, Typography} from "@material-ui/core";
import {Rating} from "@material-ui/lab";
import TextField from "@material-ui/core/TextField";
import moment from "moment";
import {useTranslation} from "react-i18next";

const TicketDialog = ({diagState, ticket, onClose, onRate, onCloseTicket, groups, mngMode = false}) => {
    const {t} = useTranslation()

    const [rate, setRate] = useState(ticket.get('clientRate', 0) || 0)
    const [rateDescription, setRateDescription] = useState(ticket.get('clientRateDescription', '') || '')

    const createdAt = moment(ticket.get('createdAt'))
    const closedAt = moment(ticket.get('closedAt'))
    const actGroups = groups.find(g => g.get('id') === ticket.get('destinationDepartment'))


    return (
        <Dialog open={diagState} onClose={() => onClose(false)} className={style.ticketDialog} fullWidth>
            <div className={style.dialogTitle}>
                <Typography variant={'h5'}>{t('Ticket')} {ticket.get('id')} - {ticket.get('preface')}</Typography>
                <Typography variant={"caption"} style={{
                    color: '#e0e0e0',
                    alignSelf: "flex-end",
                    minWidth: '105px'
                }}>{createdAt.format('DD/MM/YYYY HH:mm')}</Typography>
            </div>
            <Divider variant={"fullWidth"}/>
            <div className={style.dialogContent}>
                <div className={style.department}>
                    <Typography variant={"body1"}
                                style={{marginRight: 5, fontWeight: "bold"}}>{t('Dipartimento')}: </Typography>
                    <Typography variant={"body2"}
                                style={{color: '#e0e0e0', marginBottom: 2}}>{actGroups.get('name')}</Typography>
                </div>
                <Divider variant={"middle"} style={{marginTop: 10, marginBottom: 10}}/>
                <Typography className={style.mainText}>{ticket.get('description')}</Typography>
                {
                    ticket.get('closedAt') &&
                    <>
                        <Divider variant={"fullWidth"} style={{marginTop: 10, marginBottom: 10}}/>
                        <div className={style.rating} style={{marginBottom: 10}}>
                            <Typography variant={"body1"} style={{marginRight: '10px', fontWeight: "bold"}}>
                                {t("Chiuso il")}:
                            </Typography>
                            <Typography variant={"body2"} style={{color: '#e0e0e0', marginBottom: 2}}
                                        className={style.closedTimestamp}>
                                {closedAt.format('DD/MM/YYYY HH:mm')}
                            </Typography>
                        </div>
                    </>
                }
                {
                    ticket.get('closedAt') && (ticket.get('rateAt') || !mngMode) &&
                    <>
                        <div className={style.rating} style={{}}>
                            <Typography style={{
                                marginRight: '10px',
                                fontWeight: "bold"
                            }}>{t("Valuta il servizio")}</Typography>
                            <Rating name="read-only" value={rate}
                                    readOnly={(ticket.get('closedAt') && ticket.get('rateAt')) || mngMode}
                                    onChange={(event, newValue) => {
                                        setRate(newValue);
                                    }}/>
                        </div>
                        <TextField
                            className={style.ratingDescription}
                            label={t('Descrizione')}
                            disabled={(ticket.get('closedAt') && ticket.get('rateAt')) || mngMode}
                            multiline
                            rows={4}
                            value={rateDescription}
                            onChange={(e) => setRateDescription(e.target.value)}
                            variant="outlined"
                        />
                    </>
                }

                {
                    mngMode && !ticket.get('closedAt') &&
                    <Button
                        style={{margin: '10px'}}
                        variant={"contained"}
                        onClick={() => onCloseTicket(ticket.get('id')).then(() => onClose(false))}>
                        {t('Chiudi ticket')}
                    </Button>
                }
            </div>
            <div style={{position: 'relative', margin: 'auto 0 auto auto'}}>
                <Button style={{margin: '10px'}} variant={"outlined"}
                        onClick={() => onClose(false)}>{t('Chiudi')}</Button>
                {
                    ticket.get('closedAt') && !ticket.get('rateAt') && !mngMode &&
                    <Button
                        style={{margin: '10px'}}
                        variant={"contained"}
                        onClick={() => onRate(ticket.get('id'), rate, rateDescription).then(() => onClose(false))}>
                        {t('Invia recensione')}
                    </Button>
                }
            </div>
        </Dialog>
    )
}

export default TicketDialog;
