import './UserSettings.scss'
import DataTable from 'react-data-table-component';
import {useTranslation} from "react-i18next";
import {connect, useDispatch} from "react-redux";
import IconButton from '@material-ui/core/IconButton';
import React, {useEffect, useMemo, useState} from "react";
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import UserSettingsPopUp from "../library/UserSettingsPopUp/UserSettingsPopUp";
import {changeUserPassword, inviteUser, updateUser} from "../../store/actions/auth";
import {fromJS, Map} from "immutable";
import {deleteUser, getUsers} from "../../store/actions/meta";
import ConfirmPopUp from "../library/ConfirmPopUp/ConfirmPopUp";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";

const UserSettings = ({userList, changeUserPassword, updateUser, deleteUser, roles, groups}) => {
    const {t} = useTranslation()
    const dispatch = useDispatch()

    const [popUpSettings, setPopUpSettings] = useState(Map({}));
    const [deleteUserPopUp, setDeleteUserPopUp] = useState(null);
    const [newUserPopUp, setNewUserPopUp] = useState(null);
    const [newUserEmail, setNewUserEmail] = useState('');

    useEffect(() => dispatch(getUsers()), [])

    const openSettingsPopUp = (user) => {
        setPopUpSettings(user)
    }
    const closeSettingsPopUp = () => {
        setPopUpSettings(Map({}))
    }

    const handleCloseNewUserDialog = () => {
        setNewUserPopUp(false)
        setNewUserEmail('')
    }


    const handleInviteNewUserDialog = () => {
        dispatch(inviteUser(newUserEmail)).then(() => {
            setNewUserPopUp(false)
            setNewUserEmail('')
        })
    }

    const columns = [
        {
            name: t('Email'),
            selector: row => row.email,
            maxWidth: '350px',
            width: '300px',
        },
        {
            name: t('Nome'),
            selector: row => row.name,
        },
        {
            name: t('Cognome'),
            selector: row => row.surname,
        },
        {
            name: t('Ruolo'),
            selector: row => roles.find(r => r.get('id') === row.roleId)?.get('name')
        },
        {
            name: t('Gruppo'),
            selector: row => groups.find(r => r.get('id') === row.groupId)?.get('name')
        },
        {
            name: t('Numero di macchine'),
            selector: row => {
                return row.machines.length
            },
        },
        {

            cell: row => <>
                <IconButton
                    aria-label="more"
                    aria-controls="long-menu"
                    aria-haspopup="true"
                    onClick={() => openSettingsPopUp(fromJS(row))}
                >
                    <EditRoundedIcon/>
                </IconButton>
            </>,
            allowOverflow: true,
            button: true,
            width: '45px',
        },
        {

            cell: row => <>
                <IconButton
                    aria-label="more"
                    aria-controls="long-menu"
                    aria-haspopup="true"
                    onClick={() => setDeleteUserPopUp(row.id)}
                >
                    <DeleteRoundedIcon/>
                </IconButton>
            </>,
            allowOverflow: true,
            button: true,
            width: '45px',
        },
    ];

    const actionsMemo = useMemo(() => <Button variant={"outlined"} color={'primary'}
                                              onClick={() => setNewUserPopUp(true)}>{t('Invita nuovo utente')} </Button>, []);

    return (
        <div className={'userSettings'}>
            <DataTable
                columns={columns}
                actions={actionsMemo}
                data={userList.filter(u => u.getIn(['role', 'name']) !== 'dev').toJS()} theme="dark"
                // dense
                fixedHeader={true}
                fixedHeaderScrollHeight={'Calc(100vh - 282px)'}
                paginationPerPage={30}
                defaultSortField={0}
                pagination={true}
                // noHeader
            />
            <UserSettingsPopUp
                open={popUpSettings.get('name', false)}
                user={popUpSettings}
                onClose={closeSettingsPopUp}
                onChangePassword={(...args) => changeUserPassword(...args)}
                onSave={(...args) => updateUser(...args).then(() => closeSettingsPopUp())}
                mngMode={true}
                roles={roles}
                groups={groups}
            />
            <ConfirmPopUp
                open={deleteUserPopUp != null}
                handleAccept={() => deleteUser(deleteUserPopUp).then(() => setDeleteUserPopUp(null))}
                handleDecline={() => setDeleteUserPopUp(null)}
                title={t('Eliminazione utente')}
                description={t(`Sei sicuro di voler eliminare l'utente?`)}
                ko={t('Annulla')}
                ok={t('Conferma')}
            />
            <Dialog open={newUserPopUp} onClose={handleCloseNewUserDialog} maxWidth={"xs"} fullWidth>
                <DialogTitle>
                    Nuovo utente
                </DialogTitle>
                <DialogContent>
                    <TextField
                        variant={"outlined"}
                        fullWidth
                        label={t('Email')}
                        value={newUserEmail}
                        onChange={(e) => setNewUserEmail(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        variant={"outlined"}
                        color={"primary"}
                        onClick={handleCloseNewUserDialog}
                    >{t('Annulla')}</Button>
                    <Button
                        variant={"contained"}
                        color={"primary"}
                        onClick={handleInviteNewUserDialog}
                    >{t('Invia')}</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}


const mapStateToProps = state => {
    return {
        userList: state.meta.userList,
        roles: state.meta.roles,
        groups: state.meta.groups,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        updateUser: (...args) => dispatch(updateUser(...args)),
        deleteUser: (...args) => dispatch(deleteUser(...args)),
        changeUserPassword: (...args) => dispatch(changeUserPassword(...args)),

    }
};

export default connect(mapStateToProps, mapDispatchToProps)(UserSettings);
