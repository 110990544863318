import React from "react";
import './PopUpEmailList.scss'
import {Dialog, DialogActions, DialogContent, DialogTitle, List, ListItem, ListItemText} from "@material-ui/core";
import {withTranslation} from "react-i18next";
import Button from "@material-ui/core/Button";


const PopUpEmailList = (props) => {
    const {t} = props;


    return (
        <Dialog
            open={props.open}
            onClose={() => props.onClose()}
        >
            <DialogTitle style={{padding:'10px'}}>{t('Lista condivisioni')}</DialogTitle>
            <DialogContent style={{minHeight: '95px', padding:'10px'}}>
                <List dense={true}>
                    {
                        props.emailList.map(e => {
                            return (
                                <ListItem>
                                    <ListItemText
                                        primary={e.get('userEmail')}
                                        // secondary={secondary ? 'Secondary text' : null}
                                    />
                                </ListItem>
                            )
                        })
                    }
                </List>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.onClose()} variant={"text"}>
                    {t('Chiudi')}
                </Button>

            </DialogActions>
        </Dialog>
    )
}

export default withTranslation()(PopUpEmailList);
