import React, {useState} from 'react';
import './Machine.scss'
import {
    ButtonBase,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, Divider,
    IconButton,
    Paper,
    TextField,
    Tooltip,
    Typography
} from "@material-ui/core";
import ShareRoundedIcon from '@material-ui/icons/ShareRounded';
import SupervisorAccountRoundedIcon from '@material-ui/icons/SupervisorAccountRounded';
import NotificationsOffRoundedIcon from '@material-ui/icons/NotificationsOffRounded';
import LayersClearIcon from "../icons/LayersClearIcon";
import {withTranslation} from "react-i18next";
import NotificationsActiveRoundedIcon from '@material-ui/icons/NotificationsActiveRounded';
import ConfirmPopUp from "../ConfirmPopUp/ConfirmPopUp";
import Button from "@material-ui/core/Button";
import PopUpEmailList from "../PopUpEmailList/PopUpEmailList";


const Machine = (props) => {
    const {t} = props;
    const [unsubscribe, setUnsubscribe] = useState(false)
    const [share, setShare] = useState(false)
    const [shareEmail, setShareEmail] = useState('')
    const [emailList, setEmailList] = useState(false)

    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const emailError = !re.test(String(shareEmail).toLowerCase()) && shareEmail !== '';


    console.debug(props.data)
    console.debug(props.plant)

    return (
        <React.Fragment>
            <Paper className={'machine'}>
                <Typography variant={"h5"} className={'machineName'}>{props.data.get('mName')}</Typography>
                <Divider variant={"fullWidth"}/>
                {/*<Typography variant={"h6"}>{props.data.getIn(['plant', 'pName'])}</Typography>*/}
                <Typography variant={"body1"} className={'serialNumber'}><b>{t('Matricola')}</b>: {props.data.getIn(['serialNumber'])}</Typography>
                {
                    props.enableAction &&
                    <div className={'actions'}>
                        <Tooltip title={t('Condividi macchina')}>
                            <IconButton color={"primary"} className={'iconButton'} onClick={() => setShare(true)}>
                                <ShareRoundedIcon className={'icon'}/>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={t('Rimuovi macchina')}>
                            <IconButton color={"primary"} className={'iconButton'} onClick={() => setUnsubscribe(true)}>
                                <LayersClearIcon className={'icon'}/>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={t('Lista condivisioni')}>
                            <React.Fragment>
                                <IconButton color={"primary"}
                                            className={props.sharedMachines?.size <= 0 ? 'iconButtonDisabled' : 'iconButton'}
                                            onClick={() => setEmailList(true)}
                                            disabled={props.sharedMachines?.size <= 0}
                                >
                                    <SupervisorAccountRoundedIcon className={'icon'}/>
                                </IconButton>
                            </React.Fragment>
                        </Tooltip>
                        <Tooltip title={t('Notifica email')}>
                            <IconButton color={"primary"} className={'iconButton'}
                                        onClick={() => props.handleEmailAlert(props.data.get('id'), props.userEmail, props.data.get('emailAlert'))}>
                                {props.data.get('emailAlert') ?
                                    <NotificationsActiveRoundedIcon className={'icon'}/>:
                                    <NotificationsOffRoundedIcon className={'icon'}/>
                                }
                            </IconButton>
                        </Tooltip>
                    </div>
                }
                {!props.enableAction &&
                <ButtonBase className={'content'} onClick={() => props.onMachineClick(props.data.get('id'))}>
                </ButtonBase>}
            </Paper>
            {
                props.enableAction &&
                <React.Fragment>
                    <ConfirmPopUp
                        open={unsubscribe}
                        handleAccept={() => props.handleUnsubscribe(props.data.get('id')).then(()=>setUnsubscribe(false))}
                        handleDecline={() => setUnsubscribe(false)}
                        title={t('Cancellazione sottoscrizione macchina')}
                        description={t('Sei sicuro di voler rimuovere la macchina?')}
                        ko={t('Annulla')}
                        ok={t('Conferma')}
                    />
                    <Dialog
                        open={share}
                        onClose={() => setShare(false)}
                    >
                        <DialogTitle>{t('Condivisione macchina')}</DialogTitle>
                        <DialogContent style={{minHeight: '95px'}}>
                            <TextField
                                label={t('Email')}
                                variant={"outlined"}
                                value={shareEmail}
                                onChange={event => setShareEmail(event.target.value)}
                                error={emailError}
                                helperText={emailError && t('Formato email non valido')}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setShare(false)} variant={"text"}>
                                {t('Annulla')}
                            </Button>
                            <Button onClick={() => props.handleShare(props.data.get('id'), shareEmail)}
                                    variant={"outlined"}
                                    autoFocus
                                    disabled={emailError || shareEmail === ''}
                            >
                                {t('Conferma')}
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <PopUpEmailList
                        open={emailList}
                        onClose={() => setEmailList(false)}
                        emailList={props.sharedMachines}
                    />
                </React.Fragment>
            }
        </React.Fragment>
    );
};

export default withTranslation()(Machine);
