import * as actionType from "./action";


export const closeSnackbar = key => ({
    type: actionType.CLOSE_SNACKBAR,
    dismissAll: !key, // dismiss all if no key has been defined
    key,
});

export const removeSnackbar = key => ({
    type: actionType.REMOVE_SNACKBAR,
    key,
});

export const enqueueSnackbar = (notification) => {
    const key = notification.options && notification.options.key;
    return {
        type: actionType.ENQUEUE_SNACKBAR,
        notification: {
            ...notification,
            key: key || new Date().getTime() + Math.random(),
        },
    };
};