import React, {Component} from 'react'
import TextField from '@material-ui/core/TextField';
import "./SignUp.scss"
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {IconButton, InputAdornment, Paper} from "@material-ui/core";
import {signup} from "../../store/actions/auth";
import {withTranslation} from "react-i18next";
import {Visibility, VisibilityOff} from "@material-ui/icons";

class SignUp extends Component {

    state = {
        email: {
            value: '',
            validation: {
                required: true,
                isEmail: true
            },
            valid: false,
        },
        password: {
            value: '',
            validation: {
                required: true,
                minLength: 8
            },
            valid: true,
        },
        password2: {
            value: '',
            validation: {
                required: true,
                minLength: 8
            },
            valid: true,
        },
        name: {
            value: '',
            validation: {
                required: false,
                minLength: 1
            },
            valid: false,
        },
        surname: {
            value: '',
            validation: {
                required: false,
                minLength: 1
            },
            valid: false,
        },
        logged: false,
        errorMessage: '',
        showPassword: false,
        showPassword2: false,
    };

    checkValidity(value, rules) {
        let isValid = true;
        if (!rules) {
            return true;
        }

        if (rules.required) {
            isValid = value.trim() !== '' && isValid;
        }

        if (rules.minLength) {
            isValid = value.length >= rules.minLength && isValid
        }

        if (rules.maxLength) {
            isValid = value.length <= rules.maxLength && isValid
        }

        if (rules.isEmail) {
            const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
            isValid = pattern.test(value) && isValid
        }

        if (rules.isNumeric) {
            const pattern = /^\d+$/;
            isValid = pattern.test(value) && isValid
        }

        return isValid;
    }

    handleEmailChange = (event) => {
        this.setState({
                email: {
                    ...this.state.email,
                    value: event.target.value,
                    valid: this.checkValidity(event.target.value, this.state.email.validation),
                }
            }
        )
    };

    handleNameChange = (event) => {
        this.setState({
                name: {
                    ...this.state.name,
                    value: event.target.value,
                    valid: this.checkValidity(event.target.value, this.state.name.validation),
                }
            }
        )
    };

    handleSurnameChange = (event) => {
        this.setState({
                surname: {
                    ...this.state.surname,
                    value: event.target.value,
                    valid: this.checkValidity(event.target.value, this.state.surname.validation),
                }
            }
        )
    };

    handlePasswordChange = (event) => {
        this.setState({
                password: {
                    ...this.state.password,
                    value: event.target.value,
                    valid: this.checkValidity(event.target.value, this.state.password.validation),
                }
            }
        )
    };

    handlePassword2Change = (event) => {
        this.setState({
                password2: {
                    ...this.state.password2,
                    value: event.target.value,
                    valid: this.checkValidity(event.target.value, this.state.password2.validation),
                }
            }
        )
    };

    handleOnSubmit = (event) => {
        event.preventDefault();
        if (
            (this.state.surname.value.length > 0 && this.state.surname.valid) &&
            (this.state.name.value.length > 0 && this.state.name.valid) &&
            (this.state.email.value.length > 0 && this.state.email.valid) &&
            (this.state.password.value.length > 0 && this.state.password.valid && this.state.password.value === this.state.password2.value)
        ) {
            this.props.SignUp(this.state.name.value, this.state.surname.value, this.state.email.value, this.state.password.value)
                .then((res) => {
                    this.props.history.push('/signIn')
                })
        } else {
            this.setState({errorMessage: this.props.t('Campi mancanti')})
        }
    };

    onClickLoginHandle = () => {
        window.location.pathname = '/SignIn';
    }

    handleClickShowPassword = () => {
        this.setState((old) => ({showPassword: !old.showPassword}))
    };

    handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    handleClickShowPassword2 = () => {
        this.setState((old) => ({showPassword2: !old.showPassword2}))
    };

    handleMouseDownPassword2 = (event) => {
        event.preventDefault();
    };

    render() {
        const {t} = this.props;

        if (!this.state.logged) {
            return (
                <div className="signUp">
                    <Paper className={'signUpBody'}>
                        <form className={"auth_form"} onSubmit={this.handleOnSubmit}>
                            <Typography variant={'h5'} style={{
                                marginBottom: '15px',
                                color: '#e0e0e0',
                                alignSelf: 'start',
                                marginLeft: '17px'
                            }} color="textPrimary">{t('Registrazione')}</Typography>
                            <TextField
                                id={'regSurname'}
                                className="text_field"
                                label={t("Cognome")}
                                fullWidth={true}
                                type="text"
                                variant="outlined"
                                inputProps={{
                                    autocomplete: 'new-password',
                                    form: {
                                        autocomplete: 'off',
                                    },
                                }}
                                onChange={this.handleSurnameChange}
                                error={this.state.surname.value.length > 0 && !this.state.surname.valid}
                                value={this.state.surname.value}

                            />
                            <TextField
                                id={'regName'}
                                className="text_field"
                                label={t("Nome")}
                                fullWidth={true}
                                type="text"
                                variant="outlined"
                                inputProps={{
                                    autocomplete: 'new-password',
                                    form: {
                                        autocomplete: 'off',
                                    },
                                }}

                                onChange={this.handleNameChange}
                                error={this.state.name.value.length > 0 && !this.state.name.valid}
                                value={this.state.name.value}

                            />
                            <TextField
                                id={'regEmail'}
                                className="text_field"
                                label={t("Email")}
                                fullWidth={true}
                                type="email"
                                variant="outlined"
                                autoComplete='off'
                                inputProps={{
                                    autocomplete: 'new-password',
                                    form: {
                                        autocomplete: 'off',
                                    },
                                }}
                                onChange={this.handleEmailChange}
                                error={this.state.email.value.length > 0 && !this.state.email.valid}
                                value={this.state.email.value}

                            />
                            <TextField
                                id={'regPass'}
                                className="text_field"
                                label={t("Password")}
                                fullWidth={true}
                                variant="outlined"
                                autoComplete='off'
                                inputProps={{
                                    autocomplete: 'new-password',
                                    form: {
                                        autocomplete: 'off',
                                    },
                                }}
                                onChange={this.handlePasswordChange}
                                error={!this.state.password.valid}
                                value={this.state.password.value}
                                type={this.state.showPassword ? 'text' : 'password'}
                                InputProps={{
                                    endAdornment:
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={this.handleClickShowPassword}
                                                onMouseDown={this.handleMouseDownPassword}
                                            >
                                                {this.state.showPassword ? <Visibility/> : <VisibilityOff/>}
                                            </IconButton>
                                        </InputAdornment>
                                }}
                            />
                            <TextField
                                id={'regPass2'}
                                className="text_field"
                                label={t("Ripeti password")}
                                fullWidth={true}
                                variant="outlined"
                                autoComplete='off'
                                inputProps={{
                                    autocomplete: 'new-password',
                                    form: {
                                        autocomplete: 'off',
                                    },
                                }}
                                onChange={this.handlePassword2Change}
                                error={!this.state.password2.valid || this.state.password.value !== this.state.password2.value}
                                value={this.state.password2.value}
                                type={this.state.showPassword2 ? 'text' : 'password'}
                                InputProps={{
                                    endAdornment:
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={this.handleClickShowPassword2}
                                                onMouseDown={this.handleMouseDownPassword2}
                                            >
                                                {this.state.showPassword2 ? <Visibility/> : <VisibilityOff/>}
                                            </IconButton>
                                        </InputAdornment>
                                }}
                            />
                            <div className={'action'}>
                                <Button
                                    className="login_button"
                                    variant="outlined"
                                    color="primary"
                                    fullWidth={true}
                                    onClick={this.onClickLoginHandle}
                                    style={{marginRight: '10px'}}
                                >
                                    {t('Accedi')}
                                </Button>
                                <Button
                                    className="login_button"
                                    variant="contained"
                                    color="primary"
                                    fullWidth={true}
                                    type="submit"
                                >
                                    {t('Registrati')}
                                </Button>
                            </div>
                            <Typography color={'error'}>{this.state.errorMessage}</Typography>
                            {/*<Link to={{pathname: '/SignIn'}}>*/}
                            {/*    <Typography className="registration_button" color="textPrimary"*/}
                            {/*                variant="caption">Accedi</Typography>*/}
                            {/*</Link>*/}
                        </form>
                        <div className={'logo'}>
                            <img src="logo_TMCI.png" alt=""/>
                        </div>
                    </Paper>
                </div>
            )
        } else {
            return (
                <div>
                    {this.props.children}
                </div>
            )
        }
    }
}

const mapDispatchToProps = dispatch => {
    return {
        SignUp: (...args) => dispatch(signup(...args)),
    }
}

export default connect(null, mapDispatchToProps)(withRouter(withTranslation()(SignUp)));
