import * as actionType from '../actions/action'
import {List, Map} from "immutable";
import {updateObject} from "../utility";
import moment from "moment";

const initialState = {
    plantId: 0,
    machineId: 0,
    filters: Map({
        'eventType': List([]),
        'eventCode': List([]),
        'device': List([]),
        'eventClass': List([]),
        'type': List([]),
        'startTime': moment().subtract(2, 'hour'),
        'endTime': moment(),
        'activeAlarm': false,
        'limit': 10000,
        'orderBy': {
            'timestamp_start': 'desh'
        }
    }),
    filterType: 0,
    showTable: true,
    showGraph: true,
    batchReports: Map({}),
    cipReports: Map({}),
    data: Map({}),
    graphData: List([]),
    graphSeries: List([]),
    documents: List([]),
    machine_tickets: List([])
}

const data = (state = initialState, action) => {
    switch (action.type) {
        case actionType.UPDATE_REPORTS:
            return updateObject(state, {batchReports: action.batchReports, cipReports: action.cipReports});
        case actionType.UPDATE_DATA:
            return updateObject(state, {
                data: action.data,
                graphData: action.graphData,
                graphSeries: action.graphSeries,
            });
        case actionType.UPDATE_SELECTED_PLANT:
            return updateObject(state, {
                plantId: action.data,
                machineId: 0,
                batchReports: Map({}),
                data: Map({}),
                graphData: List([]),
                graphSeries: List([]),
                documents: List([]),
                machine_tickets: List([]),
                filters: Map({
                    'eventType': List([]),
                    'eventCode': List([]),
                    'device': List([]),
                    'eventClass': List([]),
                    'type': List([]),
                    'startTime': moment().subtract(2, 'hour'),
                    'endTime': moment(),
                    'activeAlarm': false,
                    'limit': 10000,
                    'orderBy': {
                        'timestamp_start': 'desh'
                    }
                }),
            });
        case actionType.UPDATE_SELECTED_MACHINE:
            return updateObject(state, {
                machineId: action.data,
                filters: Map({
                    'eventType': List([]),
                    'eventCode': List([]),
                    'device': List([]),
                    'eventClass': List([]),
                    'type': List([]),
                    'startTime': moment().subtract(2, 'hour'),
                    'endTime': moment(),
                    'activeAlarm': false,
                    'limit': 10000,
                    'orderBy': {
                        'timestamp_start': 'desh'
                    }
                }),
                batchReports: Map({}),
                data: Map({}),
                graphData: List([]),
                graphSeries: List([]),
                documents: List([]),
                machine_tickets: List([])
            });
        case actionType.UPDATE_FILTERS:
            return updateObject(state, {
                filters: action.filters,
                showGraph: action.showGraph,
                showTable: action.showTable,
                filterType: action.filterType
            });
        case actionType.SHOW_GRAPH:
            return updateObject(state, {showGraph: action.data});
        case actionType.SHOW_TABLE:
            return updateObject(state, {showTable: action.data});
        case actionType.UPDATE_DOCUMENTS:
            return updateObject(state, {documents: action.data});
        case actionType.UPDATE_MACHINE_TICKETS:
            return updateObject(state, {machine_tickets: action.data});
        default:
            return state
    }
};

export default data;
