import React, {useState} from 'react'
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import TextField from "@material-ui/core/TextField";


const PopUpNewTicket = ({open, groups, onCancel, onSend}) => {
    const {t} = useTranslation()

    const [destinationDepartment, setDestinationDepartment] = useState(0)
    const [preface, setPreface] = useState('')
    const [description, setDescription] = useState('')

    return (
        <Dialog open={open} fullWidth>
            <DialogTitle>{t('Richiedi assistenza')}</DialogTitle>
            <DialogContent>
                <TextField
                    variant={"outlined"}
                    id="standard-select-currency"
                    select
                    label={t('Dipartimento')}
                    value={destinationDepartment}
                    style={{minWidth:200}}
                    onChange={(e) => setDestinationDepartment(e.target.value)}
                >
                    {groups.filter(g=> g.get('name') === 'Ricambi' || g.get('name') === 'Assistenza' ).map((option) => (
                        <MenuItem key={option.get('id')} value={option.get('id')}>
                            {option.get('name')}
                        </MenuItem>
                    ))}
                </TextField>
                <TextField
                    variant={"outlined"}
                    label={t('Oggetto')}
                    fullWidth
                    value={preface}
                    onChange={(e)=>setPreface(e.target.value)}
                />
                <TextField
                    variant={"outlined"}
                    label={t('Descrizione')}
                    multiline={true}
                    rows={5}
                    fullWidth
                    value={description}
                    onChange={(e)=>setDescription(e.target.value)}
                />
            </DialogContent>
            <DialogActions>
                <Button
                    variant={"text"}
                    onClick={()=>{
                        setDestinationDepartment(0)
                        setDescription('')
                        setPreface('')
                        onCancel()
                    }}
                >
                    {t('Annulla')}</Button>
                <Button
                    variant={"contained"}
                    onClick={()=>onSend({
                        destinationDepartment,
                        description,
                        preface
                    })}
                >
                    {t('Invia')}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default PopUpNewTicket;
