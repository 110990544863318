export  const AUTH_START = 'AUTH_START';
export  const AUTH_SUCCESS = 'AUTH_SUCCESS';
export  const AUTH_FAIL = 'AUTH_FAIL';
export  const AUTH_LOGOUT = 'AUTH_LOGOUT';
export  const UPDATE_USER = 'UPDATE_USER';
export const AUTO_LOGIN_SUCCESS = 'AUTO_LOGIN_SUCCESS';
export const AUTO_LOGIN_FAIL = 'AUTO_LOGIN_FAIL';
export const SignUp = 'SignUp';

export const ENQUEUE_SNACKBAR = 'ENQUEUE_SNACKBAR';
export const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR';
export const REMOVE_SNACKBAR = 'REMOVE_SNACKBAR';

export const UPDATE_REPORTS = 'UPDATE_REPORTS';
export const UPDATE_SELECTED_PLANT = 'UPDATE_SELECTED_PLANT';
export const UPDATE_SELECTED_MACHINE = 'UPDATE_SELECTED_MACHINE';
export const RESET_MACHINE_META = 'RESET_MACHINE_META';

export const UPDATE_PLANTS = 'UPDATE_PLANTS';
export const UPDATE_MACHINES = 'UPDATE_MACHINES';
export const UPDATE_DEVICES = 'UPDATE_DEVICES';
export const UPDATE_TYPE = 'UPDATE_TYPE';
export const UPDATE_EVENT_CLASS = 'UPDATE_EVENT_CLASS';
export const UPDATE_EVENT_CODE = 'UPDATE_EVENT_CODE';
export const UPDATE_FILTERS = 'UPDATE_FILTERS';
export const UPDATE_DATA = 'UPDATE_DATA';
export const UPDATE_GRAPH_DATA = 'UPDATE_GRAPH_DATA';
export const UPDATE_GRAPH_SERIES = 'UPDATE_GRAPH_SERIES';
export const SHOW_TABLE = 'SHOW_TABLE';
export const SHOW_GRAPH = 'SHOW_GRAPH';
export const UPDATE_FAVORITE_FILTERS = 'UPDATE_FAVORITE_FILTERS';

export const UPDATE_USER_LIST = 'UPDATE_USER_LIST';
export const UPDATE_ROLES = 'UPDATE_ROLES';
export const UPDATE_GROUPS = 'UPDATE_GROUPS';

export const UPDATE_DOCUMENTS = 'UPDATE_DOCUMENTS';
export const UPDATE_MACHINE_TICKETS = 'UPDATE_MACHINE_TICKETS';
export const UPDATE_TICKETS = 'UPDATE_TICKETS';


