import React, {useState} from "react";
import './AddFavoriteFilterPopUp.scss'
import {withTranslation} from "react-i18next";
import {Dialog, DialogActions, DialogContent, DialogTitle, TextField} from "@material-ui/core";
import Button from "@material-ui/core/Button";

const AddFavoriteFilterPopUp = ({t, open, onClose, onSave}) => {
    const [name, setName] = useState()

    return(
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>{t('Salvataggio filtro')}</DialogTitle>
            <DialogContent>
                <TextField
                label={'Nome Filtro preferito'}
                value={name}
                onChange={(e)=> setName(e.target.value)}
                variant={"outlined"}
                />
            </DialogContent>
            <DialogActions>
                <Button variant={"outlined"} onClick={onClose}>{t('Annulla')}</Button>
                <Button variant={"contained"} onClick={()=>onSave(name).then(onClose)}>{t('Salva')}</Button>
            </DialogActions>
        </Dialog>
    )
}

export default withTranslation()(AddFavoriteFilterPopUp);
