import * as actionType from '../actions/action'
import {Map} from "immutable";
import {updateObject} from "../utility";

const initialState = {
    user: Map({}),
    userLogged: false,
    socketConnected: false,
    session: '',
    error: null,
    loading: null,
    autoLogin: 0
}

const auth = (state = initialState, action) => {
    switch (action.type) {
        case actionType.UPDATE_USER:
            return updateObject(state, {user: action.data});
        case actionType.AUTH_START:
            return updateObject(state, {
                error: null,
                loading: true,
            });
        case actionType.AUTH_SUCCESS:
            return updateObject(state, {
                user: action.data,
                userLogged: true,
                error: null,
                loading: false,
            });
        case actionType.AUTO_LOGIN_SUCCESS:
            return updateObject(state, {
                user: action.data,
                userLogged: true,
                error: null,
                loading: false,
                autoLogin: 1
            });
        case actionType.AUTH_LOGOUT:
            return updateObject(state, {
                user: Map({}),
                socketConnected: false,
                session: '',
                error: null,
                loading: null,
                userLogged: false,
            });
        case actionType.AUTO_LOGIN_FAIL:
            return updateObject(state, {
                autoLogin: -1
            });
        default:
            return state

    }
    // return state
};

export default auth;