import './MachineSettings.scss'
import DataTable from 'react-data-table-component';
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import IconButton from '@material-ui/core/IconButton';
import React, {useMemo, useState} from "react";
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import {fromJS, Map} from "immutable";
import {Button} from "@material-ui/core";
import AddUserPopUp from "../library/AddUserPopUp/AddUserPopUp";
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';
import MachineSettingsPopUp from "../library/MachineSettingsPopUp/MachineSettingsPopUp";
import {updateMachine} from "../../store/actions/meta";

const MachineSettings = ({changeUserPassword, updateUser, deleteUser}) => {
    const {t} = useTranslation()
    const dispatch = useDispatch()

    const machines = useSelector(state => state.meta.machines);
    const userList = useSelector(state => state.meta.userList);
    const userEmail = useSelector(state => state.auth.user.getIn(['user', 'email']));
    const machineMng = useSelector(state => state.auth.user.getIn(['user', 'role', 'machine']));

    const [popUpSettings, setPopUpSettings] = useState(Map({}));
    const [newUserPopUp, setNewUserPopUp] = useState(null);

    const updateEditedMachine = (machine) => {
        dispatch(updateMachine(machine.get('id'), machine.toJS()))
            .then(()=>setPopUpSettings(Map({})))
    }

    const columns = [
        {
            name: t('Impianto'),
            selector: row => row.plant.pName,
        },
        {
            name: t('Macchina'),
            selector: row => row.mName,
        },
        {
            name: t('Id Macchina'),
            selector: row => row.id,
        },
        {
            name: t('Numero di serie'),
            selector: row => row.serialNumber,
        },
        {
            name: t('Condivisioni'),
            selector: row => row.sharedTo.length,
            omit: !machineMng
        },
        {
            name: t('Email alert'),
            cell: row => {
                if (row.sharedTo.find(s => s.userEmail === userEmail)?.emailAlert) {
                    return <CheckRoundedIcon/>
                } else if (row.sharedTo.find(s => s.userEmail === userEmail)?.emailAlert === false) {
                    return <ClearRoundedIcon/>
                } else {
                    return <></>
                }

            },
            selector: row => {
                return row.sharedTo.find(s => s.userEmail === userEmail)?.emailAlert
            },
        },
        {

            cell: row => <>
                <IconButton
                    aria-label="more"
                    aria-controls="long-menu"
                    aria-haspopup="true"
                    onClick={() => setPopUpSettings(fromJS(row))}
                >
                    <EditRoundedIcon/>
                </IconButton>
            </>,
            allowOverflow: true,
            button: true,
            width: '45px',
        }
    ];

    return (
        <div className={'userSettings'}>
            <DataTable
                columns={columns}
                data={machines.toJS()}
                theme="dark"
                // dense
                fixedHeader={true}
                fixedHeaderScrollHeight={'Calc(100vh - 282px)'}
                paginationPerPage={30}
                defaultSortFieldId={3}
                pagination={true}
                // noHeader
            />
            <MachineSettingsPopUp
                state={popUpSettings.get('id', 0) !== 0}
                machine={popUpSettings}
                userEmail={userEmail}
                userList={userList}
                mngMode={machineMng}
                onClose={() => setPopUpSettings(Map({}))}
                onChangePassword={(...args) => changeUserPassword(...args)}
                onSave={updateEditedMachine}
            />
            <AddUserPopUp open={newUserPopUp} onCLose={() => setNewUserPopUp(false)}/>
        </div>
    )
}

export default MachineSettings;
