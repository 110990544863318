import React, {useState} from "react";
import './FavoritesFilter.scss'
import {
    Avatar,
    Divider,
    IconButton,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText
} from "@material-ui/core";
import StarRoundedIcon from "@material-ui/icons/StarRounded";
import DeleteIcon from "@material-ui/icons/Delete";
import {withTranslation} from "react-i18next";
import ConfirmPopUp from "../library/ConfirmPopUp/ConfirmPopUp";


const FavoriteFilter = ({t, filter, onDelete, onSelect}) => {
    const [deleteFilter, setDeleteFilter] = useState(false)

    return (
        <div>
            <ListItem button onClick={()=>onSelect(filter.get('id'))}>
                <ListItemAvatar>
                    <Avatar>
                        <StarRoundedIcon/>
                    </Avatar>
                </ListItemAvatar>
                <ListItemText
                    primary={filter.get('filterName')}
                />
                <ListItemSecondaryAction>
                    <IconButton edge="end" onClick={() => setDeleteFilter(true)}>
                        <DeleteIcon/>
                    </IconButton>
                </ListItemSecondaryAction>
            </ListItem>
            <Divider/>
            <ConfirmPopUp
                open={deleteFilter}
                handleAccept={() => onDelete(filter.get('id'))}
                handleDecline={() => setDeleteFilter(false)}
                title={t('Cancellazione filtro')}
                description={t('Sei sicuro di voler cancellare i filtri?')}
                ko={t('Annulla')}
                ok={t('Conferma')}
            />
        </div>

    )
}

export default withTranslation()(FavoriteFilter)

