import React, {useState} from "react";
import './TimeSelectionPopUp.scss'
import {withTranslation} from "react-i18next";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import {KeyboardDateTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import moment from "moment";
import MomentUtils from "@date-io/moment";

const TimeSelectionPopUp = ({t, open, favoriteFilter, cancel, getData}) => {
    const [filters, setFilters] = useState(favoriteFilter)

    const startError = filters.get('startTime') instanceof moment && !filters.get('startTime')._isValid
    const endError = filters.get('endTime') instanceof moment && !filters.get('endTime')._isValid

    return (
        <Dialog open={open} onEnter={()=>setFilters(favoriteFilter)}>
            <DialogTitle>{t('Selezione arco temporale')}</DialogTitle>
            <DialogContent>
                <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={moment.locale()}>
                    <KeyboardDateTimePicker
                        variant="inline"
                        inputVariant="outlined"
                        className={'textField'}
                        ampm={false}
                        label={t('Da')}
                        value={filters.getIn(['startTime'])}
                        onChange={(date) => setFilters(old => (old.update('startTime', date)))}
                        onError={console.log}
                        // disablePast
                        format="DD/MM/yyyy HH:mm"
                        error={startError}
                        helperText={startError && t('Data di inizio non valida')}

                    />
                    <KeyboardDateTimePicker
                        variant="inline"
                        inputVariant="outlined"
                        className={'textField'}
                        ampm={false}
                        label={t('A')}
                        value={filters.getIn(['endTime'])}
                        onChange={(date) => setFilters(old => (old.update('endTime', date)))}
                        onError={console.log}
                        // disablePast
                        disableFuture
                        format="DD/MM/yyyy HH:mm"
                        error={endError}
                        // helperText={props.filters.getIn(['endTime']) - props.filters.getIn(['startTime']) > 86400000? 'tanto':'poco'}
                        helperText={endError && t('Data di fine non valida')}
                    />
                </MuiPickersUtilsProvider>
            </DialogContent>
            <DialogActions>
                <Button
                    variant={"outlined"}
                    className={'button'}
                    color={'primary'}
                    onClick={() => cancel()}
                >{t('Annulla')}</Button>
                <Button
                    variant={"contained"}
                    color={'primary'}
                    className={'button'}
                    onClick={() => {
                        getData(filters, true, true)
                    }}
                >{t('Invia filtri')}</Button>
            </DialogActions>
        </Dialog>
    )
}

export default withTranslation()(TimeSelectionPopUp);