import * as actionType from '../actions/action'
import {List, Map} from "immutable";
import {updateObject} from "../utility";

const initialState = {
    plants: List([]),
    machines: List([]),
    devices: List([]),
    eventClass: List([]),
    eventCode: List([]),
    type: List([]),
    favoriteFilters: List([]),
    userList: List([]),
    roles: List([]),
    groups: List([]),
    tickets: List([])
}

const data = (state = initialState, action) => {
    switch (action.type) {
        case actionType.UPDATE_PLANTS:
            return updateObject(state, {plants: action.data});
        case actionType.UPDATE_MACHINES:
            return updateObject(state, {machines: action.data});
        case actionType.UPDATE_DEVICES:
            return updateObject(state, {devices: action.data});
        case actionType.UPDATE_EVENT_CODE:
            return updateObject(state, {eventCode: action.data});
        case actionType.UPDATE_EVENT_CLASS:
            return updateObject(state, {eventClass: action.data});
        case actionType.UPDATE_TYPE:
            return updateObject(state, {type: action.data});
        case actionType.UPDATE_FAVORITE_FILTERS:
            return updateObject(state, {favoriteFilters: action.data});
        case actionType.RESET_MACHINE_META:
            return updateObject(state, {devices: List([]), eventCode: List([])});
        case actionType.UPDATE_USER_LIST:
            return updateObject(state, {userList: action.data});
        case actionType.UPDATE_ROLES:
            return updateObject(state, {roles: action.data});
        case actionType.UPDATE_GROUPS:
            return updateObject(state, {groups: action.data});
        case actionType.UPDATE_TICKETS:
            return updateObject(state, {tickets: action.data});
        default:
            return state
    }
};

export default data;
