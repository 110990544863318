import React, {PureComponent} from "react";
import './DataView.scss'
import {
    AppBar,
    Button,
    LinearProgress,
    Step,
    StepLabel,
    Stepper,
    Tab,
    Tabs,
    Tooltip,
    Typography
} from "@material-ui/core";
import Plants from "../library/Plants/Plants";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import Machines from "../library/Machines/Machines";
import {
    getBatchReports,
    getFilteredData,
    onSelectedMachineChange,
    onSelectedPlantChange
} from "../../store/actions/data";
import TabPanel from "../library/TabPanel/TabPanel";
import EventTypeFilterPage from "../library/EventTypeFilterPage/EventTypeFilterPage";
import {
    addFavoriteFilter,
    deleteFavoriteFilters,
    getDevices,
    getEventCode,
    getFavoriteFilters
} from "../../store/actions/meta";
import {fromJS, List, Map} from "immutable";
import moment from "moment";
import DeviceFilterPage from "../library/DeviceFilterPage/DeviceFilterPage";
import DataTable from "react-data-table-component";
import EchartsLineChart from "../library/EchartsLineChart/EchartsLineChart";
import ConfirmPopUp from "../library/ConfirmPopUp/ConfirmPopUp";
import * as actionType from "../../store/actions/action";
import EditIcon from '@material-ui/icons/Edit';
import {PaginationItem} from "@material-ui/lab";
import AdvancedFilterPage from "../library/AdvancedFilterPage/AdvancedFilterPage";
import FavoritesFilterPage from "../library/FavoritesFilterPage/FavoritesFilterPage";
import {withMedia} from "react-media-query-hoc";
import {withRouter} from "react-router";
import StarBorderRoundedIcon from '@material-ui/icons/StarBorderRounded';
import AddFavoriteFilterPopUp from "../library/AddFavoriteFilterPopUp/AddFavoriteFilterPopUp";
import TimeSelectionPopUp from "../library/TimeSelectionPopUp/TimeSelectionPopUp";
import UpdateRoundedIcon from '@material-ui/icons/UpdateRounded';
// import ApexLineChart from "../library/ApexLineChart/ApexLineChart";

const confirmText = [`Sicuro di voler cambiare l'impianto`, `Sicuro di voler cambiare la macchina`, `Sicuro di voler cambiare i filtri`,]

class DataView extends PureComponent {

    plant = 'Selezione impianto'
    machine = 'Selezione macchina'

    getInitialFilterState = () => (Map({
        'eventType': List([]),
        'eventCode': List([]),
        'device': List([]),
        'eventClass': List([]),
        'type': List([]),
        'startTime': moment().subtract(2, 'hour'),
        'endTime': moment(),
        'activeAlarm': false,
        'limit': 10000,
        'orderBy': {
            'timestamp_start': 'desh'
        }
    }))

    state = {
        filterType: this.props.filterType,
        eventTypeFilters: this.props.filters,
        deviceFilters: this.props.filters,
        advancedFilters: this.props.filters,
        activeStep: 0,
        loadingData: false,
        popUpConfirmResetFilter: false,
        resetStep: -1,
        popUpSAveFilter: false,
        showTable: true,
        showGraph: true,
        showTimeSelection: false,
        favoriteFilterSelected: Map({
            'startTime': moment().subtract(2, 'hour'),
            'endTime': moment(),
        }),
        lastFilters:{}
    }

    handleChangeFilterType = (tab) => {
        this.setState({filterType: tab})
    }

    updateEventTypeFilters = (key, value) => {
        let newState = this.state.eventTypeFilters
        if (key === 'eventType') {
            newState = newState.merge(Map({
                'eventType': value,
                'eventCode': List([]),
                'device': List([]),
                'eventClass': List([]),
                'type': List([]),
                'startTime': moment().subtract(2, 'hour'),
                'endTime': moment(),
                'activeAlarm': false,
            }))
        } else {
            newState = newState.update(key, () => value)
        }
        this.setState({eventTypeFilters: newState})
    }

    updateDeviceFilters = (key, value) => {
        let newState = this.state.deviceFilters;
        newState = newState.update(key, () => value);
        this.setState({deviceFilters: newState});
    }

    updateAdvancedFilters = (key, value) => {
        let newState = this.state.advancedFilters;
        newState = newState.update(key, () => value);
        this.setState({advancedFilters: newState});
    }

    getData = (filters, showTable, showGraph, update=false) => {
        if (!update) this.setState({'activeStep': 4});
        this.setState({'loadingData': true})
        this.props.getFilteredData(filters, showTable, showGraph, this.state.filterType)
            .then(() => {
                if (this.state.activeStep >= 4){
                    this.setState({'activeStep': 5, 'loadingData': false})
                }
            });
    }

    resetFilter = (step) => {
        this.setState({popUpConfirmResetFilter: false})
        if (this.state.resetStep === 0 && this.state.activeStep > 0) {
            this.props.onSelectedPlantChange(0);
            this.props.resetMachineMeta();
            this.setState({
                'activeStep': 0,
                'loadingData': false,
                eventTypeFilters: this.getInitialFilterState(),
                deviceFilters: this.getInitialFilterState(),
                advancedFilters: this.getInitialFilterState(),
                showTable: true,
                showGraph: true,
            });
        } else if (this.state.resetStep === 1 && this.state.activeStep > 1) {
            this.props.onSelectedMachineChange(0)
            this.props.resetMachineMeta();
            this.setState({
                'activeStep': 1,
                'loadingData': false,
                eventTypeFilters: this.getInitialFilterState(),
                deviceFilters: this.getInitialFilterState(),
                advancedFilters: this.getInitialFilterState(),
                showTable: true,
                showGraph: true,
            });
        } else if (this.state.resetStep === 2 && this.state.activeStep > 3) {
            this.setState({
                'activeStep': 2,
                'loadingData': false,
                // eventTypeFilters: this.getInitialFilterState(),
                // deviceFilters: this.getInitialFilterState(),
                // advancedFilters: this.getInitialFilterState(),
                // showTable: true,
                // showGraph: true,
            });
            this.props.resetData();

        }
    }

    /* Ritorna true se lo stato attuale è uguale all' indice,
    *  se lo step è il 2 c'è un bypass per evitare che lo step non risulti attivo*/
    isStepActive = (idx) => {
        if (idx === 2 && this.state.activeStep === 3) return true
        return idx === this.state.activeStep
    }

    /* Ritorna true se l' indice passato è maggiore dello step attuale,
    *  se lo step è il 2 c'è un bypass per evitare che lo step risulti completato una fase in anticipo*/
    isStepCompleted = (idx) => {
        if (idx === 2 && this.state.activeStep === 3) return false
        return idx < this.state.activeStep
    }

    closePopUpSaveFilter = () => {
        this.setState({popUpSAveFilter: false})
    }

    openPopUpSaveFilter = () => {
        this.setState({popUpSAveFilter: true})
    }

    onSelectFavoriteFilter = (filterId) => {
        const filter = this.props.favoriteFilters.get(this.props.favoriteFilters.findIndex(f => f.get('id') === filterId))
        const filterType = filter.getIn(['filter', 'filterType'])
        let newFilter = filter.get('filter')
        newFilter = newFilter.delete('showTable')
        newFilter = newFilter.delete('showGraph')
        const showTable = filter.getIn(['filter', 'showTable'])
        const showGraph = filter.getIn(['filter', 'showGraph'])
        this.setState(old => ({
            favoriteFilterSelected: old.favoriteFilterSelected.merge(fromJS(newFilter)),
            showTimeSelection: this.props.media.genPhone
        }))
        switch (filterType) {
            case 0:
                this.setState(old => ({
                    eventTypeFilters: old.eventTypeFilters.merge(fromJS(newFilter)),
                    showTable: showTable,
                    showGraph: showGraph
                }));
                break
            case 1:
                this.setState(old => ({
                    deviceFilters: old.deviceFilters.merge(fromJS(newFilter)),
                    showTable: showTable,
                    showGraph: showGraph
                }));
                break
            case 2:
                this.setState(old => ({
                    advancedFilters: old.advancedFilters.merge(fromJS(newFilter)),
                    showTable: showTable,
                    showGraph: showGraph
                }));
                break
            default:
                console.error('filterType non valido')
        }
        this.handleChangeFilterType(filterType)

    }


    render() {
        const {t} = this.props
        const {pathname} = this.props.location;

        /* Lista Step*/
        const showMode = localStorage.getItem('showMode') === 'true' || false
        const _plant = this.props.plants.find(p => p.get('id') === this.props.plantId)?.get('pName') || ''
        const steps = [
            t(this.plant, {'plant': showMode && _plant !=='Fiera'? `${_plant[0]}...`: _plant}),
            t(this.machine, {'machine': this.props.machines.find(m => m.get('id') === this.props.machineId)?.get('mName')}),
            t('Filtri')
        ]

        /* Gestione step */
        if (this.props.plantId !== 0 && this.state.activeStep === 0) {
            this.setState({'activeStep': 1})
            this.plant = `Impianto`
        }
        if (this.props.plantId !== 0 && this.props.machineId !== 0 && this.state.activeStep === 1) {
            this.setState({'activeStep': 2})
            this.machine = `Macchina`
        }
        if (this.props.plantId !== 0 && this.props.machineId !== 0 && this.state.activeStep === 2) {
            this.props.getEventCode()
            this.props.getDevices()
            this.props.getFavoriteFilters()
            this.setState({'activeStep': 3})
        }
        if (this.props.data.size > 0 && this.state.activeStep === 3) {
            this.setState({'activeStep': 5})

        }

        const columns = [
            {
                name: t('id'),
                selector: 'id',
                sortable: true,
                omit: true
            },
            {
                name: t('Inizio'),
                selector: 'timestampStart',
                sortable: true,
                format: (row) => moment.unix(row.timestampStart / 1000).format('DD/MM/YYYY hh:mm:ss'),
                maxWidth: '185px',
                width: '180px',
            },
            {
                name: t('Fine'),
                selector: 'timestampEnd',
                sortable: true,
                format: (row) => row.timestampEnd > 0 ? moment.unix(row.timestampEnd / 1000).format('DD/MM/YYYY hh:mm:ss') : null,
                maxWidth: '185px',
                width: '180px',
            },
            {
                name: t('Dispositivo'),
                selector: `deviceId`,
                sortable: true,
                format: (row) => this.props.devices?.find(d => d.get('id') === row.deviceId)?.get('dName'),
                maxWidth: '180px',
            },
            {
                name: t('Descrizione'),
                selector: `description`,
                format: (row) => {
                    return row.description[localStorage.getItem('lang')] ? row.description[localStorage.getItem('lang')] : row.description[Object.keys(row.description)[0]]
                },
                sortable: true,
                minWidth: '180px',
            },
            {
                name: t('Valore'),
                selector: 'value',
                sortable: true,
                maxWidth: '180px',
            },
            {
                name: t('Unità'),
                selector: 'unit',
                sortable: true,
                maxWidth: '120px',
            },
        ]

        return (
            <div className={'dataView'}>
                <Stepper activeStep={this.state.activeStep} elevation={5} className={'stepper'}>
                    {steps.map((label, index) => {
                        return (
                            <Step
                                key={label}
                                icon={<EditIcon/>}
                                onClick={() => this.state.activeStep > index && this.setState({
                                    popUpConfirmResetFilter: true,
                                    resetStep: index
                                })}
                            >
                                <StepLabel
                                    icon={
                                        this.isStepCompleted(index) ?
                                            <EditIcon style={{color: '#4caf50'}}/> :
                                            <PaginationItem page={index} shape={"round"} size={"small"} style={{
                                                backgroundColor: this.isStepActive(index) ? '#ffeb3b' : '#9e9e9e',
                                                color: '#000'
                                            }} selected={true}/>}
                                    className={this.isStepCompleted(index) ? 'step completedStep' : 'step'}
                                >{label}</StepLabel>
                            </Step>
                        )
                    })}

                    {
                        pathname === '/DataView' && this.props.media.genPc && this.state.activeStep === 5 &&
                        <>
                            <Tooltip title={'Salva filtro'}>
                                <Button className={'saveFavFilter'} variant={"text"}
                                        onClick={this.openPopUpSaveFilter}><StarBorderRoundedIcon/></Button>

                            </Tooltip>
                            <Tooltip title={'Aggiorna dati'}>
                                <Button className={'saveFavFilter'} variant={"text"}
                                        onClick={()=>this.getData(this.props.filters, this.props.showTable, this.props.showGraph, true)}><UpdateRoundedIcon/></Button>

                            </Tooltip>
                        </>

                    }
                </Stepper>
                <div className={'contentData'}>
                    {/* Vista impianti */}
                    {
                        this.state.activeStep === 0 && <Plants/>
                    }
                    {/* Vista macchine */}
                    {
                        this.state.activeStep === 1 && <Machines/>
                    }
                    {/* Vista filtri */}
                    {
                        this.state.activeStep === 3 && this.props.media.genPc &&
                        <div className={'filters'}>
                            <AppBar position="static" color="default">
                                <Tabs
                                    value={this.state.filterType}
                                    onChange={(e, v) => this.handleChangeFilterType(v)}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    variant="fullWidth"
                                >
                                    <Tab label={t("Tipo di evento")}/>
                                    <Tab label={t("Dispositivo")}/>
                                    <Tab label={t("Avanzato")}/>
                                    <Tab label={t("Preferiti")}/>
                                </Tabs>
                            </AppBar>
                            <TabPanel value={this.state.filterType} index={0}>
                                <EventTypeFilterPage
                                    filters={this.state.eventTypeFilters}
                                    onChangeFilter={(...args) => this.updateEventTypeFilters(...args)}
                                    eventCode={this.props.eventCode}
                                    eventClass={this.props.eventClass}
                                    showTable={this.state.showTable}
                                    showGraph={this.state.showGraph}
                                    sendFilters={(showTable, showGraph) => this.getData(this.state.eventTypeFilters, showTable, showGraph)}
                                    resetFilter={() => this.setState({
                                        eventTypeFilters: this.getInitialFilterState(),
                                        showTable: true,
                                        showGraph: true,
                                    })}
                                />

                            </TabPanel>
                            <TabPanel value={this.state.filterType} index={1}>
                                <DeviceFilterPage
                                    filters={this.state.deviceFilters}
                                    onChangeFilter={(...args) => this.updateDeviceFilters(...args)}
                                    eventCode={this.props.eventCode}
                                    eventClass={this.props.eventClass}
                                    devices={this.props.devices}
                                    showTable={this.state.showTable}
                                    showGraph={this.state.showGraph}
                                    sendFilters={(showTable, showGraph) => this.getData(this.state.deviceFilters, showTable, showGraph)}
                                    resetFilter={() => this.setState({
                                        deviceFilters: this.getInitialFilterState(),
                                        showTable: true,
                                        showGraph: true,
                                    })}
                                />
                            </TabPanel>
                            <TabPanel value={this.state.filterType} index={2}>
                                <AdvancedFilterPage
                                    filters={this.state.advancedFilters}
                                    onChangeFilter={(...args) => this.updateAdvancedFilters(...args)}
                                    eventCode={this.props.eventCode}
                                    eventClass={this.props.eventClass}
                                    devices={this.props.devices}
                                    type={this.props.type}
                                    showTable={this.state.showTable}
                                    showGraph={this.state.showGraph}
                                    sendFilters={(showTable, showGraph) => this.getData(this.state.advancedFilters, showTable, showGraph)}
                                    resetFilter={() => this.setState({
                                        advancedFilters: this.getInitialFilterState(),
                                        showTable: true,
                                        showGraph: true,
                                    })}
                                />
                            </TabPanel>
                            <TabPanel value={this.state.filterType} index={3}>
                                <FavoritesFilterPage
                                    favoriteFilters={this.props.favoriteFilters}
                                    deleteFavoriteFilters={this.props.deleteFavoriteFilters}
                                    onSelect={this.onSelectFavoriteFilter}
                                />
                            </TabPanel>
                        </div>
                    }
                    {
                        this.state.activeStep === 3 && this.props.media.genPhone &&
                        <FavoritesFilterPage
                            favoriteFilters={this.props.favoriteFilters}
                            deleteFavoriteFilters={this.props.deleteFavoriteFilters}
                            onSelect={this.onSelectFavoriteFilter}
                        />
                    }
                    {
                        this.state.loadingData && this.state.activeStep >= 4 &&
                        <LinearProgress color={"secondary"}/>
                    }
                    {/* Vista Dati*/}
                    {
                        this.state.activeStep === 5 && this.props.data.size > 0 &&
                        <>
                            {
                                this.props.showTable &&
                                <div className={'data'}>
                                    <DataTable
                                        data={this.props.data?.toJS()}
                                        // title={t('Batch')}
                                        columns={columns}
                                        theme="dark"
                                        progressPending={this.state.activeStep === 4}
                                        // selectableRows
                                        // Clicked
                                        // conditionalRowStyles={conditionalRowStyles}
                                        dense
                                        // style={{minHeight:'300px'}}
                                        fixedHeader={true}
                                        // fixedHeaderScrollHeight={'Calc(55vh - 282px)'}
                                        fixedHeaderScrollHeight={!this.props.showGraph ? 'Calc(100vh - 282px)': this.props.media.show?'1200px':'200px'}
                                        paginationPerPage={!this.props.showGraph ? 30 : this.props.media.show?70:15}
                                        defaultSortField={0}
                                        pagination={true}
                                        noHeader
                                        conditionalRowStyles={[
                                            {
                                                when: row => row.unit === 'bool' && row.eventClass === 30,
                                                style: {
                                                    backgroundColor: '#ab3022'
                                                }
                                            },
                                            {
                                                when: row => row.unit === 'bool' && row.eventClass === 20,
                                                style: {
                                                    backgroundColor: '#ff9800'
                                                }
                                            },
                                            {
                                                when: row => row.unit === 'bool' && row.eventClass === 10,
                                                style: {
                                                    backgroundColor: '#2196f3'
                                                }
                                            },
                                        ]}
                                    />
                                </div>
                            }
                            {
                                this.props.graphData.size > 0 && this.props.showGraph &&

                                <EchartsLineChart
                                    graphData={this.props.graphData.toJS()}
                                    graphSeries={this.props.graphSeries.toJS()}
                                    fullHeight={!this.props.showTable}
                                    midHeight={this.props.media.show}
                                />
                            }
                        </>
                    }
                    {
                        this.state.activeStep === 5 && this.props.data.size <= 0 &&
                        <Typography variant={"h5"}
                                    style={{textAlign: 'center', paddingTop: '10px'}}>{t('Nessun dato')}</Typography>
                    }
                </div>
                <ConfirmPopUp
                    open={this.state.popUpConfirmResetFilter}
                    handleAccept={this.resetFilter}
                    handleDecline={() => this.setState({popUpConfirmResetFilter: false})}
                    title={t('Reset')}
                    description={t(confirmText[this.state.resetStep])}
                    ko={t('Annulla')}
                    ok={t('Conferma')}
                />
                <AddFavoriteFilterPopUp
                    open={this.state.popUpSAveFilter}
                    onClose={this.closePopUpSaveFilter}
                    onSave={this.props.addFavoriteFilter}
                />
                <TimeSelectionPopUp
                    open={this.state.showTimeSelection && this.props.media.genPhone}
                    getData={(...args) => {
                        this.setState({showTimeSelection: false})
                        this.getData(...args)
                    }}
                    favoriteFilter={this.state.favoriteFilterSelected}
                    cancel={() => this.setState({
                        showTimeSelection: false, favoriteFilterSelected: Map({
                            'startTime': moment().subtract(2, 'hour'),
                            'endTime': moment(),
                        })
                    })}
                />
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        machineId: state.data.machineId,
        plantId: state.data.plantId,
        batchReports: state.data.batchReports,
        filters: state.data.filters,
        showGraph: state.data.showGraph,
        showTable: state.data.showTable,
        filterType: state.data.filterType,
        eventCode: state.meta.eventCode,
        eventClass: state.meta.eventClass,
        devices: state.meta.devices,
        type: state.meta.type,
        data: state.data.data,
        graphData: state.data.graphData,
        graphSeries: state.data.graphSeries,
        plants: state.meta.plants,
        machines: state.meta.machines,
        favoriteFilters: state.meta.favoriteFilters,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getBatchReports: (...args) => dispatch(getBatchReports(...args)),
        getEventCode: (...args) => dispatch(getEventCode(...args)),
        getFilteredData: (...args) => dispatch(getFilteredData(...args)),
        getFavoriteFilters: (...args) => dispatch(getFavoriteFilters(...args)),
        getDevices: (...args) => dispatch(getDevices(...args)),
        onSelectedPlantChange: (...args) => dispatch(onSelectedPlantChange(...args)),
        onSelectedMachineChange: (...args) => dispatch(onSelectedMachineChange(...args)),
        addFavoriteFilter: (...args) => dispatch(addFavoriteFilter(...args)),
        deleteFavoriteFilters: (...args) => dispatch(deleteFavoriteFilters(...args)),
        resetData: () => dispatch(dispatch({
            type: actionType.UPDATE_DATA,
            data: List([]),
            graphData: List([]),
            graphSeries: List([])
        })),
        resetMachineMeta: () => dispatch(dispatch({
            type: actionType.RESET_MACHINE_META
        })),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withRouter(withMedia(DataView))));
