import React from "react";
import {Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel} from "@material-ui/core";
import {withTranslation} from "react-i18next";


const VisualizationType = ({showTable, showGraph, setShowTable, setShowGraph, t}) => {

    return (
        <FormControl required error={!showTable && !showGraph} component="fieldset" className={'visualizationType'}>
            <FormLabel component="legend">{t('Modalità di visualizzazione')}</FormLabel>
            <FormGroup>
                <FormControlLabel
                    control={<Checkbox checked={showTable} onChange={(e) => setShowTable(e.target.checked)}
                                       name="gilad"/>}
                    label={t('Mostra tabella')}
                />
                <FormControlLabel
                    control={<Checkbox checked={showGraph} onChange={(e) => setShowGraph(e.target.checked)}
                                       name="jason"/>}
                    label={t('Mostra grafico')}
                />
            </FormGroup>
            {!showTable && !showGraph &&
            <FormHelperText>{t('Nessuna modalità di visualizzazione selezionata')}</FormHelperText>
            }
        </FormControl>

    )
}

export default withTranslation()(VisualizationType);