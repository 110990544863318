import './Dashboard.scss'
import {Button, ButtonBase, Divider, Paper, Step, StepLabel, Stepper, Tooltip} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import {PaginationItem, Skeleton} from "@material-ui/lab";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {
    deleteDocument,
    generateUploadLink,
    getDocument,
    getDocumentsList,
    getMachineTickets,
    newMachineTicket,
    onSelectedMachineChange,
    onSelectedPlantChange,
    updateMachineTicket,
    uploadDocument
} from "../../store/actions/data";
import * as actionType from "../../store/actions/action";
import Plants from "../library/Plants/Plants";
import Machines from "../library/Machines/Machines";
import ConfirmPopUp from "../library/ConfirmPopUp/ConfirmPopUp";
import TextWidget from "../library/TextWidget/TextWidget";
import {fromJS, Map} from 'immutable'
import {getDevices, getEventCode, getFavoriteFilters, updateMachine} from "../../store/actions/meta";
import NumberWidget from "../library/NumberWidget/NumberWidget";
import {Responsive, WidthProvider} from "react-grid-layout";
import 'react-grid-layout/css/styles.css'
import EditDashboard from "../library/icons/EditDashboard";
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded';
import settings from "../Settings/Settings";
import {useQuery} from 'react-query';
import axiosInst from "../../axios_inst";
import SaveRoundedIcon from '@material-ui/icons/SaveRounded';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import NewWidgetDialog from "../library/NewWidgetDialog/NewWidgetDialog";
import {withMedia} from "react-media-query-hoc";
import Typography from "@material-ui/core/Typography";
import ArrowDropDownRoundedIcon from '@material-ui/icons/ArrowDropDownRounded';
import ArrowDropUpRoundedIcon from '@material-ui/icons/ArrowDropUpRounded';
import Document from "../library/Document/Document";
import PdfPopUpVisualization from "../library/PdfPopUpVisualization/PdfPopUpVisualization";
import {Worker} from "@react-pdf-viewer/core";
import CloudUploadRoundedIcon from '@material-ui/icons/CloudUploadRounded';
import Ticket from "../library/Ticket/Ticket";
import moment from "moment";
import PopUpNewTicket from "../library/PopUpNewTicket/PopUpNewTicket";
import NewTicket from "../library/icons/NewTicket";

const ResponsiveReactGridLayout = WidthProvider(Responsive);
const confirmText = [`Sicuro di voler cambiare l'impianto`, `Sicuro di voler cambiare la macchina`, `Sicuro di voler cambiare i filtri`,]

const Dashboard = (props) => {
    const {t} = useTranslation()
    const dispatch = useDispatch()

    let showMode = localStorage.getItem('showMode') == 'true' || false

    const plants = useSelector(state => state.meta.plants)
    const machines = useSelector(state => state.meta.machines)
    const plantId = useSelector(state => state.data.plantId)
    const machineId = useSelector(state => state.data.machineId)
    const eventCodes = useSelector(state => state.meta.eventCode)
    const documents = useSelector(state => state.data.documents)
    const machine_tickets = useSelector(state => state.data.machine_tickets)
    const groups = useSelector(state => state.meta.groups)
    const userRole = useSelector(state => state.auth.user.getIn(['user', 'role']))

    const [activeStep, setActiveStep] = useState(0)
    const [popUpConfirmResetFilter, setPopUpConfirmResetFilter] = useState(false)
    const [resetStep, setResetStep] = useState(-1)
    const [plantString, setPlantString] = useState('Selezione impianto')
    const [machineString, setMachineString] = useState('Selezione macchina')
    const [request, setRequest] = useState(false)
    const [editMode, setEditMode] = useState(false)
    const [machine, setMachine] = useState(Map({}))
    const [l, setL] = useState({layouts: {}})
    const [newWidgetState, setNewWidgetState] = useState(false)
    const [docState, setDocState] = useState(false)
    const [ticketState, setTicketState] = useState(false)
    const [oldTicketState, setOldTicketState] = useState(false)
    const [pdfUrl, setPdfUrl] = useState('')
    const [pdfName, setPdfName] = useState('')
    const [popUpConfirmDeleteFile, setPopUpConfirmDeleteFile] = useState('')
    const [popUpNewTicketState, setPopUpNewTicketState] = useState(false)

    useEffect(() => {
        if (documents.size) setDocState(true)
    }, [documents])

    useEffect(() => {
        if (machine_tickets.size && machine_tickets.some(t => t.get('rateAt') === null)) setTicketState(true)
    }, [machine_tickets])


    const resetFilter = () => {
        setPopUpConfirmResetFilter(false)
        if (resetStep === 0 && activeStep > 0) {
            dispatch(onSelectedPlantChange(0))
            dispatch({type: actionType.RESET_MACHINE_META})
            setActiveStep(0)
            setMachine(Map({}))
            setL({layouts: {}})
            setEditMode(false)
            setMachineString('Selezione macchina')
            setPlantString('Selezione impianto')
        } else if (resetStep === 1 && activeStep > 1) {
            dispatch(onSelectedMachineChange(0))
            dispatch({type: actionType.RESET_MACHINE_META})
            setActiveStep(1)
            setMachine(Map({}))
            setL({layouts: {}})
            setEditMode(false)
            setMachineString('Selezione macchina')
            setPlantString('Selezione impianto')
        }
    }

    if (plantId !== 0 && activeStep === 0) {
        setActiveStep(1)
        setRequest(false)
        setPlantString(`Impianto`)
    }
    if (plantId !== 0 && machineId !== 0 && activeStep === 1) {
        setActiveStep(2)
        setRequest(false)
        setMachineString(`Macchina`)
    }
    if (plantId !== 0 && machineId !== 0 && activeStep === 2 && !request && (eventCodes.size === 0)) {
        setRequest(true)
        setActiveStep(3)
        Promise.all([
            dispatch(getEventCode()),
            dispatch(getDevices()),
            dispatch(getFavoriteFilters()),
            dispatch(getDocumentsList()),
            dispatch(getMachineTickets())

        ])
            .then(() => setActiveStep(4))
            .catch(() => setActiveStep(4))

        setMachine(machines.find(m => m.get('id') === machineId))
    }
    if (activeStep === 2 && eventCodes.size > 0) {
        setActiveStep(4)
        setMachine(machines.find(m => m.get('id') === machineId))
    }


    const data_fetch = () => {
        const events = machine?.get('dashboardSchema').filter(w=> {
            const i = w.get('i')
            // console.debug(i.length > 1 && i.split('.')[0] !== '0')
            // console.debug(i.size > 1)
            // console.debug(i.size)
            // console.debug(i.length)
            // console.debug(i.split('.')[0] !== '0')
            // console.debug(i.split('.')[0])
            // console.debug(i.split('.'))
            if (i.length > 1 && i.split('.')[0] !== '0')
                return true
        }).map(widget => {
            return widget.get('i')
        })
        console.debug(events)
        const request_event = events.map(e => (
            {
                "machine": machineId,
                "eventType": parseInt(e?.split('.')[0]),
                "eventCode": parseInt(e?.split('.')[1])
            }
        ))
        return axiosInst.post('/logEvent', request_event).then((res) => fromJS(res['data']['data']))
    }

    const {isLoading, error, data} = useQuery('todos', data_fetch, {
        enabled: plantId !== 0 && machineId !== 0 && activeStep >= 2,
        cacheTime: 10000,
        refetchInterval: 11000
    })

    /* Ritorna true se lo stato attuale è uguale all' indice*/
    const isStepActive = (idx) => {
        return idx === activeStep
    }

    /* Ritorna true se l' indice passato è maggiore dello step attuale*/
    const isStepCompleted = (idx) => {
        return idx < activeStep
    }

    const handleAddNewWidget = (event, minScale, maxScale, savVisType) => {
        return new Promise((resolve, reject) => {
            if (!event || event === '') {
                reject(t('Nessun evento selezionato'))
                return
            }
            const tmp = event.split('.')
            const eventType = tmp[0]
            const eventCodeId = tmp[1]
            const eventCode = eventCodes.find(ec => ec.get('eventType') === parseInt(eventType) && ec.get('id') === parseInt(eventCodeId))
            if (!eventCode) {
                reject(t('Evento non valido'))
                return
            }
            let type
            if (eventCode.get('unit') === 'value_map' || eventCode?.get('unit').includes('context')) {
                type = 1
            } else {
                type = 2
            }
            if (type === 2 && (minScale === undefined || minScale === null)) {
                reject(t('Min Scale non definito'))
                return
            }
            if (type === 2 && (maxScale === undefined || maxScale === null)) {
                reject(t('Max scale non definito'))
                return
            }
            if (type === 1 && savVisType) {
                savVisType = 2
            }
            const newWidget = Map({
                "i": event,
                "layout": Map({
                    "h": type === 1 || savVisType === 2? 1 : 3,
                    "w": type === 1 || savVisType === 2? 3 : 4,
                    "x": 0,
                    "y": 0,
                    "minH": type === 1 || savVisType === 2? 1 : 2,
                    "minW": type === 1 || savVisType === 2? 2 : 4,
                }),
                "settings": Map({
                    "maxScale": maxScale,
                    "minScale": minScale,
                    "visType": savVisType
                })
            })
            let newMachine = machine.updateIn(['dashboardSchema'], o => o.push(newWidget))
            setMachine(newMachine)
            resolve()
        })
    }

    const handleDeleteWidget = (index) => {
        return new Promise((resolve, reject) => {
            let newMachine = machine.updateIn(['dashboardSchema',], o => o.delete(index))
            setMachine(newMachine)
            resolve()
        })
    }

    /* Lista Step*/
    const _plant = plants.find(p => p.get('id') === plantId)?.get('pName') || ''
    const steps = [
        t(plantString, {'plant': showMode && _plant !=='Fiera'? `${_plant[0]}...`: _plant}),
        t(machineString, {'machine': machine?.get('mName')}),
    ]

    const onEditConfigSettings = (wKey, newId, newValue) => {
        return new Promise((resolve, reject) => {
            let newMachine = machine.updateIn(['dashboardSchema', machine.get('dashboardSchema').findIndex(w => w.get('i') === wKey), 'settings'], o => o.merge(newValue))
            newMachine = newMachine.updateIn(['dashboardSchema', machine.get('dashboardSchema').findIndex(w => w.get('i') === wKey), 'i'], o => newId)
            setMachine(newMachine)
            resolve()
            // dispatch(updateMachine(machineId, newMachine)).then(() => {
            //
            // })
        })
    }

    const onEditLayout = (layout, layouts) => {
        let newMachine = machine.updateIn(['dashboardSchema'], ds => ds.map(w => {
            let newLayout = fromJS(layout).find(l => w.get('i') === l.get('i'))
            newLayout = newLayout?.delete('i')
            return w.update('layout', l => l.merge(newLayout))
        }))
        setMachine(newMachine)
        setL(layouts)
        // newMachine = newMachine.updateIn(['dashboardSchema', machine.get('dashboardSchema').findIndex(w => w.get('i') === wKey), 'i'], o => newId)
        // dispatch(updateMachine(machineId, newMachine)).then(() => {
        // })
    }

    const handleSave = () => {
        dispatch(updateMachine(machineId, machine.toJS())).then(() => {

        })
        setEditMode(false)
    }

    const handleCancel = () => {
        setMachine(machines.find(m => m.get('id') === machineId))
        setL({layouts: {}})
        setEditMode(false)
    }

    let dashboardComponents
    if (activeStep >= 2 && eventCodes.size > 0 && machine.get('dashboardSchema') && machine.get('dashboardSchema').size > 0) {
        dashboardComponents = machine.get('dashboardSchema')?.map((widget, index) => {
            const widgetEventType = parseInt(widget.get('i').split('.')[0])
            const widgetEventCode = parseInt(widget.get('i').split('.')[1])
            const eventCode = eventCodes.find(ec => ec.get('eventType') === widgetEventType && ec.get('id') === widgetEventCode)
            const record = data?.find(d => d.get('machineId') === machineId && d.get('eventType') === widgetEventType && d.get('eventCode') === widgetEventCode)
            const value = record?.get('value')
            let description = eventCode?.get('description')
            description = description?.get(localStorage.getItem('lang'), description.first())
            if (eventCode?.get('unit') === 'value_map') {
                return <div
                    key={widget.get('i')}
                    data-grid={widget.get('layout').toJS()}
                >
                    <TextWidget
                        title={description}
                        value={eventCode.getIn(['valueMap', `${value}`])}
                        eventCodeKey={widget.get('i')}
                        editMode={editMode}
                        onEdit={onEditConfigSettings}
                        index={index}
                        onDelete={handleDeleteWidget}
                        {...widget.get('settings').toJS()}
                        addTitleText={eventCode.get('reportType') === 1? t('Batch'):eventCode.get('reportType') === 2? t('Cip'):''}
                    />
                </div>
            } else if (eventCode?.get('unit').includes('context')) {
                const path = eventCode.get('unit').split('.')
                return <div
                    key={widget.get('i')}
                    data-grid={widget.get('layout').toJS()}
                >
                    <TextWidget
                        title={description}
                        value={record?.getIn(path)}
                        eventCodeKey={widget.get('i')}
                        editMode={editMode}
                        onEdit={onEditConfigSettings}
                        index={index}
                        onDelete={handleDeleteWidget}
                        {...widget.get('settings').toJS()}
                        addTitleText={eventCode.get('reportType') === 1? t('Batch'):eventCode.get('reportType') === 2? t('Cip'):''}
                    />
                </div>
            } else if (widget.getIn(['settings', 'visType']) === 2) {
                const path = eventCode.get('unit').split('.')
                return <div
                    key={widget.get('i')}
                    data-grid={widget.get('layout').toJS()}
                >
                    <TextWidget
                        title={description}
                        value={value}
                        eventCodeKey={widget.get('i')}
                        editMode={editMode}
                        onEdit={onEditConfigSettings}
                        index={index}
                        unit={eventCode.get('unit')}
                        onDelete={handleDeleteWidget}
                        {...widget.get('settings').toJS()}
                        addTitleText={eventCode.get('reportType') === 1? t('Batch'):eventCode.get('reportType') === 2? t('Cip'):''}
                    />
                </div>
            } else if (widget.getIn(['settings', 'visType']) === 3) {
                return <div
                    key={widget.get('i')}
                    data-grid={widget.get('layout').toJS()}
                >
                    <TextWidget
                        title={' '}
                        value={widget.getIn(['settings', 'text'])}
                        eventCodeKey={widget.get('i')}
                        editMode={editMode}
                        onEdit={onEditConfigSettings}
                        index={index}
                        unit={''}
                        onDelete={handleDeleteWidget}
                        {...widget.get('settings').toJS()}
                        // addTitleText={eventCode.get('reportType') === 1? t('Batch'):eventCode.get('reportType') === 2? t('Cip'):''}
                    />
                </div>
            } else {
                return <div
                    key={widget.get('i')}
                    data-grid={widget.get('layout').toJS()}
                >
                    <NumberWidget
                        title={description}
                        value={value}
                        eventCodeKey={widget.get('i')}
                        unit={eventCode.get('unit')}
                        editMode={editMode}
                        onEdit={onEditConfigSettings}
                        index={index}
                        onDelete={handleDeleteWidget}
                        {...widget.get('settings').toJS()}
                    />
                </div>
            }
        })
    } else if (machine.get('dashboardSchema') && machine.get('dashboardSchema').size === 0) {
        dashboardComponents = [<div
            key={'noData'}
            data-grid={{
                "h": 2,
                "w": 10,
                "x": 0,
                "y": 0,
                "static": true,
                "isDraggable": false,
                "isResizable": false,
            }}
        >
            <TextWidget
                title={''}
                value={t('Nessun widget')}
                eventCodeKey={'0.0.0'}
                editMode={false}
                index={0}
            />
        </div>]
    }

    const handleClosePdf = () => {
        setPdfUrl('')
        setPdfName('')
    }

    const handleLoadNewDocuments = (e) => {
        Array.from(e.target.files).forEach(f => {
            const fileData = new FormData();
            fileData.append('file', f)
            dispatch(generateUploadLink(f)).then(url => {
                dispatch(uploadDocument(url, fileData, f.name))
                    .catch(r => console.debug(r))
            })
        })
    }


    const handleDeleteFile = (fileName) => {
        dispatch(deleteDocument(fileName));
        setPopUpConfirmDeleteFile('')
    }

    const handleOpenDocument = (filename) => {
        dispatch(getDocument(filename)).then(res => {
            setPdfUrl(res)
            setPdfName(filename)
        })
    }

    const documentsComponent = documents.map((d) => <Document
        fileName={d}
        editMode={editMode && userRole.get('documents')}
        onOpen={handleOpenDocument}
        onDelete={(fileName) => setPopUpConfirmDeleteFile(fileName)}
    />)

    const handleSendNewTicket = (ticket) => {
        return new Promise((resolve, reject) => {
            dispatch(newMachineTicket(ticket))
                .then(() => {
                    resolve()
                    setPopUpNewTicketState(false)
                })
        })
    }

    const handleRateTicket = (ticketId, rate, rateDescription) => {
        return new Promise((resolve, reject) => {
            dispatch(updateMachineTicket(ticketId, {
                clientRate: rate,
                clientRateDescription: rateDescription,
                rateAt: moment().toISOString()
            }))
                .then(() => resolve())
        })
    }

    const ticketsComponent = machine_tickets.filter(t => !t.get('rateAt')).map(t => {
        return <Ticket
            ticket={t}
            groups={groups}
            onRate={handleRateTicket}
        />
    })

    const oldTicketsComponent = machine_tickets.filter(t => t.get('rateAt')).map(t => {
        return <Ticket
            ticket={t}
            groups={groups}
            onRate={handleRateTicket}
        />
    })

    return (
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.12.313/build/pdf.worker.min.js">
            <div className={'dashboard'}>
                <Stepper activeStep={activeStep} elevation={5} className={'stepper'}>
                    {steps.map((label, index) => {
                        return (
                            <Step
                                key={label}
                                icon={<EditIcon/>}
                                onClick={() => {
                                    if (activeStep > index) {
                                        setPopUpConfirmResetFilter(true)
                                        setResetStep(index)
                                    }
                                }}
                            >
                                <StepLabel
                                    icon={
                                        isStepCompleted(index) ?
                                            <EditIcon style={{color: '#4caf50'}}/> :
                                            <PaginationItem page={index} shape={"round"} size={"small"} style={{
                                                backgroundColor: isStepActive(index) ? '#ffeb3b' : '#9e9e9e',
                                                color: '#000'
                                            }} selected={true}/>}
                                    className={isStepCompleted(index) ? 'step completedStep' : 'step'}
                                >{label}</StepLabel>
                            </Step>
                        )
                    })}
                    {
                        activeStep >= 2 && props.media.genPc &&
                        <Tooltip title={'Modifica dashboard'}>
                            <ButtonBase className={'editDashboard'} variant={"contained"} size={"small"}
                                        style={{backgroundColor: editMode ? '#953632' : '#FFFFFF00'}}
                                        onClick={() => setEditMode(old => !old)}
                            ><EditDashboard style={{fill: 'white', height: '20px', width: '20px'}}/></ButtonBase>

                        </Tooltip>
                    }
                    {
                        activeStep >= 2 && editMode && props.media.genPc &&
                        <Tooltip title={'Aggiungi widget'}>
                            <ButtonBase className={'editDashboard'} variant={"text"} size={"small"}
                                        onClick={() => setNewWidgetState(true)}
                            ><AddCircleRoundedIcon style={{fill: 'white', height: '24px', width: '24px'}}/></ButtonBase>

                        </Tooltip>
                    }
                    {
                        activeStep >= 2 && editMode && props.media.genPc &&
                        <Tooltip title={'Salva'}>
                            <ButtonBase className={'editDashboard'} variant={"text"} size={"small"}
                                        onClick={handleSave} disabled={false}
                            ><SaveRoundedIcon style={{fill: 'white', height: '24px', width: '24px'}}/></ButtonBase>

                        </Tooltip>
                    }
                    {
                        activeStep >= 2 && editMode && props.media.genPc &&
                        <Tooltip title={'Scarta'}>
                            <ButtonBase className={'editDashboard'} variant={"text"} size={"small"}
                                        onClick={handleCancel} disabled={false}
                            ><CancelRoundedIcon style={{fill: 'white', height: '24px', width: '24px'}}/></ButtonBase>

                        </Tooltip>
                    }
                </Stepper>

                {
                    activeStep === 0 ? <Plants/> : null
                }
                {
                    activeStep === 1 ? <Machines/> : null
                }
                {
                    activeStep >= 2 && activeStep <= 3 && <div className={'skeleton'}>
                        <Skeleton animation="wave" variant="rect" width={338} height={338} className={'element'}/>
                        <Skeleton animation="wave" variant="rect" width={338} height={338} className={'element'}/>
                        <Skeleton animation="wave" variant="rect" width={338} height={338} className={'element'}/>
                        <div style={{margin: '0 0 0 auto'}}>
                            <Skeleton animation="wave" variant="rect" width={500} height={window.innerHeight / 2 - 90}
                                      className={'element'}/>
                            <Skeleton animation="wave" variant="rect" width={500} height={window.innerHeight / 2 - 90}
                                      className={'element'}/>

                        </div>
                    </div>
                }
                {
                    activeStep > 3 &&
                    <div className={'content'}>
                        <ResponsiveReactGridLayout
                            className="gridLayout"
                            autoSize={true}
                            rowHeight={106}
                            isDraggable={editMode}
                            isResizable={editMode}
                            width={window.innerWidth - 520}
                            style={{width: window.innerWidth - 500}}
                            breakpoints={{lg: 1200, md: 1050, sm: 700, xs: 350, xxs: 0}}
                            cols={{lg: 16, md: 12, sm: 8, xs: 4, xxs: 4}}
                            // onBreakpointChange={(newBreakpoint, newCols) => console.debug('resize')}
                            // onLayoutChange={onEditLayout}
                            onLayoutChange={(layout, layouts) => {
                                onEditLayout(layout, layouts)
                            }
                            }
                            draggableHandle={'.title'}
                            layouts={l}
                            verticalCompact={false}
                            preventCollision={true}
                        >
                            {dashboardComponents}
                        </ResponsiveReactGridLayout>
                        <div className={'extra'}>
                            <Paper className={`container ${docState ? 'open' : 'close'}`}>
                                <ButtonBase onClick={() => setDocState(old => !old)} className={'btn'}>
                                    <Typography variant={'h6'} className={'title'}>{t('Documenti')}</Typography>
                                    {docState ? <ArrowDropUpRoundedIcon/> : <ArrowDropDownRoundedIcon/>}
                                </ButtonBase>
                                <Divider variant={"fullWidth"}/>
                                <div className={'elements'}>
                                    {documentsComponent}
                                    {editMode && userRole.get('documents') &&
                                        <>
                                            <input
                                                accept=".pdf"
                                                id="uploadDoc"
                                                multiple
                                                type="file"
                                                style={{display: 'none'}}
                                                onChange={handleLoadNewDocuments}
                                            />
                                            <label htmlFor="uploadDoc"
                                                   className={'addDocument'}
                                            >
                                                <Button
                                                    variant={"contained"}
                                                    color={"secondary"}
                                                    component="span"
                                                    style={{width: '100%'}}
                                                >
                                                    <CloudUploadRoundedIcon style={{marginRight: '3px'}}/>
                                                    {t('Carica nuovo documento')}
                                                </Button>
                                            </label>
                                        </>
                                    }
                                </div>
                            </Paper>
                            <Paper className={`container ${ticketState ? 'open' : 'close'}`}>
                                <ButtonBase onClick={() => setTicketState(old => !old)} className={'btn'}>
                                    <Typography variant={'h6'} className={'title'}>{t('Ticket')}</Typography>
                                    {ticketState ? <ArrowDropUpRoundedIcon/> : <ArrowDropDownRoundedIcon/>}
                                </ButtonBase>
                                <Divider variant={"fullWidth"}/>
                                <div className={'elements'}>
                                    {ticketsComponent}
                                    <Paper elevation={0}
                                           className={`container oldTicket ${oldTicketState ? 'open' : 'close'}`}>
                                        <ButtonBase onClick={() => setOldTicketState(old => !old)} className={'btn'}>
                                            <Typography variant={'h6'} style={{fontSize: '0.9rem'}}
                                                        className={'title'}>{t('Ticket chiusi')}</Typography>
                                            {ticketState ? <ArrowDropUpRoundedIcon/> : <ArrowDropDownRoundedIcon/>}
                                        </ButtonBase>
                                        <Divider variant={"fullWidth"}/>
                                        <div className={'elements'}>
                                            {oldTicketsComponent}
                                        </div>
                                    </Paper>
                                    <Button
                                        variant={"contained"}
                                        color={"secondary"}
                                        component="span"
                                        style={{width: '100%'}}
                                        onClick={() => setPopUpNewTicketState(true)}
                                    >
                                        <NewTicket style={{marginRight: '3px', fill: '#FFF'}}/>
                                        {t('Nuovo Ticket')}
                                    </Button>
                                </div>
                            </Paper>
                        </div>
                    </div>
                }

                {/*<div>*/}
                {/*    <TextWidget title={'test'} value={'Prova'}/>*/}
                {/*</div>*/}
                <PopUpNewTicket
                    open={popUpNewTicketState}
                    groups={groups}
                    onCancel={() => setPopUpNewTicketState(false)}
                    onSend={handleSendNewTicket}
                />

                <ConfirmPopUp
                    open={popUpConfirmResetFilter}
                    handleAccept={resetFilter}
                    handleDecline={() => setPopUpConfirmResetFilter(false)}
                    title={t('Reset')}
                    description={t(confirmText[resetStep])}
                    ko={t('Annulla')}
                    ok={t('Conferma')}
                />
                <NewWidgetDialog state={newWidgetState} onClose={() => setNewWidgetState(false)}
                                 onAdd={handleAddNewWidget}/>
                <PdfPopUpVisualization
                    open={pdfUrl !== ''}
                    pdfUrl={pdfUrl}
                    fileName={pdfName}
                    onClose={handleClosePdf}
                />
                <ConfirmPopUp
                    open={popUpConfirmDeleteFile}
                    handleAccept={() => handleDeleteFile(popUpConfirmDeleteFile)}
                    handleDecline={() => setPopUpConfirmDeleteFile('')}
                    title={t('Eliminazione documento')}
                    description={t('Sei sicuro di voler eliminare il documento?')}
                    ko={t('Annulla')}
                    ok={t('Conferma')}
                />
            </div>
        </Worker>
    )
}

export default withMedia(Dashboard);
