import React, {useEffect, useMemo, useState} from 'react'
import './TicketsManagement.scss'
import DataTable from "react-data-table-component";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import IconButton from "@material-ui/core/IconButton";
import {Map} from "immutable";
import {getRoles, getTickets, getUsers, updateTicket} from "../../store/actions/meta";
import OpenInBrowserRoundedIcon from "@material-ui/icons/OpenInBrowserRounded";
import TicketDialog from "../library/TicketDialog/TicketDialog";
import {updateMachineTicket} from "../../store/actions/data";
import moment from "moment";
import {Rating} from "@material-ui/lab";

const TicketsManagement = ({}) => {
    const {t} = useTranslation()
    const dispatch = useDispatch()

    const tickets = useSelector(state => state.meta.tickets)
    const groups = useSelector(state => state.meta.groups)
    const machines = useSelector(state => state.meta.machines)
    const userList = useSelector(state => state.meta.userList)

    const [ticketDialogState, setTicketDialogState] = useState(null)

    useEffect(() => {
        dispatch(getTickets())
        dispatch(getUsers())
        dispatch(getRoles())
    }, [])

    const handleCloseTicket = (ticketId) => {
        return new Promise((resolve, reject) => {
            dispatch(updateTicket(ticketId, {
                closedAt: moment().toISOString()
            }))
                .then(() => resolve())
        })
    }
    const customStateSort = (row) => {
        let state = 0
        let strState = t('Aperto')
        if (row.closedAt && !row.rateAt) {
            state = 1
            strState = t('Chiuso')
        } else if (row.closedAt && row.rateAt) {
            state = 2
            strState = t('Valutato')
        }
        return state
    }

    const columns = useMemo(() => {
        return [{
            name: 'Id', selector: row => row.id, sortable: true, maxWidth: '70px'
        }, {
            name: t('Cliente'),
            selector: row => row.requestedFrom,
            format: (row) => {
                const user = userList.find(u => u.get('id') === row.requestedFrom)
                return `${user?.get('name')} ${user?.get('surname')}`
            }, sortable: true, // minWidth: '150px'
            maxWidth: '175px'
        }, {
            name: t('Email'),
            selector: row => row.requestedFrom,
            format: (row) => {
                const user = userList.find(u => u.get('id') === row.requestedFrom)
                return `${user?.get('email')}`
            }, sortable: true, // minWidth: '150px'
            maxWidth: '250px'
        }, {
            name: t('Reparto'),
            selector: row => row.destinationDepartment,
            format: (row) => groups.find(g => g.get('id') === row.destinationDepartment)?.get('name'),
            sortable: true,
            maxWidth: '250px'
            // minWidth: '150px'
        }, {
            name: t('Macchine'),
            selector: row => machines.find(m => m.get('id') === row.machineId)?.get('serialNumber'), // format: (row) => {
            //     return machines.find(m => m.get('machineId') === row.machineId)?.get('serialNumber')
            // },
            sortable: true,
            maxWidth: '300px'
            // minWidth: '150px'
        }, {
            // selector: `rateAt`,
            name: t('Stato'),
            selector: customStateSort,
            format: (row) => {

            },
            cell: row => {
                let strState = t('Aperto')
                if (row.closedAt && !row.rateAt) {
                    strState = t('Chiuso')
                } else if (row.closedAt && row.rateAt) {
                    strState = <Rating name="read-only" value={row.clientRate} readOnly/>
                }
                return strState

            }
            ,
            maxWidth: '150px',
            sortable: true, // minWidth: '150px'
        }, {
            name: t('Oggetto'), selector: row => row.preface, sortable: true, // minWidth: '150px'
        }, {
            selector: row => row.rateAt, sortable: false, cell: row => <>
                <IconButton
                    onClick={() => setTicketDialogState(row.id)}
                >
                    <OpenInBrowserRoundedIcon/>
                </IconButton>
            </>, allowOverflow: true, button: true, width: '90px',
        },]
    }, [machines, groups, userList])

    const customSort = (rows, selector, direction) => {
        const t = rows.sort((rowA, rowB) => {
            // use the selector function to resolve your field names by passing the sort comparitors
            const aField = selector(rowA)
            const bField = selector(rowB)

            let comparison = 0;

            if (aField > bField) {
                comparison = 1;
            } else if (aField < bField) {
                comparison = -1;
            }

            return direction === 'desc' ? comparison * -1 : comparison;
        });
        return t
    };

    const conditionalRowStyles = [{
        when: row => customStateSort(row) === 0, style: {
            color: 'rgba(255,255,0,1)', fontWeight: 'bold', '&:hover': {
                cursor: 'pointer',
            },
        },
    }, {
        when: row => customStateSort(row) === 1, style: {
            color: 'rgba(105,240,174,1)', '&:hover': {
                cursor: 'pointer', backgroundColor: 'rgba(248, 148, 6, 0.7)',
            },
        },
    },];

    return (<div>
        <DataTable
            data={tickets?.toJS()}
            sortFunction={customSort}
            defaultSortFieldId={6}
            defaultSortField={customStateSort}
            defaultSortAsc={true}
            columns={columns}
            theme="dark"
            // progressPending={this.state.activeStep < 4}
            highlightOnHover
            // selectableRows
            // Clicked
            conditionalRowStyles={conditionalRowStyles}
            // dense
            // style={{height:'Calc(100% - 50px)'}}
            fixedHeader={true}
            fixedHeaderScrollHeight={'Calc(100vh - 130px)'}
            pagination={true}
            paginationPerPage={20}
            paginationRowsPerPageOptions={[15, 20, 25, 30]}
            noHeader
            paginationComponentOptions={{
                rowsPerPageText: `${t('Righe per pagina')}:`,
                rangeSeparatorText: t('Di'),
                noRowsPerPage: false,
                selectAllRowsItem: false,
                selectAllRowsItemText: 'All',
            }}
        />
        {ticketDialogState &&

            <TicketDialog
                ticket={tickets.find(t => t.get('id') === ticketDialogState, Map({}))}
                diagState={ticketDialogState !== null}
                groups={groups}
                mngMode={true}
                onClose={() => setTicketDialogState(false)}
                onCloseTicket={handleCloseTicket}
            />}
    </div>)

}

export default TicketsManagement;
