import React from "react";
import './Document.scss'
import {IconButton, Paper, Tooltip, Typography} from "@material-ui/core";
import DescriptionRoundedIcon from '@material-ui/icons/DescriptionRounded';
import OpenInBrowserRoundedIcon from '@material-ui/icons/OpenInBrowserRounded';
import DeleteIcon from "@material-ui/icons/Delete";

const Document = ({fileName, editMode, onOpen, onDelete}) => {

    return (
        <div className={'document'}>
            <DescriptionRoundedIcon style={{marginRight:'4px'}}/>
            <Tooltip title={fileName}>
                <Typography className={'title'}>{fileName}</Typography>
            </Tooltip>
            <div style={{position: 'relative', margin: 'auto 0 auto auto'}}>
                {
                    editMode &&
                    <IconButton onClick={()=>onDelete(fileName)}>
                        <DeleteIcon/>
                    </IconButton>
                }
                <IconButton onClick={()=>onOpen(fileName)}>
                    <OpenInBrowserRoundedIcon/>
                </IconButton>
            </div>
        </div>
    )
}

export default Document;
